const defaultIsAuthState = {
    isAuth: false,
}
const defaultUserState = {
    user: {}
}

const ActionTypes = {
    SET_IS_AUTH: "SET_IS_AUTH",
    SET_USER:"SET_USER",
};

export const isAuth = (state = defaultIsAuthState, action) => {
    switch (action.type) {
        case ActionTypes.SET_IS_AUTH:
            return {...state, isAuth: action.payload}
        default:
            return state;
    }
}

export const user = (state = defaultUserState, action) => {
    switch (action.type) {
        case ActionTypes.SET_USER:
            return {...state, user: action.payload}
        default:
            return state;
    }
}


export const setIsAuth = (payload) => ({type: ActionTypes.SET_IS_AUTH, payload})
export const setUser = (payload) => ({type: ActionTypes.SET_USER, payload})