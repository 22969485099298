import React from 'react';
import cl from './Video.module.css'

const Video = () => {
    return (
        <div className={cl.wrap}>
            <header></header>
            <div className="video-container" style={{display: 'flex'}}>
                <video id="fullscreenVideo" controls>
                    <source src={'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/Sintel.mp4'}
                            type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
    );
};

export default Video;