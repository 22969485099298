import React, {useState} from 'react';
import cl from './Tabs.module.css'
import Tab from "./Tab/Tab";

const Tabs = () => {
    const [currentTab, setCurrentTab] = useState('Cats');
    const tabsData = [
        {title: 'Cats', text: 'Cats blink slowly at you as a sign of trust and affection, a feline "I love you'},
        {title: 'Dogs', text: 'Dogs have unique nose prints, much like human fingerprints, making each one special'},
        {title: 'Cows', text: 'Cows have best friends and can become stressed when separated from them, showing their strong social bonds'},
    ]
    return (
        <div className={cl.wrap}>
            <div className={cl.container}>
                <div className={cl.content}>
                    {
                        tabsData.map((i, index) =>
                            currentTab === i.title && <Tab title={i.title} text={i.text} />

                        )

                    }
                </div>
                <div className={cl.navbar}>
                    <ul>
                        <li><button onClick={()=>setCurrentTab('Cats')}>Cats</button></li>
                        <li><button onClick={()=>setCurrentTab('Dogs')}>Dogs</button></li>
                        <li><button onClick={()=>setCurrentTab('Cows')}>Cows</button></li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Tabs;