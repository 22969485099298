import React from 'react';
import cl from './ThemeToggler.module.css'

const ThemeToggler = ({style={top:0, left:0}}) => {

    return (
        <div className={cl.wrap} style={style}>
            <div className={cl.textWrap}>
                <p className={cl.text}>Theme toggler</p>
            </div>
            <div className={cl.switcher}>
                <div className={cl.round}></div>
            </div>

        </div>
    );
};

export default ThemeToggler;