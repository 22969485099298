import React, {useEffect} from 'react';
import cl from './PetProjects.module.css'
import ProjectMenu from "../../widgets/ProjectMenu/ProjectMenu";

import PetProjectsRouter from "./PetProjectsRouter";
import {useMediaQuery} from "../../shared/utils/hooks/useMediaQuery/useMediaQuery";
import {projectList} from "./ProjectsData";

const PetProjects = () => {
//TODO initial page without any project
    //TODO Move projects from pages to widgets
    const isMobileVersion = useMediaQuery('(max-width: 992px)');
    return (
        <div className={cl.wrap}>
            {!isMobileVersion&&
            <ProjectMenu projectList={projectList}/>}
            <div className={cl.petProjectRouterSkeleton}>
                <PetProjectsRouter/>
            </div>
        </div>
    );
};

export default PetProjects;