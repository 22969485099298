import React, {useState} from 'react';


const useCollectErrors = (callback) => {
    const [errors, setErrors] = useState([]);

    const errorsUnify = (err) => {
        return err.map((error) => {
            return {
                message: error.msg,
                place: error.path,
            }
        })
    }

    const handleFormSubmitting = async (...args) => {
        try {
            const response = await callback.apply(this, args);
            setErrors([])
            return  response;
        } catch (e) {
            if (e.response?.data?.errors?.length !== 0) {
                console.log(e)
                setErrors(errorsUnify(e.response.data.errors));

            } else {
                setErrors([{message: e.response.data.message, place: e.response.data.place}])
            }
            return  false;
        }
    }

    return [handleFormSubmitting,
        errors
    ];
};

export default useCollectErrors;