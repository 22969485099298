import React from 'react';
import cl from './Button_lilac_text.module.css'

const Button_lilac_text = ({  children, ...props}) => {
    return (
        <button {...props} className={cl.button}>
            {children}
        </button>
    );
};

export default Button_lilac_text;