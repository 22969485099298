import React from 'react';
import cl from './HamburgerSmallIcon.module.css'

const HamburgerSmallIcon = ({styles}) => {
    return (
        <div className={cl.wrap} style={styles}>
            <div className={cl.header}>
                <div className={cl.hamburgerWrap}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <div className={cl.sidebar}>
                <ul className={cl.sidebarList}>
                    <li>
                        one
                    </li>
                    <li>
                        two
                    </li>
                    <li>
                        three
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default HamburgerSmallIcon;