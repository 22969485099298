import React from 'react';
import MenuItem from "../../shared/UI/MenuItem/MenuItem";
import {
    projectMenuItemActiveStyles,
    availableProjectMenuItemStyles,
    PaidProjectMenuItemStyles
} from "./ProjectMenuStyles";
import cl from './ProjectMenu.module.css'
import {useSelector} from "react-redux";
import NumberOfOnlinePeople from "../../entities/NumberOfOnlinePeople/NumberOfOnlinePeople";

const ProjectMenu = ({projectList}) => {
    const isAuth = useSelector(state => state.isAuth.isAuth)
    const user = useSelector(state => state.user.user.user)
    const accessToPaidProjects = (isAuth && user.subscriptionStatus==='Paid');
    const listOfFreeProjects = ['hamburger-menu', 'background-changer', 'loader']

    return (
        <div className={cl.wrap}>
            <h2 className={cl.title}>Projects</h2>
            <div className={cl.onlinePeopleWrap}>
                <NumberOfOnlinePeople/>
            </div>
            <ul>
                {projectList.map((i) => {
                        let stylesForMenuItem;
                        let linkForMenuItem;
                        const isFreeProject = listOfFreeProjects.includes(i.id);

                        if (isFreeProject) {
                            stylesForMenuItem = availableProjectMenuItemStyles;
                            linkForMenuItem = '/pet-projects/' + i.id;

                        } else {
                            stylesForMenuItem = accessToPaidProjects ? availableProjectMenuItemStyles : PaidProjectMenuItemStyles;
                            linkForMenuItem = accessToPaidProjects ?'/pet-projects/' + i.id: isAuth?'/offer':'/login'  ;
                        }
                        return (
                            <li key={i.id}>
                                <MenuItem
                                    foldingLevel={1}
                                    key={i.id}
                                    id={i.id}
                                    linkForMenuItem={linkForMenuItem}
                                    styles={stylesForMenuItem}
                                    activeStyles={projectMenuItemActiveStyles}
                                >{i.title}</MenuItem>
                            </li>)
                    }
                )
                }
            </ul>
        </div>
    );
};

export default ProjectMenu;