import React, {useRef} from 'react';
import cl from './DraggableElem.module.css'

const DraggableElem = ({ id, onDragStart, onTouchStart, reff}) => {
    const handleDragStart = (event) => {
        event.dataTransfer.setData('text/plain', id);
        onDragStart(id);
    };

    const handleTouchStart = (event) => {
        onTouchStart(id);
    };

    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    return (
        <div ref={reff}
            id={id}
            className={cl.draggable}
            draggable={!isTouchDevice}
            onDragStart={!isTouchDevice ? handleDragStart : undefined}
            onTouchStart={isTouchDevice ? handleTouchStart : undefined}
        > </div>
    );
};

export default DraggableElem;