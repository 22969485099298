import {$authHost, $host} from "./index";
import {setIsAuth, setUser} from "../store/userReducer";
import {useDispatch} from "react-redux";

export const registration = async (email, password) => {
    const {data} = await $host.post('signup', {email, password})
    localStorage.setItem('token', data.accessToken)
    return data
}

export const useLogin = () => {
    const dispatch = useDispatch();
    const login = async (email, password) => {
        const {data} = await $host.post('login', {email, password});
        localStorage.setItem('token', data.accessToken);
        dispatch(setIsAuth(true));
        dispatch(setUser(data));
        return data;
    };

    return login;
};

export const checkAuth = async () => {
    const {data} = await $host.get('refresh')
    localStorage.setItem('token', data.accessToken)
    return data

}

export const logout = async () => {
    const {data} = $host.post('logout',)
    localStorage.removeItem('token')
    return data
}

export const resendActivationLink = async (email, password) => {
    const {data} = await $authHost.post('resendActivationLink', {email, password})
    return data
}

export const updateFullName = async (newFullName) => {
    const {data} = await $authHost.post(`updateFullName`, {newFullName})
    return data
}

export const changePassword = async (oldPassword, newPassword) =>{
    const {data} = await $authHost.post(`changePassword`, {oldPassword, newPassword})
    return data;
}

export const sendNewPassword = async (email) =>{
    const {data} = await $authHost.post(`sendNewPassword`, {email})
    return data;
}

export const getOnlineUsers = async () =>{
    const {data} = await $host.get(`getOnlineUsers`)
    return data;
}
