import React, {useEffect, useState} from 'react';
import cl from './EventCountdown.module.css'
import {accentColor} from "../../../shared/styles/styles";

const EventCountdown = () => {
    const [timeLeft, setTimeLeft] = useState({});
    const endDate = '2023-12-31T23:59:59';
    useEffect(() => {
        setTimeLeft(getTimeLeft(endDate))
        let interval = setInterval(() => {
            setTimeLeft(getTimeLeft(endDate))
        }, 1000)

        return () => clearInterval(interval)
    }, [])

    function getTimeLeft(endDate) {
        const targetDate = endDate instanceof Date ? endDate : new Date(endDate);
        const now = new Date();
        const timeLeft = targetDate.getTime() - now.getTime();

        if (timeLeft < 0) {
            return [0, 0, 0, 0];
        }

        const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((timeLeft / (1000 * 60)) % 60);
        const seconds = Math.floor((timeLeft / 1000) % 60);

        return {days, hours, minutes, seconds};
    }

    return (
        <div>
            <div className={cl.wrap}>
                <h3 className={cl.title}>New Year <span style={{color:accentColor}}>Countdown</span> </h3>
                <div className={cl.timeLeft}>
                    <div className={cl.timeWrap}>
                        <span>{timeLeft.days}</span>
                        <span>{timeLeft.hours}</span>
                        <span>{timeLeft.minutes}</span>
                        <span>{timeLeft.seconds}</span>
                    </div>
                    <div className={cl.unitsWrap}>
                        <span>Days</span>
                        <span>Hours</span>
                        <span>Minutes</span>
                        <span>Seconds</span>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default EventCountdown;