import React, {useRef} from 'react';
import cl from './HamburgerIcon.module.css'

const HamburgerIcon = ({style = {top: 0, left: 0}}) => {
    const sidebarList = useRef(null)
    return (
        <div className={cl.wrap} style={style}>
            <div className={cl.header}>

            </div>
            <div ref={sidebarList} className={cl.sidebar}>
                <div className={cl.hamburgerWrap}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <ul className={cl.sidebarList}>
                    <li>
                        Home
                    </li>
                    <li>
                        Pet
                    </li>
                    <li>
                        Login
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default HamburgerIcon;