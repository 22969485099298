import React from 'react';
import cl from './AboutCodeFeatures.module.css'
import TitleWithComment from "../../../entities/TitleWithComment/TitleWithComment";
import Code from "../../../entities/Code/Code";

const AboutCodeFeatures = () => {
    const code =
        'const btn = document.getElementById(\'btn\');\n' +
        'const nav = document.getElementById(\'nav\');\n \n' +
        'btn.addEventListener(\'click\', () => {\n' +
        '\tnav.classList.toggle(\'active\');\n' +
        '\tbtn.classList.toggle(\'active\'); );}';
    return (
        <div className={cl.wrap}>
            <h2 className={cl.title}>Easy to progress</h2>
            <div style={{display: 'flex',  justifyContent:'space-around'}}>
                <div className={cl.titlesWrap}>
                    <div className={cl.titleWithCommentWrap}>
                        <TitleWithComment data={{
                            title: 'Convenient hints',
                            comment: 'Hover the code to see the details',
                        }}/>
                    </div>
                    <div className={cl.titleWithCommentWrap}>
                        <TitleWithComment data={{
                            title: '5 lines of code',
                            comment: 'You need to write first projects',
                        }}/>
                    </div>
                </div>

                <div className={cl.code}>
                    <Code code={code}/>
                    <div className={`${cl.frontRectangle} ${cl.rect}`}></div>
                    <div className={`${cl.backRectangle} ${cl.rect}`}></div>
                </div>
            </div>

        </div>
    );
};

export default AboutCodeFeatures;