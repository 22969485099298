import React, {useState} from 'react';
import cl from './NavMenu.module.css'
import MenuItem from "../../shared/UI/MenuItem/MenuItem";
import EnterAccountBtns from "../EnterAccountBtns/EnterAccountBtns";
import {useMediaQuery} from "../../shared/utils/hooks/useMediaQuery/useMediaQuery";
import {useSelector} from "react-redux";

const NavMenu = ({menuItems, hamIsActive}) => {
    //TODO write hover on menu item
    //const [hoverRef, itemIsHovered] = useHover();
    //TODO write folded menu feature
    const user = useSelector(state => state.user.user.user)

    const isHamItemActive = useMediaQuery('(max-width: 992px)');

    const navMenuItemActiveStyles = {
        borderBottom: '2px solid var(--dark-primary-color)'
    }
    const hamNavMenuItemActiveStyles = {
        border: 'none',
        background: 'var(--dark-primary-color)',
        color: 'var(--light-primary-color)'
    }
    const offerStyles = {
        borderBottom: '2px solid var(--lilac)'
    }
    const hamOfferStyles = {
        background: 'var(--dark-primary-color)',
        color: 'var(--light-primary-color)'
    }
    const defineActiveStyle = (id) => {
        const activeStyles = isHamItemActive ? hamNavMenuItemActiveStyles : navMenuItemActiveStyles
        const offerActiveStyles = isHamItemActive ? hamOfferStyles : offerStyles;
        return id === 'offer' ? offerActiveStyles : activeStyles
    }
    return (
        <nav className={cl.navMenu + (hamIsActive ? ` ${cl.activeHam}` : '')}>
            <ul className={cl.navList}>
                {
                    menuItems.map((i) => {
                            return (!(i.title === 'Buy' && user?.subscriptionStatus === 'Paid') && !(i.id === 'introduction' && user?.subscriptionStatus !== 'Paid')&&
                                <li key={i.id}>
{}
                                    <MenuItem
                                        foldingLevel={0}
                                        key={i.id}
                                        id={i.id}
                                        activeStyles={defineActiveStyle(i.id)}>
                                        {i.title === "Buy" ?
                                            <span style={{color: "var(--lilac)", fontWeight: 'var(--semiBold)'}}>All projects access </span>
                                            : i.title}
                                    </MenuItem>
                                </li>)
                        }
                    )
                }
                    </ul>
                    <div className={cl.enterAccountBtnsWrap}>
                    <EnterAccountBtns/>
                    </div>
                    </nav>
                    );
                };

export default NavMenu;