import React from 'react';
import {Link, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {logout} from "../../shared/api/userApi";
import {setIsAuth, setUser} from "../../shared/store/userReducer";

const LogOut = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const logoutHandle = async () => {
        try {
            const data = await logout()
            dispatch(setIsAuth(false))
            dispatch(setUser({}))
            navigate("/login")
        } catch (e) {
            alert(e.response.data.message)
        }

    }

    return (
        <Link to={'/logout'} onClick={logoutHandle} className="buttonLilac">
            log out
        </Link>
    );
};

export default LogOut;