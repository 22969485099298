import React, {useState} from 'react';
import cl from './OfferSmall.module.css'
import backgroundImg from "../../shared/assets/projectsBackground.png";
import Logo from "../../shared/UI/Logo/Logo";
import {Link} from "react-router-dom";
import Button_lilac from "../../shared/UI/Button_lilac/Button_lilac";
import PackIcon from "../../shared/UI/icons/PackIcon";
import {useSelector} from "react-redux";
import PaypalPopup from "../PaypalPopup/PaypalPopup";

const OfferSmall = () => {
    const isAuth = useSelector(state => state.isAuth.isAuth)
    const [showPaymentGate, setShowPaymentGate] = useState(false)

    return (
        <div className={cl.wrap}
             style={{background: `var(--light-primary-color) url(${backgroundImg}) no-repeat 100% 76%`}}>
            <div className={cl.offer}>
                <div className={cl.logoWrap}>
                    <Logo/>
                </div>
                <h2 className={cl.title}>Get access to all projects and courses</h2>


                <div className={cl.buttonWrap}>
                    {isAuth ?
                        <Button_lilac onClick={() => setShowPaymentGate(!showPaymentGate)}>Get 20 projects
                            pack <PackIcon/>
                        </Button_lilac>
                        :
                        <Link to={'/login'}>
                            <Button_lilac>Get 20 projects pack <PackIcon/> </Button_lilac>
                        </Link>
                    }
                </div>
                <div className={cl.priceExplanation}>
                    <p className={cl.bonus}>HTML, CSS, and JavaScript courses for projects<br/> are <span
                        style={{color: "var(--lilac)"}}>included</span> in the pack </p>
                    <div className={cl.priceExplanationSeparation}></div>
                    <div className={cl.costWrap}>
                        <p className={cl.oldCost}>1 project = 2 $</p>
                        <p className={cl.cost}>1 project = 1 $</p>
                    </div>
                </div>
                <PaypalPopup  showPaymentGate={showPaymentGate}  setShowPaymentGate={setShowPaymentGate}/>

            </div>
        </div>
    );
};

export default OfferSmall;