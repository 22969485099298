import React, {useState} from 'react';
import cl from './Slider.module.css'
import Face1 from './Face1.png'
import Face2 from './Face2.png'
import Face3 from './Face3.png'

const Slider = () => {
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

    const goToNextSlide = () => {
        setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % 3);
    };

    const goToPrevSlide = () => {
        setCurrentSlideIndex((prevIndex) => (prevIndex - 1 + 3) % 3);
    };

    const peopleData = [
        {imgSrc: Face2, name: 'Miley', duty: 'Engineer'},
        {imgSrc: Face3, name: 'Angel', duty: 'Manager'},
        {imgSrc: Face1, name: 'Adam', duty: 'Engineer'},
    ]
    return (
        <div className={cl.wrap}>
            <button onClick={goToPrevSlide}>&lt;</button>
            <div className={cl.sliderContainer}>
                {peopleData.map((person, index) =>
                    <div className={cl.slide}
                    style={currentSlideIndex===((index-1+3)%3)?{transform:'translateX(-100%)  translateY(-60%)'}:
                        currentSlideIndex===((index+1)%3)?{transform:'translateX(100%) translateY(-60%)'}:{}
                    }
                    >
                            <img src={person.imgSrc} alt={`${person.name} photo`}/>

                        <h3 className={cl.name}>{person.name}</h3>
                        <p className={cl.duty}>{person.duty}</p>
                    </div>
                )}

            </div>


            <button onClick={goToNextSlide}>&gt;</button>
        </div>
    );
};

export default Slider;