import React, {useEffect, useState} from 'react';
import cl from './Login.module.css'
import Button_lilac from "../../shared/UI/Button_lilac/Button_lilac";
import {Link, useNavigate} from "react-router-dom";
import {useLogin} from "../../shared/api/userApi";
import Error from "../../shared/UI/Error/Error";
import useCollectErrors from "../../shared/utils/hooks/useCollectErrors/useCollectErrors";
import RecoverPassword from "../../features/RecoverPassword/RecoverPassword";
import PasswordInput from "../../entities/PasswordInput/PasswordInput";
import ResendActivationMail from "../../entities/ResendActivationMail/ResendActivationMail";

const Login = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showResend, setShowResend] = useState(false)
    const login = useLogin();
    const [loginWithCollectedErrors, errors] = useCollectErrors(() => login(email, password));
    const navigate = useNavigate();


    const handleLoginSubmit = async (e) => {
        e.preventDefault();
        const response = await loginWithCollectedErrors();
        if (response) navigate('/me')
    }
    useEffect(() => {
        setShowResend(false)
    }, [email, password])

    useEffect(() => {
        setShowResend(errors.find(error => error.place === 'emailActivation') !== undefined)
    }, [errors])
    return (
        <div className={cl.wrap+" flexCenter"}>

            <form action="" className={cl.form} autoComplete='on'>
                <h1 className={cl.title}>
                    Log in to Wripet
                </h1>
                <div className={cl.inputsWrap}>
                    {/*todo autoComplete form*/}
                    <input name="email" id="email" type="email" placeholder='Email or username'
                           onChange={e => setEmail((e.target.value))}/>
                    <Error errors={errors} type="email"/>
                    {errors.length !== 0 && showResend &&
                        <ResendActivationMail email={email} password={password} errors={errors}/>
                    }
                    <PasswordInput setPassword={setPassword} errors={errors}/>
                </div>
                <div className={cl.submitButtonWrap}>
                    <Button_lilac onClick={(e) => handleLoginSubmit(e)} type="submit">Log
                        in</Button_lilac>
                </div>
                <div className={cl.sendNewPasswordButtonWrap}>
                    <RecoverPassword email={email} propsFirstButton={{}} propsSecondButton={{style:{fontSize:'var(--text6)', borderRadius:'0'}}}/>
                </div>
                {/*TODO <LoginExternalWay/>*/}
                <p className={cl.signupLinkWrap}>
                    Not a member yet? <Link to={'/signup'} className={cl.signupLink}>Sign up for free</Link>
                </p>
            </form>
        </div>
    );
};

export default Login;