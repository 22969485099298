const defaultState = {
    pageIsLoading:false
}

const ActionTypes = {
    SET_PAGE_IS_LOADING: "SET_PAGE_IS_LOADING",
};

export const pageIsLoading = (state = defaultState, action) => {
    switch (action.type) {
        case ActionTypes.SET_PAGE_IS_LOADING:
            return {...state, pageIsLoading: action.payload}
        default:
            return state;
    }
}


export const setPageIsLoading = (payload) => ({type: ActionTypes.SET_PAGE_IS_LOADING, payload})