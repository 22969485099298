import React from 'react';
import cl from './ToastNotificationsIcon.module.css'

const ToastNotificationsIcon = ({style={top:0, left:0}}) => {

    return (
        <div className={cl.wrap} style={style}>
            <div className={cl.toastsWrap}>
                <div className={cl.firstToast}><span>Hi</span></div>
                <div className={cl.secondToast}><span>It's</span></div>
                <div className={cl.thirdToast}><span>Toasts</span></div>
            </div>

        </div>
    );
};

export default ToastNotificationsIcon;