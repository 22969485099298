import React, {useEffect, useState} from 'react';
import cl from './MenuItem.module.css'
import {Link, useLocation} from 'react-router-dom'
import {getCrumbsArray} from "../../utils/getCrumbsArray";
import {useDispatch, useSelector} from "react-redux";
import {setPageIsLoading} from "../../store/pageIsLoading";

const MenuItem = ({
                      styles = {},
                      foldingLevel,
                      id,
                      children,
                      activeStyles = {},
                      linkForMenuItem = '/' + id,
                      ...props
                  }) => {
    let loc = useLocation();
    const wordInCrumbs = getCrumbsArray(loc.pathname)[foldingLevel];
    const isActive = wordInCrumbs === getCrumbsArray(id)[0];
    //const isActive = wordInCrumbs === id;
    const allStyles = {...styles, ...(isActive ? activeStyles : {})};

    const pageIsLoading = useSelector(state => state.pageIsLoading.pageIsLoading)
    const dispatch = useDispatch();
    const blockNavLinks = ()=>{
        if(!pageIsLoading){
            dispatch(setPageIsLoading(true))
            setTimeout(() => dispatch(setPageIsLoading(false)), 500)
        }
    }
    return (
        <div onClick={blockNavLinks}>
            <Link to={linkForMenuItem}  {...props} className={cl.navMenuItem} style={pageIsLoading?{...allStyles, pointerEvents: 'none'}:allStyles}>
                {children}
            </Link>
        </div>

    );
};

export default MenuItem;