import React from 'react';
import cl from '../Guide.module.css'
import localCl from './HtmlGuide.module.css'
import TitleWithComment from "../../../entities/TitleWithComment/TitleWithComment";
import TitleNumbered from "../../../entities/TitleNumbered/TitleNumbered";
import OfferSmall from "../../../widgets/OfferSmall/OfferSmall";
import SyntaxHighlighter from "react-syntax-highlighter";
import {vs2015} from "react-syntax-highlighter/dist/cjs/styles/hljs";
import {useSelector} from "react-redux";

const HtmlGuide = ({}) => {
        const user = useSelector(state => state.user.user.user);
        return (
            <div className={cl.wrap + ' ' + localCl.wrap} style={user?.subscriptionStatus !== 'Paid'?{maxHeight: '1447px'}:{}}>
                <div className={cl.courseWrap}>
                    <div className={cl.titleWrap}>
                        <TitleWithComment
                            data={{title: 'HTML', comment: 'Essential HTML details for all projects'}}/>
                    </div>
                    <div className={cl.paragraphsWrap}>
                        <div className={cl.paragraphWrap}>
                            <div className={cl.courseTitleWrap}>
                                <TitleNumbered title={'What is HTML?'} number={1}/>
                            </div>
                            <p className={cl.courseTextWrap}>
                                HTML, or <b>Hypertext Markup Language</b>, is the standard way we create web pages and
                                applications. Unlike programming, it's more like giving instructions on <b>how to
                                arrange</b> content<br/><br/>
                                Using <b>tags</b> and <b>attributes</b>, HTML allows organize content and define
                                elements on
                                a webpage. It's important to note that HTML primarily defines
                                the <b>structure</b> and <b>content</b> of a
                                webpage or app and <b>does not</b> handle styles or interactive features <br/><br/>With
                                HTML, you can create headings, paragraphs,
                                add images, create links, and much more
                            </p>
                        </div>
                        <div className={cl.paragraphWrap}>
                            <div className={cl.courseTitleWrap}>
                                <TitleNumbered title={'Structure of HTML'} number={2}/>
                            </div>
                            <p className={cl.courseTextWrap}>
                                HTML documents consist of a series of elements that are enclosed
                                in <b>opening</b> and <b>closing
                                tags</b>. Tags
                                are represented by <b>angle brackets</b> (
                                <b>&lt;</b> and <b>&gt;</b>), and they provide instructions to the web browser on how to
                                display the
                                content.
                                <br/><br/> An opening
                                tag denotes the beginning of an element, and a closing tag denotes the end of an
                                element.
                                Some
                                elements can also be <b>self-closing</b>, meaning they don't require a closing
                                tag<br/><br/>
                                So there are <b>two types</b> of tags and HTML code mostly
                                consists of different combination of them
                            </p>
                            <div className={cl.codeExampleWrap}>
                                <div>
                                    <SyntaxHighlighter wrapLongLines={false} language="htmlbars" style={vs2015}>
                                        {'<p> any text </p>'}
                                    </SyntaxHighlighter>
                                    <p><b>&lt;p&gt;</b> - is opening tag</p>
                                    <p><b>&lt;/p&gt;</b> - is closing tag <br/> <br/></p>
                                    <p className={cl.mb}><b>&lt;p&gt;&lt;/p&gt;</b> - tag stands
                                        for plain text</p>
                                </div>
                                <div>
                                    <SyntaxHighlighter wrapLongLines={false} language="htmlbars" style={vs2015}>
                                        {'<h1> any title </h1>'}
                                    </SyntaxHighlighter>
                                    <p><b>&lt;h1&gt;</b> - is opening tag</p>
                                    <p><b>&lt;/h1&gt;</b> - is closing tag <br/> <br/></p>
                                    <p><b>&lt;h1&gt;&lt;/h1&gt;</b> - tag stands
                                        for title</p>
                                </div>
                            </div>
                            <div className={cl.codeExampleWrap}>
                                <div>
                                    <SyntaxHighlighter wrapLongLines={false} language="htmlbars" style={vs2015}>
                                        {'<img>'}
                                    </SyntaxHighlighter>


                                    <p><b>&lt;img&gt;</b> - is self-closing tag <br/><br/></p>
                                    <p className={cl.mb}><b>&lt;img&gt;</b> - tag is used for image
                                        on web site</p>
                                </div>
                                <div>
                                    <SyntaxHighlighter wrapLongLines={false} language="htmlbars" style={vs2015}>
                                        {'<input>'}
                                    </SyntaxHighlighter>

                                    <p><b>&lt;input&gt;</b> - is self-closing tag <br/><br/></p>
                                    <p><b>&lt;input&gt;</b> - field where user can
                                        enter data</p>
                                </div>

                            </div>
                            <p className={cl.courseTextWrap}>
                                But we can't put an image itself in our code editor.
                                So we can use <b>properties</b> to solve this
                            </p>
                            <SyntaxHighlighter wrapLongLines={false} language="htmlbars" style={vs2015}>
                                {'<img src="image.jpg">'}
                            </SyntaxHighlighter>
                            <p className={cl.courseTextWrap + ' ' + cl.mt}>
                                Let's add <b>another property</b> to our image in case
                                of slow internet connection of our user. In such case
                                it is better to replace the image with text. It is possible
                                with <b>alt</b> property
                            </p>
                            <SyntaxHighlighter wrapLongLines={false} language="htmlbars" style={vs2015}>
                                {'<img src="image.jpg" alt="Your image description">'}
                            </SyntaxHighlighter>
                            <p className={cl.courseTextWrap + ' ' + cl.mt}>
                                <b>*</b> In HTML, the use of <b>whitespace</b>, including spaces and line breaks,
                                within tags like &lt;p&gt; <b>doesn't</b> typically <b>affect</b> the rendering of
                                content. This means
                                that:
                            </p>
                            <SyntaxHighlighter wrapLongLines={false} language="htmlbars" style={vs2015}>
                                {'<p>Any text</p>'}
                            </SyntaxHighlighter>
                            <p className={cl.courseTextWrap}>
                                is essentially the same as:
                            </p>
                            <SyntaxHighlighter wrapLongLines={false} language="htmlbars" style={vs2015}>
                                {'<p>\n' +
                                '      Any text\n' +
                                '</p>'}
                            </SyntaxHighlighter>
                            <p className={cl.courseTextWrap}>
                                Using extra spaces and line breaks within HTML tags, like in &lt;p&gt; tags, typically
                                doesn't
                                affect how the content is displayed on a webpage, but it
                                can <b>improve</b> code <b>readability</b> and
                                <b> maintenance</b><br/><br/>
                                In HTML, if you want to start a new line, you can simply use the <b>&lt;br&gt;</b> tag.
                                There are also other ways to do it
                            </p>


                        </div>
                        {user?.subscriptionStatus === 'Paid' && <div>
                            <div className={cl.paragraphWrap}>
                                <div className={cl.courseTitleWrap}>
                                    <TitleNumbered title={'Grouping elements'} number={3}/>
                                </div>
                                <p className={cl.courseTextWrap}>
                                    You can also fold elements into each other and <b>group</b> them.
                                    What if we have 3 images in a row on our site? There are 2 tags
                                    to help us. <b>&lt;div&gt;</b> and <b>&lt;span&gt;</b>. Their primary function is group
                                    elements <br/><br/>
                                    <b className={cl.tag}>&lt;div&gt;</b> is like a <b>box</b> for organizing and dividing
                                    elements,
                                    while <b className={cl.tag}>&lt;span&gt;</b> is
                                    a special tool like a highlighter for picking out and styling specific <b>parts
                                    of text</b>, like a word or a small section
                                </p>
                                <SyntaxHighlighter wrapLongLines={false} language="htmlbars" style={vs2015}>
                                    {'<div>\n' +
                                    '        <img src="image_1.jpg" alt="Here must be a first cat">\n' +
                                    '        <img src="image_2.jpg" alt="Here must be a second cat">\n' +
                                    '        <img src="image_3.jpg" alt="Here must be a third cat">\n' +
                                    '</div>'}
                                </SyntaxHighlighter>
                                <SyntaxHighlighter wrapLongLines={false} language="htmlbars" style={vs2015}>
                                    {'<p>\n' +
                                    '        Hello! I am Steve. My address\n' +
                                    '        is <span> street primise + extension, postcode,\n' +
                                    '        city, country</span>\n' +
                                    '</p>'}
                                </SyntaxHighlighter>
                                <p className={cl.courseTextWrap + ' ' + cl.mt}>
                                    You can improve code readability in HTML by adding a <b>class</b> property to elements.
                                    This property helps <b>name</b> the element, making your code <b>easier
                                    to understand and maintain</b><br/><br/>
                                    For example, you can name a &lt;div&gt; element "catImages" using the 'class' property:
                                </p>
                                <SyntaxHighlighter wrapLongLines={false} language="htmlbars" style={vs2015}>
                                    {'<div class="catImages images">\n' +
                                    '          <img src="image_1.jpg" alt="Here must be a first cat">\n' +
                                    '          <img src="image_2.jpg" alt="Here must be a second cat">\n' +
                                    '          <img src="image_3.jpg" alt="Here must be a third cat">\n' +
                                    '</div>'}
                                </SyntaxHighlighter>
                                <p className={cl.courseTextWrap + ' ' + cl.mt}>
                                    *You can add multiple classes to an element by separating the class names with
                                    spaces<br/><br/>
                                    In addition, using the 'class' property allows you to <b>refer specifically</b> to that
                                    element in your CSS or JavaScript code. This will help
                                    you <b>style</b> or <b>interact</b> with just
                                    the elements you want, rather than affecting all similar elements on the page<br/><br/>
                                    And here is another example to get better understanding:
                                </p>
                                <SyntaxHighlighter wrapLongLines={false} language="htmlbars" style={vs2015}>
                                    {'<div class="images">\n' +
                                    '          <div class="cats">\n' +
                                    '                  <img src="image_cat_1.jpg" alt="Here must be a first cat">\n' +
                                    '                  <img src="image_cat_2.jpg" alt="Here must be a second cat">\n' +
                                    '          </div>\n' +
                                    '          <div class="dogs">\n' +
                                    '                  <img src="image_dog_1.jpg" alt="Here must be a first dog">\n' +
                                    '                  <img src="image_dog_2.jpg" alt="Here must be a second dog">\n' +
                                    '          </div>\n' +
                                    '</div>'}
                                </SyntaxHighlighter>
                            </div>
                            <div className={cl.paragraphWrap}>
                                <div className={cl.courseTitleWrap}>
                                    <TitleNumbered title={'Practical part. How to run the code'} number={4}/>
                                </div>
                                <p className={cl.courseTextWrap}>
                                    We've written code in a text file, but how do we turn it into a <b>webpage</b>? To view
                                    it,
                                    just
                                    change the file's extension to <b>.html</b> and open it in a web browser (or just click
                                    on
                                    the icon of the file).
                                    Browsers will interpret
                                    the HTML and display it as a webpage
                                    <br/><br/>
                                    You <b>don't have to remember</b> the standard structure of an HTML document. You can
                                    copy
                                    and use
                                    it for future projects, but it's important to understand how it works
                                    <br/><br/>
                                    A basic HTML structure is <b>crucial</b> because it ensures web pages look the same in
                                    different
                                    browsers, follows web standards, makes code easier to read, helps with SEO, and works
                                    well
                                    with web development tools. While HTML can work without it, following these practices
                                    <b> improves webpage quality and usability</b>
                                </p>
                                <SyntaxHighlighter wrapLongLines={false} language="htmlbars" style={vs2015}>
                                    {'<!doctype html>\n' +
                                    '<html>\n' +
                                    '<head>\n' +
                                    '       <title>My Webpage</title>\n' +
                                    '</head>\n' +
                                    '<body>\n' +
                                    '       <h1>Welcome to My Webpage</h1>\n' +
                                    '       <p>This is a sample paragraph.</p>\n' +
                                    '       <img src="image.jpg" alt="Description of the image">\n' +
                                    '       <a href="https://example.com">Visit Example.com</a>\n' +
                                    '</body>\n' +
                                    '</html> '}

                                </SyntaxHighlighter>
                                <p className={cl.courseTextWrap}>
                                    <b>&lt;!doctype html&gt;</b>: This declaration specifies the version
                                    of HTML being used<br/><br/>
                                    <b>&lt;html&gt;</b>: The root element that encapsulates the
                                    entire HTML content<br/><br/>
                                    <b>&lt;head&gt;</b>: Contains metadata about the HTML document,
                                    such as the title, character encoding, stylesheets, and scripts<br/><br/>
                                    <b>&lt;title&gt;</b>: Specifies the title of the webpage, which appears
                                    in the browser's title bar or tab<br/><br/>
                                    <b>&lt;body&gt;</b>: Encloses the visible content of the webpage, including text,
                                    images,
                                    links, and other HTML elements<br/><br/>
                                    <b>&lt;a&gt;</b>: Tag is responsible for creating hyperlinks, allowing users to navigate
                                    to
                                    other web pages or resources.
                                    It defines the clickable area and specifies the destination using the 'href' attribute
                                </p>
                            </div>
                            <div className={cl.paragraphWrap}>
                                <div className={cl.courseTitleWrap}>
                                    <TitleNumbered title={'Practical part. Conclusion'} number={5}/>
                                </div>
                                <p className={cl.courseTextWrap}>
                                    You've got a good grasp of HTML structure. For the future works you can
                                    simply <b>copy</b> a
                                    basic HTML template and
                                    then <b>search</b> for the tags you need. For example, if you want to add a link to your
                                    site, you
                                    can search online and find that the self-closing &lt;a&gt; tag is used for this. Don't
                                    forget to
                                    <b> group</b> elements together! We also learned that you can <b>specify</b> elements
                                    using
                                    the <b>class</b>
                                    property, which comes in handy when working with CSS and JavaScript.
                                    With <b>practice</b>,
                                    you'll
                                    start remembering most of the tags
                                    <br/><br/>
                                    <b>Great job!</b> Now you can structure websites and understand the HTML code for <b>any
                                    our
                                    project</b>
                                </p>
                            </div>
                            <div className={cl.paragraphWrap}>
                                <div className={cl.courseTitleWrap}>
                                    <TitleNumbered title={'10 the most popular tags in HTML'} number={'*6'}/>
                                </div>
                                <ol>
                                    <li><p><b className={cl.tag}>&lt;div&gt;</b> The tag is a <b>container</b> element used
                                        to
                                        group and style
                                        other
                                        HTML
                                        elements. It is commonly used for layout and organizing content</p>

                                    </li>
                                    <li><p><b className={cl.tag}>&lt;p&gt;</b> The &lt;p&gt; tag defines
                                        a <b>paragraph</b> of
                                        text. It is used to
                                        structure and format blocks of textual content</p>

                                    </li>
                                    <li><p><b className={cl.tag}>&lt;a&gt;</b> The &lt;a&gt; tag creates
                                        a <b>hyperlink</b> and
                                        is used to link one
                                        web
                                        page to another or to specific locations within the same page. It is crucial for
                                        <b>navigation</b> and connecting different parts of a website</p>

                                    </li>
                                    <li><p><b className={cl.tag}>&lt;img&gt;</b> The &lt;img&gt; tag is used to <b>embed an
                                        image</b> in an HTML
                                        document. It specifies the source file (src) of the image, can include
                                        alternative text (alt) for accessibility and many other properties</p>

                                    </li>
                                    <li><p><b className={cl.tag}>&lt;ul&gt;</b> and <b
                                        className={cl.tag}>&lt;li&gt;</b> The &lt;ul&gt; and &lt;li&gt; tags are used
                                        to create <b>unordered lists</b>.
                                        The &lt;ul&gt; tag represents the entire list, while &lt;li&gt; tags define
                                        individual list items</p>

                                    </li>
                                    <li><p><b className={cl.tag}>&lt;h1&gt;</b> to <b
                                        className={cl.tag}>&lt;h6&gt;</b> These
                                        heading tags
                                        represent different
                                        levels of <b>headings</b>,
                                        with &lt;h1&gt; being the highest level and &lt;h6&gt; being the lowest. They are
                                        used to
                                        structure and provide <b>hierarchy</b> to the content on a webpage</p>

                                    </li>
                                    <li><p><b className={cl.tag}>&lt;span&gt;</b> The &lt;span&gt; tag is
                                        an <b>inline</b> element used for
                                        styling or applying additional
                                        formatting to a specific <b>portion of text</b> within a larger block of content</p>

                                    </li>
                                    <li><p><b className={cl.tag}>&lt;table&gt;</b> The &lt;table&gt; tag is used to create
                                        a <b>table</b> within an
                                        HTML
                                        document. It consists of rows (<b>&lt;tr&gt;</b>), table data cells
                                        (<b>&lt;td&gt;</b>), and optionally table header cells (<b>&lt;th&gt;</b>)</p>

                                    </li>
                                    <li><p><b className={cl.tag}>&lt;form&gt;</b> The &lt;form&gt; tag is used to create
                                        an <b>interactive
                                            form</b> on
                                        a
                                        webpage. It allows users to input data and submit it to a server for processing</p>

                                    </li>
                                    <li><p><b className={cl.tag}>&lt;input&gt;</b> The &lt;input&gt; tag is usually used
                                        within
                                        a &lt;form&gt; to
                                        create various types of <b>input fields</b>, such as text input, checkboxes, radio
                                        buttons,
                                        and more</p>

                                    </li>
                                </ol>
                            </div>
                        </div>
                        }

                    </div>
                    {/* TODO make absolute position relative to body or other way */}

                </div>
                {user?.subscriptionStatus !== 'Paid' && <div className={cl.banner}><div className={cl.blur}></div><div className={cl.offerSmall}><OfferSmall/></div></div>}
            </div>
        );
    }
;

export default HtmlGuide;