import React from 'react';
import cl from './Js.module.css'
import TitleWithComment from "../../../entities/TitleWithComment/TitleWithComment";
import Code from "../../../entities/Code/Code";
import Button_lilac from "../../../shared/UI/Button_lilac/Button_lilac";

const Js = ({code}) => {
    return (
        <div className={cl.wrap}>
            <div className={cl.jsHoverHintTitleWrap}>

                <TitleWithComment
                    data={{
                        title: code?'Java Script code':'No java script required',
                        comment: code? <div><div style={{marginBottom:'20px'}} >Hover the command to see the details </div><Button_lilac onClick={() =>  navigator.clipboard.writeText(code)}>Copy the code</Button_lilac></div>:'But it\'s still useful project to learn',
                        leftHeight: '18vh'
                    }}/>
            </div>
            {code &&
            <Code code={code} isProjectListItem={true}/>
            }

        </div>
    );
};

export default Js;