import React, {forwardRef, useEffect, useMemo, useRef, useState} from 'react';
import cl from './Command.module.css'
import {getCommandByName} from "../../../shared/api/commandApi";
import {useHover} from "../../../shared/utils/hooks/useHover/useHover";
import {codePalette} from "../../../shared/styles/styles";
import Hint from "../Hint/Hint";
import {typesOfCommand as types} from "../CommandsData";
import {createPortal} from 'react-dom';


const Command = ({commandName, dataOfType}) => {
    const [commandNode, isHoveredHook] = useHover();
    const [isHovered, setIsHovered] = useState();
    const [commandTop, setCommandTop] = useState(null)
    const [commandLeft, setCommandLeft] = useState(null)
    const [domReady, setDomReady] = React.useState(false)

    React.useEffect(() => {
        setDomReady(true)
    }, [])
    useEffect(() => {
        setIsHovered(isHoveredHook);
    }, [isHoveredHook])
    useEffect(() => {
        if (commandNode.current) {
            const parent = document.getElementById('jsCodee');
            const parentRect = parent.getBoundingClientRect();
            const childRect = commandNode?.current?.getBoundingClientRect();
            setCommandTop(commandNode.current.offsetTop + commandNode.current.offsetHeight + 5)
            setCommandLeft(childRect.left - parentRect.left + parent.scrollLeft)
            let scrollStart = false;
            document.getElementById('jsCodeWrap').addEventListener('scroll', () => {
                if (!scrollStart) {
                    setIsHovered(false)
                }
                scrollStart = true;

            })
            document.getElementById('jsCodeWrap').addEventListener('scrollend', () => {
                scrollStart = false;
                setIsHovered(isHoveredHook)
            })
        }
    }, [commandNode, isHovered])


    const [commandData, setCommandData] = useState({});


    useEffect(() => {
        const fetchCommand = async (name) => {
            if (dataOfType === 'command') {
                const cachedData = localStorage.getItem(name);
                if (cachedData) {
                    setCommandData(JSON.parse(cachedData));
                } else {
                    try {
                        // Make API call to fetch the data
                        const response = await getCommandByName(name);
                        setCommandData(response);

                        // Store the result in local storage for future use
                        localStorage.setItem(name, JSON.stringify(response));
                    } catch
                        (e) {
                        setCommandData(types.err)
                    }

                }
            }
        }
        if (dataOfType === 'command')
            fetchCommand(commandName)
        else
            setCommandData(dataOfType)

    }, [dataOfType])


    const memoizedCommand = useMemo(() => commandData, [commandData]);

    return (
        <span ref={commandNode} className={cl.wrap + (isHovered ? ' ' + cl.hovered : '')}
              style={{color: codePalette[memoizedCommand.color]}}>

            {domReady
                ? createPortal(<Hint name={memoizedCommand.name} isHovered={isHovered} style={{
                    left: `${commandLeft}px`,
                    top: `${commandTop}px`
                }}>{memoizedCommand.hint}</Hint>, document.getElementById('jsCodee')) : null}
            {commandName}
        </span>);
};

export default Command;