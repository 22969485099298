import React from 'react';
import cl from './EnterAccountBtns.module.css'
import {Link, useLocation} from "react-router-dom";
import {getCrumbsArray} from "../../shared/utils/getCrumbsArray";
import {useSelector} from "react-redux";
import UserIcon from "../../shared/UI/icons/UserIcon";
import {lightPrimaryColor} from "../../shared/styles/styles";


const EnterAccountBtns = () => {
    const link = useLocation().pathname;
    const firstWordInLink = getCrumbsArray(link)[0];
    const isLoginPage = firstWordInLink === 'login';
    const isSignupPage = firstWordInLink === 'signup';

    const isAuth = useSelector(state => state.isAuth.isAuth)

    return (
        <div className={cl.wrap}>
            {!isLoginPage && !isAuth &&
            <Link to={'/login'} className="button">
                Log in
            </Link>}
            {!isSignupPage && !isAuth &&
            <Link to={'/signup'} className="buttonLilac">
                Sign up
            </Link>}
            {isAuth &&
            <Link to={'/me'} className="buttonLilac">
                Me <UserIcon color={lightPrimaryColor}/>
            </Link>}
        </div>
    );
};

export default EnterAccountBtns;