import React from 'react';
import cl from "./ThemeTogglerSmallIcon.module.css";

const ThemeTogglerSmallIcon = ({...props}) => {
    return (
        <div className={cl.wrap} {...props}>
            <div className={cl.textWrap}>
                <p className={cl.text}>Theme toggler</p>
            </div>
            <div className={cl.switcher}>
                <div className={cl.round}></div>
            </div>

        </div>
    );
};

export default ThemeTogglerSmallIcon;