import React, {useState} from 'react';
import cl from './PasswordInput.module.css'
import Error from "../../shared/UI/Error/Error";
import PasswordEyeIcon from "../../shared/UI/icons/PasswordEyeIcon";
import {accentColor, darkPrimaryColor} from "../../shared/styles/styles";

const PasswordInput = ({setPassword, errors, placeholder="Password", errorsType="password"}) => {
    const [showPassword, setShowPassword] = useState(false)

    return (
        <div>
            <div className={cl.inputWrap}>
                <input name="password"  type={showPassword?"text":"password"} placeholder={placeholder}
                       onChange={e => setPassword((e.target.value))}/>
                <div className={cl.eyeIconWrap}>
                    {/*TODO Tabindex change*/}
                    <button tabIndex={-1} onClick={(e)=>{e.preventDefault(); setShowPassword(!showPassword)}}>
                        <PasswordEyeIcon fill={showPassword?accentColor:darkPrimaryColor}/>
                    </button>
                </div>
            </div>
            <Error errors={errors} type={errorsType}/>
        </div>
    );
};

export default PasswordInput;