import React, {useEffect} from 'react';
import cl from "./Hamburger.module.css"

const Hamburger = ({hamIsActive, setHamIsActive}) => {
    useEffect(() => {
        const onWheel = (e) => {
            e.preventDefault()
        }


        if (hamIsActive) {
            window.addEventListener("wheel", onWheel, {
                passive: false
            });
        } else {
            // Remove the event listener when hamIsActive is false
            window.removeEventListener("wheel", onWheel);
        }
        if (hamIsActive) {
            window.addEventListener("touchmove", onWheel, {
                passive: false
            });
        } else {
            // Remove the event listener when hamIsActive is false
            window.removeEventListener("touchmove", onWheel);
        }



        return () => {
            window.removeEventListener("wheel", onWheel);
            window.removeEventListener("touchmove", onWheel);
        };
    }, [hamIsActive])

    return (
        <div className={cl.hamburger + (hamIsActive ? ` ${cl.activeHam}` : '')}
             onClick={() => setHamIsActive(!hamIsActive)}>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};

export default Hamburger;