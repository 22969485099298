import React from 'react';
import cl from './BallSmallIcon.module.css'

const BallSmallIcon = ({styles}) => {
    return (
        <div className={cl.wrap} style={styles}>
            <div className={cl.ball}></div>
            <div className={cl.line}></div>
        </div>
    );
};

export default BallSmallIcon;