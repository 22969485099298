import React from 'react';
import TitleWithComment from "../../../entities/TitleWithComment/TitleWithComment";
import ProjectDescription from "../../../entities/ProjectDescription/ProjectDescription";
import cl from './TryProject.module.css'

const TryProject = ({data}) => {

    return (
        <div className={cl.wrap}>
            <div className={cl.titleWithCommentWrap}>
                <TitleWithComment
                    data={{title: data.title, comment: data.comment, leftHeight: data.leftHeight}}/>
            </div>
            <div className={cl.content}>
                <div className={cl.appDemoWrap}>
                    {data.appDemo}
                </div>
                <ProjectDescription data={data.descriptionArray}/>
            </div>
        </div>
    );
};

export default TryProject;