import React from 'react';
import cl from './Logo.module.css'

const Logo = () => {
    return (
        <div>
            <p className={cl.text}>
                <span className={cl.text__firstPart}>
                    Wri
                </span>
                <span className={cl.text__secondPart}>
                    pet
                </span>
            </p>
        </div>
    );
};

export default Logo;