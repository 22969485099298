import React from 'react';
import cl from './BackgroundChangerSmallIcon.module.css'
import Button_lilac_outline from "../../Button_lilac_outline/Button_lilac_outline";

const BackgroundChangerSmallIcon = ({styles}) => {
    return (
        <div className={cl.wrap} style={styles}>
            <Button_lilac_outline>Change</Button_lilac_outline>
        </div>
    );
};

export default BackgroundChangerSmallIcon;