import React from 'react';
import cl from './LoaderSmallIcon.module.css'

const LoaderSmallIcon = ({styles}) => {
    return (
        <div className={cl.wrap} style={styles}>
            <div className={cl.loaderWrap}>
                <div className={cl.ldsRoller}>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    );
};

export default LoaderSmallIcon;