export const availableProjectMenuItemStyles = {
    fontSize:'var(--text5)',
    fontWeight:'var(--regular)',
    margin:'0 0 14px',
    display:'inline-block',
    paddingBottom:'4px',
    transform:'translateX(0)',
    borderBottom:'2px solid transparent',
}

export const PaidProjectMenuItemStyles = {
    fontSize:'var(--text5)',
    fontWeight:'var(--regular)',
    margin:'0 0 14px',
    display:'inline-block',
    paddingBottom:'4px',
    transform:'translateX(0)',
    borderBottom:'2px solid transparent',
    color:'grey'
}



export const projectMenuItemActiveStyles = {
    borderBottom:'2px solid var(--lilac)',
    transform:'translateX(10px)',
    fontWeight:'var(--semiBold)'
}