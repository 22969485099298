import React from 'react';
import cl from './Css.module.css';
import { vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import SyntaxHighlighter from 'react-syntax-highlighter';
import Title from "../../../shared/UI/Title/Title";
import TitleWithComment from "../../../entities/TitleWithComment/TitleWithComment";
import Button_lilac from "../../../shared/UI/Button_lilac/Button_lilac";

const Css = ({name, code}) => {
    return (
        <div className={cl.wrap}>
            <div className={cl.titleWithCommentWrap}>
                <TitleWithComment
                    data={{title: 'CSS styles', comment:  <div><div style={{marginBottom:'20px'}} >{name} project</div><Button_lilac onClick={() =>  navigator.clipboard.writeText(code)}>Copy the code</Button_lilac></div>, leftHeight: '18vh'}}/>
            </div>
            <SyntaxHighlighter  wrapLongLines={true} language="CSS" style={vs2015} >
                {code}
            </SyntaxHighlighter>
        </div>
    );
};

export default Css;