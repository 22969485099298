import React, {useRef, useState} from 'react';
import cl from './ChangePassword.module.css'
import Error from "../../shared/UI/Error/Error";
import Button_lilac from "../../shared/UI/Button_lilac/Button_lilac";
import {CSSTransition} from "react-transition-group";
import Button_lilac_text from "../../shared/UI/Button_lilac_text/Button_lilac_text";
import useCollectErrors from "../../shared/utils/hooks/useCollectErrors/useCollectErrors";
import {changePassword} from "../../shared/api/userApi";
import PasswordInput from "../../entities/PasswordInput/PasswordInput";
import {useNavigate} from "react-router-dom";

const ChangePassword = ({user}) => {
    const navigate = useNavigate();
    const successfulChangeNode = useRef(null)
    const [showSaved, setShowSaved] = useState(false)
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [changePasswordIsVisible, setChangePasswordIsVisible] = useState(false)
    const [changePasswordCollected, errors] = useCollectErrors(() => changePassword( oldPassword, newPassword));

    const handleChangePassword = async (e) => {
        try {
            const response = await changePasswordCollected(oldPassword, newPassword)
            if (response) {
                setShowSaved(true)
                setTimeout(() => {
                    setShowSaved(false)
                    navigate("/login")
                }, 1500)
            }
        } catch (e) {
            console.log(e)
        }
    }
    const toggleChangePassword = (e) => {
        e.preventDefault();
        setChangePasswordIsVisible(!changePasswordIsVisible)
    }
    return (
        <div className={cl.wrap}>
            <div className={cl.openButtonWrap}>
                <Button_lilac_text onClick={(e) => toggleChangePassword(e)}>
                    Change password
                </Button_lilac_text>
            </div>
            {changePasswordIsVisible &&
            <div className={cl.changePasswordWrap}>
                <PasswordInput errors={errors} setPassword={setOldPassword} errorsType={"oldPassword"} placeholder={"Old password"}/>
                <PasswordInput errors={errors} setPassword={setNewPassword} errorsType={"newPassword"} placeholder={"New password"}/>

                <div className={cl.saveNewPasswordButtonWrap}>
                    <Button_lilac onClick={() => handleChangePassword()}>
                        Change
                    </Button_lilac>
                    <CSSTransition
                        nodeRef={successfulChangeNode}
                        in={showSaved}
                        timeout={200}
                        classNames={{
                            // enter: cl.savedEnter,
                            // enterActive: cl.savedEnterActive,
                            exit: cl.successfulChangeExit,
                            exitActive: cl.successfulChangeExitActive
                        }}
                        unmountOnExit
                    >
                        <span className={cl.successfulChange} ref={successfulChangeNode}>Saved</span>
                    </CSSTransition>
                </div>
            </div>}
        </div>
    );
};

export default ChangePassword;