import React, {useEffect, useRef, useState} from 'react';
import cl from './AccordionItem.module.css'

const AccordionItem = ({question, answer, initialActive = false}) => {
    const [isActive, setIsActive] = useState(initialActive)

    return (
        <article className={cl.wrap}>
            <button className={cl.questionWrap} onClick={() => setIsActive(!isActive)}>
                <h5 className={cl.question}>
                    {question}
                </h5>
                <div className={cl.iconWrap}>
                    <span className={cl.icon}>
                        {isActive ? '-' : '+'}
                    </span>
                </div>

            </button>
            <p className={cl.answer}
               style={isActive ? {maxHeight: '200px', transition: 'max-height 0.5s ease-in'} : {}}>
                {answer}
            </p>
        </article>
    );
};

export default AccordionItem;