import React, {useEffect, useRef, useState} from 'react';
import cl from './AutoSavedUserInput.module.css'
import {useDebounce} from "../../shared/utils/hooks/useDebounce/useDebounce";

import {CSSTransition} from "react-transition-group";

const AutoSavedUserInput = ({apiMethod, propertyName, user}) => {
    const [propertyValue, setProperty] = useState(user[propertyName])
    const debouncedProperty = useDebounce(propertyValue, 500)
    const [firstRender, setFirstRender] = useState(true)
    const savedHint = useRef(null)
    const [showSaved, setShowSaved] = useState(false)

    const handleChangeProperty = (e) => {
        e.preventDefault();
        setProperty(e.target.value)
    }

    useEffect(() => {
        if (firstRender) {
            setFirstRender(false)
        } else {
            apiMethod(propertyValue)
            setShowSaved(true)
            setTimeout(() => {
                setShowSaved(false)
            }, 500)
        }

    }, [debouncedProperty])
    return (
        <div className={cl.wrap}>
            <input placeholder={propertyValue} name={propertyName}
                   type="text" className={cl.property}
                   value={propertyValue} onChange={(e) => handleChangeProperty(e)}/>
            <CSSTransition
                nodeRef={savedHint}
                in={showSaved}
                timeout={200}
                classNames={{
                    // enter: cl.savedEnter,
                    // enterActive: cl.savedEnterActive,
                    exit: cl.savedExit,
                    exitActive: cl.savedExitActive
                }}
                unmountOnExit
            >
                <span className={cl.saved} ref={savedHint}>Saved</span>
            </CSSTransition>
        </div>
    );
};

export default AutoSavedUserInput;