import React from 'react';
import cl from './TitleNumbered.module.css'
import Sticks from "../../shared/UI/Sticks/Sticks";

const TitleNumbered = ({number, title, ...props}) => {
    return (
        <div {...props}>
            <span className={cl.number}>{number}</span>
            <h3 className={cl.title}>{title}</h3>
        </div>
    );
};

export default TitleNumbered;