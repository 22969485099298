import React, {useEffect, useRef} from 'react';
import cl from './Map.module.css'

const Map = () => {
    // Function to handle drag and move camera functionality
    const container = useRef(null)
    const elemsWrap = useRef(null)
    function handleDragAndMoveCamera() {
        let left = 0;
        let top = 0;
        let drag = false;
        let move = false;
        let coorX = 0;
        let coorY = 0;
        const speed = 1;

        container.current.addEventListener('mousedown', (e) => {
            drag = true;
            coorX = e.pageX - container.current.offsetLeft;
            coorY = e.pageY - container.current.offsetTop;
        });

        container.current.addEventListener('mouseup', () => {
            drag = false;
            move = false;
            left = container.current.scrollLeft;
            top = container.current.scrollTop;
        });

        container.current.addEventListener('mousemove', (e) => {
            if (drag) {
                move = true;
                container.current.scrollLeft = left - (e.pageX - container.current.offsetLeft - coorX) * speed;
                container.current.scrollTop = top - (e.pageY - container.current.offsetTop - coorY) * speed;
            }
        });
        container.current.addEventListener('mouseleave',(e)=>{
            drag = false;
            move = false;
            left = container.current.scrollLeft;
            top = container.current.scrollTop;
        })

        container.onmouseleave = () => {
            move = false;
            drag = false;
            left = container.scrollLeft;
            top = container.scrollTop;
        };
    }

// Function to handle zoom functionality
    function handleZoom() {
        let scaleValue = 10;
        function scrollUp() {
            if (scaleValue < 10) {
                scaleValue++;
                elemsWrap.current.style.transform = `scale(${scaleValue / 10})`;
            }
        }

        function scrollDown() {
            if (scaleValue > 6) {
                scaleValue--;
                elemsWrap.current.style.transform = `scale(${scaleValue / 10})`;
            }
        }

        // Scroll navigation
        if ('onwheel' in container) {
            container.current.addEventListener("wheel", onWheel, {passive: false});
        } else if ('onmousewheel' in document) {
            container.current.addEventListener("mousewheel", onWheel, {passive: false});
        } else {
            container.current.addEventListener("MozMousePixelScroll", onWheel, {passive: false});
        }
        container.current.addEventListener("resize", onWheel, {passive: false});

        container.current.addEventListener('gestureend', function(e) {
            if (e.scale < 1.0) {
                scrollDown();
            } else if (e.scale > 1.0) {
                scrollUp();
            }
        }, false);
        function onWheel(e) {
            e = e || window.event;
            const delta = e.deltaY || e.detail || e.wheelDelta;
            if (delta > 0) {
                scrollDown();
            } else {
                scrollUp();
            }
            e.preventDefault ? e.preventDefault() : (e.returnValue = false);
        }
    }

// Function to initialize the app
    function initApp() {
        handleDragAndMoveCamera();
        handleZoom();
    }

// Call the initApp function once the DOM is ready
   useEffect(()=>{
       initApp()
   },[])
    return (
        <div>
            <div ref={container} className={cl.container}>
                <div ref={elemsWrap} className={cl.elemsWrap}>
                    <div className={cl.introduction}>
                        <h3 className={cl.title}>
                            Map pet-project
                        </h3>
                        <ul className={cl.rules}>
                            <li>
                                <p>
                                    Hold and move your mouse to move the camera
                                </p>
                            </li>
                            <li>
                                <p>
                                    Scroll to change scale
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div className={cl.listWrap}>
                        <h4>
                            Street
                        </h4>
                        <ul>
                            <li style={{top: '-100px', left: '-100px'}}>
                                <p>
                                   1
                                </p>
                            </li>
                            <li style={{top: '-100px', right: '-100px'}}>
                                <p>
                                   4
                                </p>
                            </li>
                            <li style={{top: '40px', left: '50%'}}>
                                <p>
                                    5
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div className={cl.square}>School</div>
                </div>
            </div>
        </div>

    );
};

export default Map;