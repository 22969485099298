import React, {useEffect, useState} from 'react';
import cl from './UserProfile.module.css'
import UserDetails from "../../widgets/UserDetails/UserDetails";
import UserIcon from "../../shared/UI/icons/UserIcon";
import {useSelector} from "react-redux";


const UserProfile = () => {
    const userFromToken = useSelector(state => state.user.user)
    const [user, setUser] = useState(userFromToken.user)
    return (
        <div className={cl.wrap}>
            <UserDetails user={user}/>
            <div className={cl.userIconWrap}>
                <UserIcon/>
            </div>
        </div>
    );
};

export default UserProfile;