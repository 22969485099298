import React, {useEffect, useRef, useState} from 'react';
import cl from './HamburgerMenu.module.css'
import {ReactComponent as CurvesComponent} from '../../../shared/assets/CurvesItem.svg'


const HamburgerMenu = () => {
    const [isActive, setIsActive] = useState(false);
    const [removeAnimation, setRemoveAnimation] = useState(false)
    const handleClick = ()=>{
        setRemoveAnimation(true);
        setIsActive(!isActive)
    }
    return (
        <div className={cl.wrap}>
            <header>
                <button
                    className={cl.hamburgerWrap + (isActive ? ' ' + cl.active : '') + (removeAnimation ? ' ' + cl.removeAnimation : '')}
                    onClick={handleClick}>
                    <div></div>
                    <div></div>
                    <div></div>
                </button>
            </header>
            <div className={cl.curves}>
                <CurvesComponent/>
            </div>
            <nav className={(isActive ? cl.active : '') + (removeAnimation ? ' ' + cl.removeAnimation : '')}>
                <ul>
                    <li><p href="">Home</p></li>
                    <li><p href="">About</p></li>
                    <li><p href="">Contacts</p></li>
                </ul>
            </nav>
        </div>
    );
};

export default HamburgerMenu;