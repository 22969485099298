import React, {useRef, useState} from 'react';
import cl from './Project.module.css'

import {AnimatePresence, motion} from "framer-motion";
import TryProject from "../../../widgets/projectOptions/TryProject/TryProject";
import Html from "../../../widgets/projectOptions/HTML/Html";
import Css from "../../../widgets/projectOptions/CSS/Css";

import Guide from "../../../widgets/projectOptions/Guide/Guide";
import Js from "../../../widgets/projectOptions/JS/Js";
import {useMediaQuery} from "../../../shared/utils/hooks/useMediaQuery/useMediaQuery";
import {Link} from "react-router-dom";
import LeftArrow from "../../../shared/UI/icons/LeftArrow";


const Project = ({projectData}) => {
    const optionsMenuData = [
        {title: 'Try project', id: 'try-project', component: <TryProject data={projectData.demoData}/>},
        {title: 'HTML', id: 'html', component: <Html name={projectData.name} code={projectData.html.code}/>},
        {title: 'CSS', id: 'css', component: <Css name={projectData.name} code={projectData.css.code}/>},
        {
            title: 'JS',
            id: 'js',
            component: <Js code={projectData.js?.code}/>
        },
        {title: 'Guide', id: 'guide', component: <Guide data={projectData.guide}/>}];

    const isMobileVersion = useMediaQuery('(max-width: 992px)');
    const [currentOption, changeCurrentOption] = useState('try-project')

    return (<div className={cl.wrap}>
            {isMobileVersion && <Link to={'/pet-projects'} className={cl.returnButton}><LeftArrow/> Project list</Link>}
            <ul className={cl.listWrap}>
                {optionsMenuData.map((option) =>
                    <li key={option.id}>
                        <button onClick={() => changeCurrentOption(option.id)}
                                className={currentOption === option.id ? `${cl.active}` : ''}
                                key={option.id}>
                            {option.title}
                        </button>
                    </li>
                )}
            </ul>
            <AnimatePresence mode='wait'>
                {optionsMenuData.map((option) =>
                    currentOption === option.id && (
                        <motion.div key={option.id} initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}
                                    transition={{duration: 0.2}} className={cl.optionWrap}>
                            {option.component}
                        </motion.div>)
                )}
            </AnimatePresence>
        </div>
    );
};

export default Project;