import React, {useState} from 'react';
import cl from './Clicker.module.css'
import Button_lilac from "../../../shared/UI/Button_lilac/Button_lilac";

const Clicker = () => {
    const [clicks, setClicks]= useState(0);
    return (
        <div className={cl.wrap}>
            <header></header>
            <h3 className={cl.score}>Score : {clicks}</h3>
            <Button_lilac onClick={()=>setClicks(clicks+1)}>Click me</Button_lilac>
        </div>
    );
};

export default Clicker;