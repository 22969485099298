import React from 'react';
import cl from './AboutCompany.module.css'
import Logo from "../../../shared/UI/Logo/Logo";
import Title from "../../../shared/UI/Title/Title";
import backgroundImg from "../../../shared/assets/Background-2.png"

const AboutCompany = () => {
    return (
        <div className={cl.wrap} id="about" style={{backgroundImage: `url(${backgroundImg})`}}>
            <div className={cl.content}>
                <div className={cl.logoWrap}>
                    <Logo/>
                </div>
                <h3 className={cl.title + " text-3"}>
                    Unleash Your Coding Potential <br/>
                    with Pet-Projects
                </h3>
                <ul className={cl.aboutCompanyQuestions}>
                    <li>
                        <div className={cl.titleWrap}>
                            <Title data={{title: "What is Wripet about? "}}/>
                        </div>
                        <p className={cl.answer}>Wripet is an <b>academy</b> where anyone can enter the exciting world of
                            IT. Join us on this journey as we make <b>coding education </b>fun, practical, and achievable for
                            all skill levels</p>
                    </li>
                    <li>
                        <div className={cl.titleWrap}>
                            <Title data={{title: "What is our mission? "}}/>
                        </div>
                        <p className={cl.answer}>Our mission is to provide a unique learning experience through small
                            <b> pet-projects</b>,
                            allowing individuals to build their coding skills and ignite their passion for
                            technology</p>
                    </li>
                    <li>
                        <div className={cl.titleWrap}>
                            <Title data={{title: "What is our vision? "}}/>
                        </div>
                        <p className={cl.answer}>With a vision of accessibility and empowerment, we aim to <b>revolutionize </b>
                            the way people learn and enter the <b>IT industry</b>  </p>
                    </li>
                </ul>

            </div>
        </div>
    );
};

export default AboutCompany;