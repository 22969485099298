import React from 'react';
import cl from './Error.module.css'

const Error = ({errors, type,...props}) => {
    const isShown = errors?.find(error => error.place === type)?.message;
    if(!isShown){
        return (<div style={{display:'none'}}> </div>)
    }
    return (
    <p {...props} className={cl.wrap}>
        {errors.find(error => error.place === type)?.message}
    </p>
    );
};

export default Error;