import React, {useState} from 'react';
import cl from './ThemeToggler.module.css'

const ThemeToggler = () => {
    const [isActive, setIsActive] = useState(false);

    return (
        <div className={cl.wrap}>
            <header></header>
            <div className={cl.content+ (isActive ? ' ' + cl.contentActive : '')}>
                <h5 className={cl.text}>Theme Toggler</h5>
            </div>
            <button className={cl.button + (isActive ? ' ' + cl.btnActive : '')} onClick={()=>setIsActive(!isActive)}></button>
        </div>
    );
};

export default ThemeToggler;