import React from 'react';
import cl from './OfferContent.module.css'
import Button_lilac from "../../../shared/UI/Button_lilac/Button_lilac";
import Sticks from "../../../shared/UI/Sticks/Sticks";
import HamburgerSmallIcon from "../../../shared/UI/siteSmallIcons/HamburgerSmallIcon/HamburgerSmallIcon";
import LoaderSmallIcon from "../../../shared/UI/siteSmallIcons/LoaderSmallIcon/LoaderSmallIcon";
import BackgroundChangerSmallIcon
    from "../../../shared/UI/siteSmallIcons/BackgroundChangerSmallIcon/BackgroundChangerSmallIcon";
import {BrowserRouter as Router, Link} from "react-router-dom";
import BallSmallIcon from "../../../shared/UI/siteSmallIcons/BallSmallIcon/BallSmallIcon";
import ThemeTogglerSmallIcon from "../../../shared/UI/siteSmallIcons/ThemeTogglerSmallIcon/ThemeTogglerSmallIcon";
import ToastNotificationsSmallIcon
    from "../../../shared/UI/siteSmallIcons/ToastNotificationSmallIcon/ToastNotificationSmallIcon";
import {useMediaQuery} from "../../../shared/utils/hooks/useMediaQuery/useMediaQuery";
import ScrollToTop from "../../../shared/utils/ScrollToTop";


const OfferContent = () => {
    const isMobileVersion = useMediaQuery('(max-width: 992px)');

    return (
        <div className={cl.wrap}>
            <div className={cl.offer}>
                <div className={cl.sticksWrap}>
                    <Sticks styles={{
                        width: '4px', leftHeight: '32vh', rightHeight: '40vh', leftStickTop: '0', rightStickTop: '80px',
                        distance: '30px', wrapTop: '21%',
                        wrapLeft: '15%'
                    }}/>
                    <Sticks styles={{
                        width: '4px', leftHeight: '40vh', rightHeight: '32vh', leftStickTop: '80px', rightStickTop: '0',
                        distance: '30px', wrapTop: '21%',
                        wrapLeft: '82%'
                    }}/>
                </div>

                <div className={cl.text}>
                    <h2 className={cl.title}>
                        Start learning IT from projects
                    </h2>
                    <p className={cl.subtitle}>
                        View 20+ interactive projects below
                    </p>

                    <Link to={isMobileVersion?'/pet-projects':'/pet-projects/hamburger-menu'}>
                        <Button_lilac style={{padding: '20px 30px'}}>All projects</Button_lilac>
                    </Link>

                </div>
            </div>
            <div className={cl.sites}>
                <ul className={cl.sitesList}>
                    <li className={cl.siteFrame}>
                        <HamburgerSmallIcon/>
                        <p className={cl.siteName}>Hamburger menu</p>
                    </li>

                    <li className={cl.siteFrame}>
                        <BallSmallIcon/>
                        <p className={cl.siteName}>Bouncing ball</p>
                    </li>
                    <li className={cl.siteFrame}>
                        <LoaderSmallIcon/>
                        <p className={cl.siteName}>Loader</p>
                    </li>
                    <li className={cl.siteFrame}>
                        <ThemeTogglerSmallIcon/>
                        <p className={cl.siteName}>Theme toggler</p>
                    </li>
                    <li className={cl.siteFrame}>
                        <BackgroundChangerSmallIcon/>
                        <p className={cl.siteName}>Background changer</p>
                    </li>
                    <li className={cl.siteFrame}>
                        <ToastNotificationsSmallIcon/>
                        <p className={cl.siteName}>Toast notifications </p>
                    </li>

                </ul>
            </div>
        </div>
    );
};

export default OfferContent;