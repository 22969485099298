import {$authHost, $host} from "./index";

export const createOrder = async (totalPrice) => {
    const {data} = await $authHost.post("/createOrder", {
        product: {
            description: "A pack of projects from the Wripet online academy",
            cost: totalPrice
        }
    })
    return data;
}

export const captureOrder = async (data) => {
    const res = await $authHost.post("/captureOrder", {
        orderID: data.orderID,
    })
    return res;
}
