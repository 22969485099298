import React, {useState} from 'react';
import cl from './Counter.module.css'
import Button_lilac from "../../../shared/UI/Button_lilac/Button_lilac";

const Counter = () => {
    const [clicks, setClicks]= useState(0);
    return (
        <div className={cl.wrap}>
            <header></header>
            <h3 className={cl.title}>Counter</h3>
            <div style={{display:'flex'}}>
                <Button_lilac onClick={()=>setClicks(clicks-1)}>-</Button_lilac>
                <span className={cl.score}>{clicks} </span>
                <Button_lilac onClick={()=>setClicks(clicks+1)}>+</Button_lilac>
            </div>

        </div>
    );
};

export default Counter;