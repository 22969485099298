import React, {useState} from 'react';
import cl from './AccordionMenu.module.css'
import AccordionItem from "./AccordionItem/AccordionItem";

const AccordionMenu = () => {

    return (
        <div className={cl.wrap}>
            <div >
                <header>
                </header>
                <AccordionItem question={'How to make accordion menu?'}
                               answer={'Follow the information in the Guide tab to complete According Menu project'}/>
                <AccordionItem
                    question={'Is it used in real projects?'}
                    answer={'Yes, it is commonly used in real-world projects, particularly in the field of UI design'}
                initialActive={true}/>
                <AccordionItem
                    question={'Should I add it to my portfolio?'}
                    answer={'Absolutely! It will surely catch the recruiter\'s eye'}/>
            </div>

        </div>
    );
};

export default AccordionMenu;