import React from 'react';
import cl from './TitleWithComment.module.css'

const TitleWithComment = ({data}) => {
    return (
        <div className={cl.wrap}>
            <h3 className={cl.title}>{data.title}</h3>
            <div className={cl.comment}>{data.comment}</div>
        </div>
    );
};

export default TitleWithComment;