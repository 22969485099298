import React, {useRef} from 'react';
import cl from './Toast.module.css'

const Toast = () => {
    const container = useRef(null);

    const createNotification = () => {
        const notif = document.createElement('div');
        notif.classList.add(`${cl.toast}`);
        notif.innerText = '+1';
        container.current.appendChild(notif);
        setTimeout(() => {
            notif.remove();
        }, 3000);
    };
    return (
        <div className={cl.wrap}>
            <header></header>
            <button id="btn" onClick={createNotification}>
                Create toast
            </button>
            <div ref={container} className={cl.toastContainer}> </div>
        </div>
    );
};

export default Toast;