import React, {useEffect, useRef} from 'react';
import cl from './SmoothScroll.module.css'

const SmoothScroll = () => {
    const scrollLinks = document.querySelectorAll(`${cl.scrollLink}`);
    const navbar = useRef(null);
    const containerNode = useRef(null)
    const wrapNode = useRef(null)

    const handleClick = (e)=>{
        e.preventDefault();
        // navigate to specific spot
        const id = e.currentTarget.getAttribute("href").slice(1);
        const element = document.getElementById(id);

        const computedStyle = getComputedStyle( wrapNode.current.parentNode);
        const scaleY = parseFloat(computedStyle.transform.split(',')[3]); // Extract scaleY from transform matrix

        const navHeight = navbar.current.getBoundingClientRect().height/scaleY;
        let position = element.offsetTop - navHeight -2;


        containerNode.current.scrollTo({
            left: 0,
            top: position,
            behavior:'smooth'
        });
    }

    return (
        <div className={cl.wrap} ref={wrapNode}>
            <div className={cl.container} ref={containerNode}>
                <header>
                    <nav ref={navbar}>
                        <ul className={cl.navList}>
                            <li>
                                <a href="#smoothScrollHome" className={cl.scrollLink} onClick={handleClick}>
                                    Home
                                </a>
                            </li>
                            <li>
                                <a href="#smoothScrollAbout" className={cl.scrollLink} onClick={handleClick}>
                                    About
                                </a>
                            </li>
                            <li>
                                <a href="#smoothScrollContacts" className={cl.scrollLink} onClick={handleClick}>
                                    Contacts
                                </a>
                            </li>
                        </ul>
                    </nav>
                </header>
                <section id="smoothScrollHome">
                    Home
                </section>
                <section id="smoothScrollAbout">
                    About
                </section>
                <section id="smoothScrollContacts">
                    Contacts
                </section>
            </div>
        </div>
    );
};

export default SmoothScroll;