import React, {useState} from 'react';
import cl from './LoginPopup.module.css'
import Button_lilac from "../../shared/UI/Button_lilac/Button_lilac";
import {resendActivationLink, useLogin} from "../../shared/api/userApi";
import Error from "../../shared/UI/Error/Error";
import {useNavigate} from "react-router-dom";
import useCollectErrors from "../../shared/utils/hooks/useCollectErrors/useCollectErrors";
import Timer from "../Timer/Timer";

const LoginPopup = ({email, password}) => {
    /*TODO organize in one func (Also in login)*/
    const login = useLogin();
    const [loginWithCollectedErrors, errors] = useCollectErrors(() => login(email, password));
    const navigate = useNavigate();

    const handleLoginSubmit = async (e) => {
        e.preventDefault();
        const response = await loginWithCollectedErrors();
        if (response) navigate('/me')
    };

    const [startTimer, setStartTimer ] = useState(false)
    const handleResend = async () => {
        try {
            await resendActivationLink(email, password)
            setStartTimer(true)
        } catch (e) {
            console.log(e)
        }
    }
    return (
        <div className={cl.wrap}>
            <div className={cl.popupWrap}>
                <h4 className={cl.title}>Confirm your email</h4>
                <Button_lilac onClick={(e) => handleResend()} type='button'>Resend</Button_lilac>
                <span
                    className={cl.timerWrap}><Timer setStartTimer={setStartTimer} start={startTimer} secondsNumber={60}/></span>
                <Button_lilac onClick={async (e) => handleLoginSubmit(e)} type="submit"
                              style={{marginBottom: '24px'}}>Log in</Button_lilac>
                {errors.length !== 0 &&
                <Error>{errors.find(error => error.place === 'emailActivation')?.message}</Error>}
            </div>
        </div>
    );

}
export default LoginPopup;