import React, {useEffect, useState} from 'react';
import cl from './Timer.module.css'

const Timer = ({setStartTimer, start, secondsNumber,}) => {
    const [seconds, setSeconds] = useState(secondsNumber);
    const [block, setBlock] = useState()
    let interval;

    useEffect(() => {
        if (!block)
            if (start) {
                setBlock(true)
                setSeconds(secondsNumber)
                interval = setInterval(() => setSeconds((seconds) => seconds - 1), 1000);
            }

        return () => clearInterval(interval);

    }, [start])

    useEffect(() => {
        if (seconds === 0) {
            clearInterval(interval)
            setBlock(false)
            setStartTimer(false)
        }
        return () => clearInterval(interval);
    }, [seconds]);

    return (
        <span className={cl.wrap}>
            {seconds}
        </span>
    );
};

export default Timer;