import React from 'react';
import cl from './Button_lilac_outline.module.css'

const ButtonLilacOutline = ({children, ...props}) => {
    return (
        <button className={cl.button} {...props}>
            {children}
        </button>
    );
};

export default ButtonLilacOutline;