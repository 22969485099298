import React, {useEffect, useState} from 'react';

import './App.css'

import {BrowserRouter as Router, Navigate, Route, Routes, useLocation} from 'react-router-dom'
import Header from "../widgets/Header/Header";
import AppRouter from "./router/AppRouter";
import Footer from "../widgets/Footer/Footer";
import {setIsAuth, setUser} from "../shared/store/userReducer";
import {useDispatch, useSelector} from "react-redux";
import {checkAuth} from "../shared/api/userApi";
import Loader from "../shared/UI/Loader/Loader";
import ScrollToTop from "../shared/utils/ScrollToTop";
import TechWorksAlert from "../pages/TechWorksAlert/TechWorksAlert";


function App() {
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch();

    useEffect(() => {

        const check = async () => {

            /*rename the function and move it out*/
            try {
                const res = await checkAuth();
                dispatch(setIsAuth(true));
                dispatch(setUser(res));
                setLoading(false)
            } catch (e) {
                setLoading(false)
            }
        }
        if (localStorage.getItem('token'))
            check()
        else
            setLoading(false)


    }, [])

    if (loading) {
        return <Loader/>
    }
    return (
        <Router className="App">
            <ScrollToTop/>
            <Header/>
            {/*<Routes>
                <Route path="*" element={<TechWorksAlert/>}/>
            </Routes>*/}
            {<AppRouter/>}
            <Footer/>
        </Router>
    );
}

export default App;
