import React from 'react';
import cl from './Footer.module.css'
import {Link} from "react-router-dom";

const Footer = () => {
    return (
        <div className={cl.wrap}>
           <p className={cl.agreements}>
               <Link to="/terms-of-use">
                   Terms of use
               </Link>
               <Link to="/privacy-policy">
                   Privacy Policy
               </Link>


           </p>
            <p className={cl.contacts}>
                <a href="mailto:wripet@wripet.com">
                    wripet@wripet.com
                </a>
            </p>
        </div>
    );
};

export default Footer;