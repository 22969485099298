import React, {useEffect, useState} from 'react';
import cl from './PaypalPopup.module.css'
import {Link} from "react-router-dom";
import PaypalGate from "./PaypalGate/PaypalGate";
import {useSelector} from "react-redux";

const PaypalPopup = ({showPaymentGate, setShowPaymentGate}) => {
    const user = useSelector(state => state.user.user.user);

    const [months, setMonths] = useState(1);
    const [totalPrice, setTotalPrice] = useState(19.9);
    useEffect(() => {
        setTotalPrice((months * 19.90).toFixed(1))
    }, [months])

    return (
        <div style={showPaymentGate ? {opacity: 1, pointerEvents: 'auto'} : {
            opacity: 0,
            pointerEvents: 'none'
        }} className={cl.paypalWrap} onClick={() => setShowPaymentGate(!showPaymentGate)}>
            <div className={cl.paypalPopup} onClick={(e) => e.stopPropagation()}>
                <div>
                    <h3 className={cl.paypalTitle}>Access to <br/>20 projects + courses</h3>
                    <h3 className={cl.paypalPrice}><span>39.90$</span> 19.90$</h3>
                    <h5 className={cl.paypalPeriod}> per month</h5>
                    <div className={cl.paypalMonthsCounter}>
                        <div>
                            <div>
                                <button className={cl.paypalMonthsCounter__minus} onClick={() => {
                                    if (months > 1) setMonths(months - 1)
                                }}>-
                                </button>
                                <span className={cl.paypalMonthsCounter__months}>{months}</span>
                                <button className={cl.paypalMonthsCounter__plus}
                                        onClick={() => setMonths(months + 1)}>+
                                </button>
                            </div>

                            <h5 className={cl.paypalMonthsText}> months</h5>
                        </div>
                        <div>
                            <span className={cl.paypalMonthsCounter__months}>{totalPrice}$</span>
                            <h5 className={cl.paypalMonthsText}> total</h5>
                        </div>
                    </div>
                    <h5 className={cl.paypalBuyerAccount}> Your logged in as<br/> <span>{user?.email}</span></h5>
                    <h6 className={cl.paypalLicenseAgreement}>By signing up, you agree to our <Link
                        to={'/terms-of-use'} target="_blank">Terms of Use</Link> and <Link
                        to={'/privacy-policy'} target="_blank">Privacy Policy</Link></h6>
                    <button className={cl.paypalExit} onClick={() => setShowPaymentGate(!showPaymentGate)}>x
                    </button>
                </div>

                <PaypalGate setShowPaymentGate={setShowPaymentGate} months={months} totalPrice={totalPrice}/>
            </div>
        </div>
    );
};

export default PaypalPopup;