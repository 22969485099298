import React, {useEffect, useState} from 'react';
import cl from './TextTyper.module.css'

const TextTyper = () => {
    const text = 'You are the best!';
    const [index, setIndex] = useState(0);
    const [intervalId, setIntervalId] = useState()
    let intervalIdVar;
    useEffect(() => {
        setIntervalId(setInterval(() => setIndex((index) => index + 1), 110));
        return ()=>clearInterval(intervalIdVar);
    }, [])
    useEffect(() => {
        if (index > text.length) {

            clearInterval(intervalId);
            setTimeout(async () => {
                await setIndex(0)
                setIntervalId(setInterval(() => setIndex((index) => index + 1), 110))
            }, 1000);
        }

    }, [index])
    useEffect(()=>{
        intervalIdVar = intervalId;
        return ()=>clearInterval(intervalIdVar);

    },[intervalId])

    return (
        <div className={cl.wrap}>
            <header></header>
            <h4>
                {text.slice(0, index)}
            </h4>
        </div>
    );
};

export default TextTyper;