export const accentColor = "var(--lilac)"
export const darkPrimaryColor = "var(--dark-primary-color)"
export const lightPrimaryColor = "var(--light-primary-color)"

export const bold = "var(--bold)"
export const semiBold = "var(--semiBold)"
export const regular = "var(--regular)"
export const light = "var(--light)"

export const codePalette = {
    yellow: '#ddc664',
    purple: '#756ca9',
    orange: '#d27125',
    green: '#66a05e',
    white: '#fff',
    blue: '#63c2e1',
    red: 'red',
    grey: '#8b8b8b'
}

export const text6 = "var(--text6)"
export const text5 = "var(--text5)"
export const text4 = "var(--text4)"
export const text3 = "var(--text3)"
export const text2 = "var(--text2)"
export const text1 = "var(--text1)"
