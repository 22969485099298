import React, {useEffect, useMemo, useRef, useState} from 'react';
import './Code.css'
import Command from "./Command/Command";
import {typesOfCommand as types} from "./CommandsData";
import {useHover} from "../../shared/utils/hooks/useHover/useHover";

const Code = ({code, styles = {}, isProjectListItem}) => {
    const finalCode = useMemo(()=>rebuildCode(), [code])

    function getCommandPushTemplate(commandName, type) {
        return <Command commandName={commandName} dataOfType={type}/>;
    }

    function rebuildCode (){
        let acornLoose = require("acorn-loose");
        if (code) {
            let index = 0;
            const ast = acornLoose.parse(code, {ecmaVersion: 2020});
            const elements = [];

            iterateNode(ast.body, true, [], [], [])

            /*TODO Make not just copy the line before node.start and index but
               special function to process what we skipped (for comments at least) */

            function iterateNode(inheritor, newScope = false, outerVariables = [], functions = [], params = []) {
                //To print all symbols
                const nodeArray = Array.isArray(inheritor) ? inheritor : [inheritor];
//TODO write so that it finishes the rest of the string after code word,  not part before the command word.
                //Scope
                const localVariables = [];
                if (newScope) {
                    functions.push(...collectFunctions(inheritor))
                }
                for (const node of nodeArray) {
                    //other symbols
                    if (node.start > index) {
                        elements.push(<span>{code.substr(index, node.start - index)}</span>)
                        index = node.start;
                    }

                    if (node.type === 'Literal') {
                        let literalType = typeof node.value;
                        if (node.value === null) {
                            literalType = 'null'
                        }
                        elements.push(getCommandPushTemplate(node.raw, types[literalType]));
                        index += node.raw.length;

                    }
                    if (node.type === 'Identifier') {
                        let identifierType;
                        if (localVariables.includes(node.name) || outerVariables.includes(node.name)) {
                            identifierType = types.variable
                        } else {
                            if (functions.includes(node.name)) {
                                identifierType = types.func
                            } else {
                                if (params.includes(node.name)) {
                                    identifierType = types.params
                                } else
                                    identifierType = types.command
                            }
                        }
                        elements.push(getCommandPushTemplate(node.name, identifierType));
                        index += node.name.length;
                    }

                    if (node.type === 'VariableDeclaration') {
                        elements.push(getCommandPushTemplate(node.kind, types.command));

                        localVariables.push(node.declarations[0].id.name)

                        index += node.kind.length;
                        iterateNode(node.declarations, false, [...outerVariables, ...localVariables], functions, params)
                    }
                    if (node.type === 'VariableDeclarator') {
                        elements.push(getCommandPushTemplate(node.id.name, types.variable));
                        index += node.id.name.length;
                        if (node.init) {
                            iterateNode(node.init, false, [...outerVariables, ...localVariables], functions, params)
                        }
                    }
                    if (node.type === 'CallExpression') {
                        iterateNode(node.callee, false, [...outerVariables, ...localVariables], functions, params)
                        iterateNode(node.arguments, false, [...outerVariables, ...localVariables], functions, params)
                    }
                    if (node.type === 'MemberExpression') {
                        iterateNode(node.object, false, [...outerVariables, ...localVariables], functions, params)
                        iterateNode(node.property, false, [...outerVariables, ...localVariables], functions, params)
                    }
                    if (node.type === 'BlockStatement') {
                        iterateNode(node.body, true, [...outerVariables, ...localVariables], functions, params)
                    }
                    if (node.type === 'ExpressionStatement') {
                        iterateNode(node.expression, false, [...outerVariables, ...localVariables], functions, params)

                    }
                    if (node.type === 'ArrowFunctionExpression') {
                        //handle params
                        const paramsNodeArray = node.params;
                        paramsNodeArray.forEach((node) => {
                            params.push(node.name)
                        })
                        iterateNode(node.body, false, [...outerVariables, ...localVariables], functions, params)
                    }
                    if (node.type === 'FunctionExpression') {
                        //handle params
                        const paramsNodeArray = node.params;
                        paramsNodeArray.forEach((node) => {
                            params.push(node.name)
                        })
                        elements.push(getCommandPushTemplate('function', types.command));
                        index += 8;
                        iterateNode(node.body, false, [...outerVariables, ...localVariables], functions, params)
                    }
                    if (node.type === 'IfStatement') {
                        elements.push(getCommandPushTemplate('if', types.command));
                        index += 2;
                        iterateNode(node.test, false, [...outerVariables, ...localVariables], functions, params)
                        iterateNode(node.consequent, false, [...outerVariables, ...localVariables], functions, params)
                        if (node?.alternate) {
                            elements.push(<span>{code.substr(index, node.consequent.end - index + 1)}</span>)
                            elements.push(getCommandPushTemplate('else', types.command));

                            index = node.consequent.end + 5;
                            iterateNode(node.alternate, false, [...outerVariables, ...localVariables], functions, params)
                        }
                    }

                    if (node.type === 'BinaryExpression') {
                        iterateNode(node.left, false, [...outerVariables, ...localVariables], functions, params)
                        iterateNode(node.right, false, [...outerVariables, ...localVariables], functions, params)
                    }
                    if (node.type === 'LogicalExpression') {
                        iterateNode(node.left, false, [...outerVariables, ...localVariables], functions, params)
                        iterateNode(node.right, false, [...outerVariables, ...localVariables], functions, params)
                    }
                    if (node.type === 'AssignmentExpression') {
                        iterateNode(node.left, false, [...outerVariables, ...localVariables], functions, params)
                        iterateNode(node.right, false, [...outerVariables, ...localVariables], functions, params)
                    }
                    if (node.type === 'FunctionDeclaration') {
                        const paramsNodeArray = node.params;
                        paramsNodeArray.forEach((node) => {
                            params.push(node.name)
                        })
                        elements.push(getCommandPushTemplate('function', types.command));
                        index += 8;
                        iterateNode(node.id, false, [...outerVariables, ...localVariables], functions, params)
                        iterateNode(node.body, false, [...outerVariables, ...localVariables], functions, params)
                    }
                    if (node.type === 'ReturnStatement') {
                        elements.push(getCommandPushTemplate('return', types.command));
                        index += 6;
                        if (node.argument)
                            iterateNode(node.argument, false, [...outerVariables, ...localVariables], functions, params)
                    }
                    if (node.type === 'TemplateLiteral') {
                        const stringValue = code.substr(node.start, node.end - node.start);
                        elements.push(getCommandPushTemplate(stringValue, types.templateLiteral));
                        index += stringValue.length;
                    }
                    if (node.type === 'UpdateExpression') {
                        iterateNode(node.argument, false, [...outerVariables, ...localVariables], functions, params)

                    }
                    if (node.type === 'ArrayExpression') {
                        const stringValue = code.substr(node.start, node.end - node.start);
                        elements.push(getCommandPushTemplate(stringValue, types.array));

                        index += stringValue.length;
                    }
                    if (node.type === 'ObjectExpression') {
                        // const stringValue = code.substr(node.start, node.end - node.start);
                        // elements.push(getCommandPushTemplate(stringValue, types.object));
                        // index += stringValue.length;
                        iterateNode(node.properties, false, [...outerVariables, ...localVariables], functions, params)
                    }
                    if (node.type === 'ConditionalExpression') {
                        const stringValue = code.substr(node.test.start, node.test.end - node.test.start);
                        index += node.test.end - node.test.start;
                        elements.push(getCommandPushTemplate(stringValue, types.conditionalExpressionTest));
                        iterateNode(node.consequent, false, [...outerVariables, ...localVariables], functions, params)
                        iterateNode(node.alternate, false, [...outerVariables, ...localVariables], functions, params)
                    }
                    if (node.type === 'Property') {
                        const stringValue = code.substr(node.start, node.end - node.start);
                        index += node.end - node.start;
                        elements.push(getCommandPushTemplate(stringValue, types.property));
                    }
                    if (node.type === 'NewExpression') {
                        const stringValue = code.substr(node.start, node.end - node.start);
                        index += node.end - node.start;
                        elements.push(getCommandPushTemplate(stringValue, types.newExpression));
                    }
                }
            }

            elements.push(<span>{code.substr(index, code.length)}</span>)
            return elements;

            function collectFunctions(inheritor) {
                const nodeArray = Array.isArray(inheritor) ? inheritor : [inheritor];
                const functions = [];
                for (const node of nodeArray) {
                    if (node.type === 'FunctionDeclaration') {
                        functions.push(node.id.name)
                        // node.params.forEach((i) => console.log(i.name))
                    }
                }
                return functions
            }
        }
    }


    return (
        <div style={{position: 'relative'}} id='jsCodee'>
            <div className={"jsCodeWrap" + (isProjectListItem ? ' jsCodeWrapProject' : '')} id='jsCodeWrap'>
                {finalCode}
            </div>
        </div>

    );

}
export default Code;

