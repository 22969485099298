import React from 'react';
import cl from './Button_lilac.module.css'

const Button_lilac = ({ children, ...props}) => {
    return (
        <button {...props} className={cl.button}>
            {children}
        </button>
    );
};

export default Button_lilac;