import React, {useState} from 'react';
import {logout, sendNewPassword} from "../../shared/api/userApi";
import Button_lilac from "../../shared/UI/Button_lilac/Button_lilac";
import cl from "./RecoverPassword.module.css";
import Button from "../../shared/UI/Button/Button";
import Button_lilac_outline from "../../shared/UI/Button_lilac_outline/Button_lilac_outline";
import Button_lilac_text from "../../shared/UI/Button_lilac_text/Button_lilac_text";
import {useNavigate} from "react-router-dom";

const RecoverPassword = ({email, propsFirstButton, propsSecondButton, redirectToLoginThen}) => {
    const navigate = useNavigate();
    const [showRecoverPassword, setShowRecoverPassword] = useState(false)

    const showRecoverButton = (e) => {
        e.preventDefault()
        setShowRecoverPassword(!showRecoverPassword)
    }
    const handleSendNewPassword = async (e) => {
        try {
            e.preventDefault()
            await sendNewPassword(email)
            await logout()
            if(redirectToLoginThen) navigate("/login")

        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className={cl.wrap}>
            <Button_lilac_text onClick={(e) => showRecoverButton(e)} {...propsFirstButton}>
                I forgot my password
            </Button_lilac_text>
            {showRecoverPassword &&
            <div className={cl.sendNewPasswordButtonWrap}>
                <Button_lilac onClick={(e) => handleSendNewPassword(e)} {...propsSecondButton}>
                    Send a new password on my email
                </Button_lilac>
            </div>
            }
        </div>
    );
};

export default RecoverPassword;