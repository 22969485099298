import Home from "../../pages/Home/Home"
import NotFound from "../../pages/NotFound/NotFound"

import Login from "../../pages/Login/Login";
import Signup from "../../pages/Signup/Signup";
import UserProfile from "../../pages/UserProfile/UserProfile";

import OfferPayment from "../../pages/OfferPayment/OfferPayment";
import HtmlGuide from "../../pages/Guides/HtmlGuide/HtmlGuide";
import Project from "../../pages/PetProjects/Project/Project";
import React from "react";
import {
    AccordionMenuData,
    backgroundChangerData,
    ClickerData,
    CounterData,
    DragAndDropData,
    EventCountdownData,
    FallingObjectsData,
    hamburgerMenuData,
    HoverData,
    loaderProjectData,
    MapData,
    PaintData,
    PopupData,
    ScrollingProgressData,
    SliderData,
    SmoothScrollData,
    TabsData,
    TextTyperData, ThemeTogglerData, ToastData, TodoListData, VideoData
} from "../../pages/PetProjects/ProjectsData";
import CssGuide from "../../pages/Guides/CssGuide/CssGuide";
import JsGuide from "../../pages/Guides/JsGuide/JsGuide";
import IntroGuide from "../../pages/Guides/IntroGuide/IntroGuide";
import TechWorks from "../../pages/TechWorksAlert/TechWorksAlert";
import PrivacyPolicy from "../../pages/PrivacyPolicy/PrivacyPolicy";
import TermsOfUse from "../../pages/TermsOfUse/TermsOfUse";


export const publicRoutes = [
    {path: '/', component: Home, exact: true},
    {path: '/home', component: Home, exact: true},
    {path: '/not-found', component: NotFound, exact: true},
    {path: '/login', component: Login, exact: true},
    {path: '/signup', component: Signup, exact: true},
    {path: '/offer', component: OfferPayment, exact: true},
    {path: '/html-guide', component: HtmlGuide, exact: true},
    {path: '/css-guide', component: CssGuide, exact: true},
    {path: '/js-guide', component: JsGuide, exact: true},
    {path: '/privacy-policy', component:PrivacyPolicy , exact: true},
    {path: '/terms-of-use', component:TermsOfUse , exact: true},
]
export const privateRoutes = [
    {path: '/me', component: UserProfile, exact: true},
]
export const paidRoutes = [
    {path: '/introduction', component: IntroGuide, exact: true},
]
export const freeProjectRoutes = [
    {path: '/hamburger-menu', component: () => <Project projectData={hamburgerMenuData}/>, exact: false},
    {path: '/background-changer', component: () => <Project projectData={backgroundChangerData}/>, exact: false},
    {path: '/loader', component: () => <Project projectData={loaderProjectData}/>, exact: false},
]
export const paidProjectRoutes = [
    {path: '/accordion-menu', component: () => <Project projectData={AccordionMenuData}/> , exact: true},
    {path: '/clicker', component: () => <Project projectData={ClickerData}/> , exact: true},
    {path: '/counter', component: () => <Project projectData={CounterData}/> , exact: true},
    {path: '/drag-and-drop', component: () => <Project projectData={DragAndDropData}/> , exact: true},
    {path: '/event-countdown', component: () => <Project projectData={EventCountdownData}/> , exact: true},
    {path: '/falling-objects', component: () => <Project projectData={FallingObjectsData}/> , exact: true},
    {path: '/hover', component: () => <Project projectData={HoverData}/> , exact: true},
    {path: '/map', component: () => <Project projectData={MapData}/> , exact: true},
    // {path: '/paint', component: () => <Project projectData={PaintData}/> , exact: true},
    {path: '/popup', component: () => <Project projectData={PopupData}/> , exact: true},
    {path: '/scrolling-progress', component: () => <Project projectData={ScrollingProgressData}/> , exact: true},
    {path: '/slider', component: () => <Project projectData={SliderData}/> , exact: true},
    {path: '/smooth-scroll', component: () => <Project projectData={SmoothScrollData}/> , exact: true},
    {path: '/tabs', component: () => <Project projectData={TabsData}/> , exact: true},
    {path: '/text-typer', component: () => <Project projectData={TextTyperData}/> , exact: true},
    {path: '/theme-toggler', component: () => <Project projectData={ThemeTogglerData}/> , exact: true},
    {path: '/toast', component: () => <Project projectData={ToastData}/> , exact: true},
    {path: '/todo-list', component: () => <Project projectData={TodoListData}/> , exact: true},
    {path: '/video', component: () => <Project projectData={VideoData}/> , exact: true},
]