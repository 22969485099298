import React from 'react';
import cl from './Tab.module.css';

const Tab = ({title, text}) => {
    return (
        <div className={cl.wrap}>
            <h3 className={cl.title}>{title}</h3>
            <p className={cl.text}>{text}</p>
        </div>
    );
};

export default Tab;