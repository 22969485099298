import React from 'react';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom'
import {freeProjectRoutes, privateRoutes, publicRoutes, paidProjectRoutes, paidRoutes} from "./index";
import {useSelector} from "react-redux";
import {AnimatePresence} from "framer-motion";
import {motion} from "framer-motion"
import PetProjects from "../../pages/PetProjects/PetProjects";
import Loader from "../../shared/UI/Loader/Loader";
import {useMediaQuery} from "../../shared/utils/hooks/useMediaQuery/useMediaQuery";
import ProjectMenu from "../../widgets/ProjectMenu/ProjectMenu";
import {projectList} from "../../pages/PetProjects/ProjectsData";

const AppRouter = () => {
    const isAuth = useSelector(state => state.isAuth.isAuth)
    const location = useLocation();
    const user = useSelector(state => state.user.user.user);
    const isMobileVersion = useMediaQuery('(max-width: 992px)');


    return (
        <AnimatePresence mode='wait'>
            <Routes location={location} key={location.pathname}>
                {isAuth && privateRoutes.map(route =>
                    <Route path={route.path}
                           element={
                               <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
                                   <route.component/>
                               </motion.div>
                           }
                           exact={route.exact}
                           key={route.path}/>
                )}

                {privateRoutes.concat(paidProjectRoutes).map(route =>
                    <Route path={route.path}
                           element={<motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
                               <Navigate to="/login" replace/>
                           </motion.div>
                           }
                           exact={route.exact}
                           key={route.path}/>
                )}


                {publicRoutes.map(route =>
                    <Route element={<motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
                        <route.component/>
                    </motion.div>}
                           path={route.path}
                           exact={route.exact}
                           key={route.path}/>
                )
                }


                {/* Add other routes specific to /pet-projects */}


                {/*TODO decide whether to put motion.div or not*/}
                {isMobileVersion &&
                <Route element={<ProjectMenu projectList={projectList}/>}
                       path={'pet-projects'}
                       exact={true}
                       key={'pet-projects'}/>}
                {isMobileVersion &&
                <Route element={<PetProjects/>}
                       path={'pet-projects/*'}
                       exact={false}
                       key={'pet-projects'}/>}
                {!isMobileVersion &&
                <Route element={<PetProjects/>}
                       path={'pet-projects/*'}
                       exact={false}
                       key={'pet-projects'}/>
                }


                {freeProjectRoutes.map(route =>
                    <Route element={<motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
                        <route.component/>
                    </motion.div>}
                           path={route.path}
                           exact={route.exact}
                           key={route.path}
                           loader={<Loader/>}
                    />
                )}
                {user?.subscriptionStatus === 'Paid' && paidProjectRoutes.concat(paidRoutes).map(route =>
                    <Route element={<motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
                        <route.component/>
                    </motion.div>}
                           path={route.path}
                           exact={route.exact}
                           key={route.path}
                           loader={<Loader/>}
                    />
                )}
                {isAuth && paidProjectRoutes.concat(paidRoutes).map(route =>
                    <Route element={
                        <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
                            <Navigate to="/offer"/>
                        </motion.div>}
                           path={route.path}
                           exact={route.exact}
                           key={route.path}
                           loader={<Loader/>}
                    />
                )}
                {paidRoutes.map(route =>
                    <Route path={route.path}
                           element={<motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
                               <Navigate to="/login" replace/>
                           </motion.div>
                           }
                           exact={route.exact}
                           key={route.path}/>
                )}



                <Route path="*" element={<Navigate to="/not-found" replace/>}/>
            </Routes>
        </AnimatePresence>
    );
};

export default AppRouter;