import React from 'react';
import cl from "./Title.module.css";

const Title = ({data}) => {
    return (
        <h3 className={cl.wrap}>
            {data.title}
        </h3>
    );
};

export default Title;