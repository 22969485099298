import React from 'react';
import cl from './Sticks.module.css'

const SticksLeft = ({styles}) => {
    return (
        <div style={{top:styles.wrapTop, left:styles.wrapLeft}} className={cl.sticksWrap}>
            <div style={{
                width: styles.width,
                height: styles.leftHeight,
                top: styles.leftStickTop,
            }} className={cl.stickLeft}> </div>

            <div style={{
                width: styles.width,
                height: styles.rightHeight,
                top: styles.rightStickTop,
                left: styles.distance
            }} className={cl.stickRight}> </div>
        </div>
    );
};

export default SticksLeft;