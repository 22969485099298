import React, {useEffect, useState} from 'react';
import Error from "../../shared/UI/Error/Error";
import Button_lilac from "../../shared/UI/Button_lilac/Button_lilac";
import {resendActivationLink} from "../../shared/api/userApi";
import Timer from "../Timer/Timer";
import cl from './ResendActivationMail.module.css';

const ResendActivationMail = ({email,password, errors}) => {
    const [startTimer, setStartTimer ] = useState(false)
    const handleResend = async () => {
        try {
            await resendActivationLink(email, password)
            setStartTimer(true)
        } catch (e) {
            console.log(e)
        }
    }
    useEffect(()=>{
        handleResend()
    },[])
    return (
        <div>
            <Error errors={errors} type="emailActivation"/>
            <Button_lilac onClick={(e) => handleResend()} type='button'>Resend</Button_lilac>
            <span className={cl.timerWrap}><Timer setStartTimer={setStartTimer} start={startTimer} secondsNumber={60}/></span>
        </div>
    );
};

export default ResendActivationMail;