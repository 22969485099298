import cl from './UserDetails.module.css'

import React, {useState} from "react";
import {logout, updateFullName} from "../../shared/api/userApi";
import RecoverPassword from "../../features/RecoverPassword/RecoverPassword";
import ChangePassword from "../../features/ChangePassword/ChangePassword";
import AutoSavedUserInput from "../../entities/AutoSavedUserInput/AutoSavedUserInput";
import TitleWithComment from "../../entities/TitleWithComment/TitleWithComment";
import LogOut from "../../entities/LogOut/LogOut";
import EmailIcon from "../../shared/UI/icons/EmailIcon";
import CardIcon from "../../shared/UI/icons/CardIcon";
import UserIdIcon from "../../shared/UI/icons/UserIdIcon";
import Button_lilac from "../../shared/UI/Button_lilac/Button_lilac";
import PackIcon from "../../shared/UI/icons/PackIcon";
import PaypalPopup from "../PaypalPopup/PaypalPopup";

const UserDetails = ({user}) => {
    const [showPaymentGate, setShowPaymentGate] = useState(false)

    return (
        <div className={cl.wrap}>
            <div className={cl.infoWrap}>
                <TitleWithComment data={{
                    leftHeight: '100px',
                    title: 'My profile',
                    comment: 'Account Details'
                }}/>
                <ul className={cl.infoList}>
                    <li>
                        <div className={cl.itemName}><UserIdIcon/>Full name:</div>
                        <AutoSavedUserInput apiMethod={updateFullName} propertyName="fullName" user={user}/>
                    </li>
                    <li>
                        <div className={cl.itemName}><EmailIcon/>Email:</div>
                        <span>  {user.email}</span>
                    </li>
                    <li>
                        <div className={cl.itemName}><CardIcon/>Subscription status:</div>
                        <span> {user.subscriptionStatus}</span>
                    </li>
                    {user.subscriptionStatus !== 'free' &&
                    <li>
                        <div className={cl.itemName}>End of the subscription:</div>
                        <span> {user?.endOfSubscription?.split('T')[0]}</span>
                    </li>}
                    {user.subscriptionStatus !== 'free' &&
                    <li className={cl.renewButtonWrap}>
                        <Button_lilac onClick={() => setShowPaymentGate(!showPaymentGate)}>Renew subscription <PackIcon/>
                        </Button_lilac>
                    </li>}
                </ul>
                <PaypalPopup  showPaymentGate={showPaymentGate}  setShowPaymentGate={setShowPaymentGate}/>

                <div className={cl.passwordHelp}>
                    <div className={cl.recoverPasswordWrap}>
                        <RecoverPassword email={user.email} propsFirstButton={{style: {fontSize: 'var(--text5)'}}}
                                         propsSecondButton={{style: {margin: "20px 0 0 25px"}}}
                                         redirectToLoginThen={false}/>
                    </div>
                    <div className={cl.changePasswordWrap}>
                        <ChangePassword user={user}/>
                    </div>
                </div>
                <LogOut/>
            </div>
        </div>
    );
};

export default UserDetails;