import React, {useRef} from 'react';
import cl from './Hover.module.css'

const Hover = () => {
    const eye = useRef(null)
    const pupil = useRef(null)
    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    const container = useRef(null)
    const onMove = (e)=>{
        const pupilSize = pupil.current.getBoundingClientRect().width;
        const eyeRect = eye.current.getBoundingClientRect();

        const computedStyle = getComputedStyle( container.current.parentNode);
        const scaleX = parseFloat(computedStyle.transform.split(',')[0].slice(7)); // Extract scaleX from transform matrix
        const scaleY = parseFloat(computedStyle.transform.split(',')[3]); // Extract scaleY from transform matrix

        let pupilX = (e.clientX - eyeRect.left);
        let pupilY = (e.clientY - eyeRect.top);
        // Calculate the position of the ball relative to the "area"


        // Ensure the ball stays inside the "area" bounds
        pupilX = Math.max(pupilSize / 2, Math.min(pupilX, eyeRect.width - pupilSize / 2))/scaleX;
        pupilY = Math.max(pupilSize / 2, Math.min(pupilY, eyeRect.height - pupilSize / 2))/scaleY;

        // Update the ball's position using CSS styles
        pupil.current.style.left = `${pupilX}px`;
        pupil.current.style.top = `${pupilY}px`;
    }
    return (
        <div onMouseMove={(e)=>onMove(e)} ref={container}>
            <div ref={eye} className={cl.eye}>
                <div ref={pupil} className={cl.pupil}>

                </div>
            </div>
        </div>

    );
};

export default Hover;