import React, {useEffect, useRef, useState} from 'react';
import cl from './ScrollingProgress.module.css'

const ScrollingProgress = () => {
    const [scrollTop, setScrollTop] = useState(0);
    const container = useRef(null)


    const onScroll = () => {

        const winScroll = container.current.scrollTop;

        const height =
            container.current.scrollHeight -
            container.current.clientHeight;
        const scrolled = (winScroll / height) * 100;

        setScrollTop(scrolled);
    };

    useEffect(() => {

        container.current.addEventListener("scroll", onScroll);

    }, []);
    return (
        <div className={cl.wrap}>
            <div ref={container}>
                <header></header>
                <div className={cl.progressBar} style={{ height: `${scrollTop}%` }}>
                </div>
                <section>1/4</section>
                <section>2/4</section>
                <section>3/4</section>
                <section>4/4</section>
            </div>
        </div>
    );
};

export default ScrollingProgress;