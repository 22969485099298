import HamburgerMenu from "../../entities/demoSites/HamburgerMenu/HamburgerMenu";
import BackgroundChanger from "../../entities/demoSites/BackgroundChanger/BackgroundChanger";
import Loader from "../../entities/demoSites/Loader/Loader";
import {accentColor, text4} from "../../shared/styles/styles";
import AccordionMenu from "../../entities/demoSites/AccordionMenu/AccordionMenu";
import Clicker from "../../entities/demoSites/Clicker/Clicker";
import Counter from "../../entities/demoSites/Counter/Counter";
import DragAndDrop from "../../entities/demoSites/DragAndDrop/DragAndDrop";
import EventCountdown from "../../entities/demoSites/EventCountdown/EventCountdown";
import FallingObjects from "../../entities/demoSites/FallingObjects/FallingObjects";
import Hover from "../../entities/demoSites/Hover/Hover";
import Map from "../../entities/demoSites/Map/Map";
import Popup from "../../entities/demoSites/Popup/Popup";
import ScrollingProgress from "../../entities/demoSites/ScrollingProgress/ScrollingProgress";
import Slider from "../../entities/demoSites/Slider/Slider";
import SmoothScroll from "../../entities/demoSites/SmoothScroll/SmoothScroll";
import Tabs from "../../entities/demoSites/Tabs/Tabs";
import TextTyper from "../../entities/demoSites/TextTyper/TextTyper";
import ThemeToggler from "../../entities/demoSites/ThemeToggler/ThemeToggler";
import Toast from "../../entities/demoSites/Toast/Toast";
import TodoList from "../../entities/demoSites/TodoList/TodoList";
import Video from "../../entities/demoSites/Video/Video";

export const projectList = [
    {title: 'Hamburger Menu', id: 'hamburger-menu'},
    {title: 'Background Changer', id: 'background-changer'},
    {title: 'Loader', id: 'loader'},
    {title: 'Clicker', id: 'clicker'},
    {title: 'Counter', id: 'counter'},
    {title: 'Theme Toggler', id: 'theme-toggler'},
    {title: 'Popup', id: 'popup'},
    {title: 'Text Typer', id: 'text-typer'},
    {title: 'FAQ', id: 'accordion-menu'},
    {title: 'Employee Slider', id: 'slider'},
    {title: 'Toast', id: 'toast'},
    {title: 'Rain', id: 'falling-objects'},
    {title: 'Tabs', id: 'tabs'},
    {title: 'Todo List', id: 'todo-list'},
    {title: 'Scrolling Progress', id: 'scrolling-progress'},
    {title: 'Smooth Scroll', id: 'smooth-scroll'},
    {title: 'Observant Gaze', id: 'hover'},
    {title: 'Drag & Drop', id: 'drag-and-drop'},
    {title: 'New Year Countdown', id: 'event-countdown'},
    {title: 'Map', id: 'map'},
    //TODO paint
    // {title: 'Paint', id: 'paint'},

    {title: '* Video', id: 'video'},
]

export const hamburgerMenuData = {
    name: 'Hamburger Menu',
    demoData: {
        title: 'Hamburger menu',
        comment: 'Try out the project here',
        leftHeight: '11vh',
        appDemo: <HamburgerMenu/>,
        descriptionArray: [
            'The hamburger menu button, represented by three stacked horizontal lines, has become a widely recognized symbol in modern user interfaces',
            'Its primary function is to provide a compact and accessible menu that houses navigation options and additional features',
            'By clicking or tapping on the hamburger menu button, users can effortlessly access hidden content, streamlining the user experience and preserving valuable screen space']
    },
    html: {
        code: '<!doctype html>\n' +
            '<html lang="en">\n' +
            '\n' +
            '  <head>\n' +
            '    <meta name="viewport" content="width=device-width, initial-scale=1.0">\n' +
            '    <link rel="stylesheet" href="style.css">\n' +
            '    <title>Hamburger Menu</title>\n' +
            '  </head>\n' +
            '\n' +
            '  <body>\n' +
            '    <button class="hamburger" id="btn">\n' +
            '      <div></div>\n' +
            '      <div></div>\n' +
            '      <div></div>\n' +
            '    </button>\n' +
            '    <nav id="nav">\n' +
            '      <ul>\n' +
            '        <li><a href="#home">Home</a></li>\n' +
            '        <li><a href="#about">About</a></li>\n' +
            '        <li><a href="#company">Company</a></li>\n' +
            '      </ul>\n' +
            '    </nav>\n' +
            '    <script src="script.js"></script>\n' +
            '  </body>\n' +
            '\n' +
            '</html>\n'
    },
    css: {
        code: '* {\n' +
            '  box-sizing: border-box;\n' +
            '  font-family: Arial, sans-serif;\n' +
            '  margin: 0;\n' +
            '  padding: 0;\n' +
            '  color: #1d1d1d;\n' +
            '}\n' +
            '\n' +
            'body {\n' +
            '  display: flex;\n' +
            '  align-items: center;\n' +
            '  justify-content: center;\n' +
            '  min-height: 100vh;\n' +
            '  background-color: #1d1d1d;\n' +
            '}\n' +
            '\n' +
            'li {\n' +
            '  list-style: none;\n' +
            '}\n' +
            '\n' +
            '.hamburger {\n' +
            '  background-color: mediumslateblue;\n' +
            '  position: fixed;\n' +
            '  top: 10px;\n' +
            '  right: 10px;\n' +
            '  border: none;\n' +
            '  transition: transform 0.3s ease-in-out;\n' +
            '  padding: 2px 6px;\n' +
            '}\n' +
            '\n' +
            '.hamburger div {\n' +
            '  width: 20px;\n' +
            '  height: 2px;\n' +
            '  background-color: white;\n' +
            '  margin: 7px 4px;\n' +
            '}\n' +
            '\n' +
            '\n' +
            '.hamburger.active {\n' +
            '  transform: translateX(-110px) translateY(-10px);\n' +
            '}\n' +
            '\n' +
            'nav {\n' +
            '  position: fixed;\n' +
            '  top: 0;\n' +
            '  right: 0;\n' +
            '  background-color: mediumslateblue;\n' +
            '  height: 100vh;\n' +
            '  transform: translateX(100%);\n' +
            '  transition: transform 0.3s ease-in-out;\n' +
            '}\n' +
            '\n' +
            'nav.active {\n' +
            '  transform: translateX(0);\n' +
            '}\n' +
            '\n' +
            'nav ul {\n' +
            '  padding: 10px;\n' +
            '}\n' +
            '\n' +
            'nav ul li {\n' +
            '  padding: 16px;\n' +
            '}\n' +
            '\n' +
            'nav ul li a {\n' +
            '  color: #fff;\n' +
            '  text-decoration: none;\n' +
            '}\n'
    },
    js: {
        code: 'const btn = document.getElementById(\'btn\');\n' +
            'const nav = document.getElementById(\'nav\');\n' +
            '\n' +
            'btn.addEventListener(\'click\', () => {\n' +
            '    nav.classList.toggle(\'active\');\n' +
            '    btn.classList.toggle(\'active\');\n' +
            '});'
    },
    guide: {
        title: 'Guide Hamburger menu',
        htmlParagraph: <span>The HTML structure consists of a button
            element acting as the <b>hamburger icon</b> and <b>navigation bar</b>.
            The hamburger icon is created using three <b>empty div blocks </b>
            nested within the button element. The navigation menu is
            represented by a <b>nav </b> element containing an unordered <b>list </b>
            of menu items. Each menu item is represented by an anchor
            (a) tag with corresponding href attributes for navigation (put real links to make it works).
            The navigation menu will be initially <b>hidden</b> using CSS and will be controlled using JavaScript</span>
        ,
        cssParagraph:
            <span>The CSS file applies <b>global styles</b> such as box-sizing and font-family.
                It sets up the body as a flex container with a background color of #1d1d1d
                and styles the hamburger icon and navigation menu using fixed positioning
                and transitions for animations. The navigation menu is <b>hidden initially</b> and
                can be shown by adding the <b>"active"</b> class, triggering the transform effects
                specified in the CSS</span>
        ,
        jsParagraph:
            <span>In the final step we will add Java Script. First we need to
            <b> locate</b> our hamburger button and moving block. After we
            need to <b>set an event</b> on the hamburger which will <b>toggle </b>
            classes 'active' to our menu and hamburger button</span>
        ,
        lastComment:
            <span><i>It is simple as that</i> <span style={{
                color: accentColor,
                fontSize: text4,
                marginLeft: '25px',
                transform: 'rotate(90deg) translateX(4px)',
                display: 'inline-block',
                letterSpacing: '0.2rem'
            }}>:)</span></span>
    }
}

export const backgroundChangerData = {
    name: 'Background Changer',
    demoData: {
        title: 'Background changer',
        comment: 'Try out the project here',
        leftHeight: '11vh',
        appDemo: <BackgroundChanger/>,
        descriptionArray:
            [
                ' The Random Background Changer is a simple and minimalistic web application with just a single button placed in the center',
                ' When clicked, this button dynamically alters the background color of the entire page, providing a visually engaging experience',
                ' Background changer is an interactive tool for effortless color exploration, suitable for personal enjoyment and inspiring designers']
    },
    html: {
        code:
            '<!doctype html>\n' +
            '<html lang="en">\n' +
            '\n' +
            '  <head>\n' +
            '    <meta name="viewport" content="width=device-width, initial-scale=1.0">\n' +
            '    <link rel="stylesheet" href="style.css">\n' +
            '    <title>Background Changer</title>\n' +
            '  </head>\n' +
            '\n' +
            '  <body>\n' +
            '    <button class="button" id="button">\n' +
            '      Change\n' +
            '    </button>\n' +
            '    <script src="script.js"></script>\n' +
            '  </body>\n' +
            '\n' +
            '</html>\n'
    },
    css: {
        code: '* {\n' +
            '  box-sizing: border-box;\n' +
            '  font-family: Arial, sans-serif;\n' +
            '  margin: 0;\n' +
            '  padding: 0;\n' +
            '  color: #1d1d1d;\n' +
            '}\n' +
            '\n' +
            'body {\n' +
            '  display: flex;\n' +
            '  align-items: center;\n' +
            '  justify-content: center;\n' +
            '  min-height: 100vh;\n' +
            '}\n' +
            '\n' +
            '.button {\n' +
            '  width: 150px;\n' +
            '  height: 80px;\n' +
            '  background-color: #1d1d1d;\n' +
            '  color: #fff;\n' +
            '  border-radius: 8px;\n' +
            '  border: none;\n' +
            '  font-size: 1.3rem;\n' +
            '}\n' +
            '\n' +
            '.button:active {\n' +
            '  transform: scale(1.02);\n' +
            '}\n'
    },
    js: {
        code: 'const btn = document.getElementById(\'button\');\n' +
            '\n' +
            'btn.addEventListener(\'click\', () => {\n' +
            '    document.body.style.backgroundColor = randomBg();\n' +
            '});\n' + '\n'+
        'function randomBg() {\n' +
                '    let colorCode = Math.floor(Math.random() * 360);\n' +
            '    return `hsl(${colorCode}, 50%, 50%`;\n' +
            '}\n'


    },
    guide: {
        title: 'Guide Background Changer',
        htmlParagraph: <span>The HTML markup consists of a basic webpage structure with a single <b>button element</b>. The button has a class "button" and an id "button" assigned to it</span>,
        cssParagraph: <span>The CSS file defines <b>global</b> box-sizing and font-family rules. It styles a fixed-size centered button with a background color and a <b>scaling effect</b> on click</span>,
        jsParagraph: <span>
              In this JavaScript code, we first <b>select</b> the button element with the id "button" and store it in the variable btn.<br/><br/>
            Then, we define a <b>function</b> called <b>randomBg()</b> that generates a random <b>HSL</b> color with a random hue value between 0 and 359 and
            fixed saturation and lightness values of 50%.<br/><br/> When the button is clicked, an <b>event listener</b> triggers an anonymous function that sets the body's
            background color to the randomly generated HSL color using the <b>randomBg()</b> function.<br/><br/> As a result, the background color of the body changes to a <b>new random
            color</b> each time the button is <b>clicked</b>.',

        </span>,
        lastComment: <span><i>It is simple as that</i> <span style={{
            color: accentColor,
            fontSize: text4,
            marginLeft: '25px',
            transform: 'rotate(90deg) translateX(4px)',
            display: 'inline-block',
            letterSpacing: '0.2rem'
        }}>:)</span></span>
    }
}

export const loaderProjectData = {
    name: 'Loader',
    demoData: {
        title: 'Loader',
        comment: 'Try out the project here',
        leftHeight:
            '11vh',
        appDemo: <Loader/>,
        descriptionArray:
            [
                ' The loader pet-project features a pleasing and minimalistic design with a set of rotating dots arranged in a circular motion',
                ' The primary function of a loader is to provide visual feedback to users while content or data is being loaded or processed in the background',
                ' It helps indicate that the website or application is working and prevents users from experiencing a blank or unresponsive page during loading times']
    },
    html: {
        code: '<!doctype html>\n' +
            '<html lang="en">\n' +
            '\n' +
            '  <head>\n' +
            '    <meta name="viewport" content="width=device-width,initial-scale=1.0">\n' +
            '    <link rel="stylesheet" href="style.css">\n' +
            '    <title>Loader</title>\n' +
            '  </head>\n' +
            '\n' +
            '  <body>\n' +
            '    <div class="wrap">\n' +
            '      <div class="loaderWrap">\n' +
            '        <div class="ldsRoller">\n' +
            '          <div></div>\n' +
            '          <div></div>\n' +
            '          <div></div>\n' +
            '          <div></div>\n' +
            '          <div></div>\n' +
            '          <div></div>\n' +
            '          <div></div>\n' +
            '          <div></div>\n' +
            '        </div>\n' +
            '        <h3 class="title">\n' +
            '          Loading\n' +
            '        </h3>\n' +
            '      </div>\n' +
            '    </div>\n' +
            '  </body>\n' +
            '\n' +
            '</html>'
    },
    css: {
        code: '* {\n' +
            '  box-sizing: border-box;\n' +
            '  font-family: arial, sans-serif;\n' +
            '  margin: 0;\n' +
            '  padding: 0;\n' +
            '  color: #1d1d1d;\n' +
            '}\n' +
            '\n' +
            'body {\n' +
            '  display: flex;\n' +
            '  justify-content: center;\n' +
            '  align-items: center;\n' +
            '  height: 100vh;\n' +
            '}\n' +
            '\n' +
            '.title {\n' +
            '  font-size: 1.5rem;\n' +
            '  margin-top: 15px;\n' +
            '}\n' +
            '\n' +
            '.loaderWrap {\n' +
            '  display: flex;\n' +
            '  justify-content: center;\n' +
            '  align-items: center;\n' +
            '  flex-direction: column;\n' +
            '}\n' +
            '\n' +
            '.ldsRoller {\n' +
            '  margin: 0 auto 1px;\n' +
            '  display: inline-block;\n' +
            '  position: relative;\n' +
            '  width: 80px;\n' +
            '  height: 80px;\n' +
            '}\n' +
            '\n' +
            '.ldsRoller div {\n' +
            '  animation: ldsRoller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;\n' +
            '  transform-origin: 40px 40px;\n' +
            '}\n' +
            '\n' +
            '.ldsRoller div::after {\n' +
            '  content: " ";\n' +
            '  display: block;\n' +
            '  position: absolute;\n' +
            '  width: 7px;\n' +
            '  height: 7px;\n' +
            '  border-radius: 50%;\n' +
            '  background: mediumslateblue;\n' +
            '  margin: -4px 0 0 -4px;\n' +
            '}\n' +
            '\n' +
            '.ldsRoller div:nth-child(1) {\n' +
            '  animation-delay: -0.036s;\n' +
            '}\n' +
            '\n' +
            '.ldsRoller div:nth-child(1)::after {\n' +
            '  top: 63px;\n' +
            '  left: 63px;\n' +
            '}\n' +
            '\n' +
            '.ldsRoller div:nth-child(2) {\n' +
            '  animation-delay: -0.072s;\n' +
            '}\n' +
            '\n' +
            '.ldsRoller div:nth-child(2)::after {\n' +
            '  top: 68px;\n' +
            '  left: 56px;\n' +
            '}\n' +
            '\n' +
            '.ldsRoller div:nth-child(3) {\n' +
            '  animation-delay: -0.108s;\n' +
            '}\n' +
            '\n' +
            '.ldsRoller div:nth-child(3)::after {\n' +
            '  top: 71px;\n' +
            '  left: 48px;\n' +
            '}\n' +
            '\n' +
            '.ldsRoller div:nth-child(4) {\n' +
            '  animation-delay: -0.144s;\n' +
            '}\n' +
            '\n' +
            '.ldsRoller div:nth-child(4)::after {\n' +
            '  top: 72px;\n' +
            '  left: 40px;\n' +
            '}\n' +
            '\n' +
            '.ldsRoller div:nth-child(5) {\n' +
            '  animation-delay: -0.18s;\n' +
            '}\n' +
            '\n' +
            '.ldsRoller div:nth-child(5)::after {\n' +
            '  top: 71px;\n' +
            '  left: 32px;\n' +
            '}\n' +
            '\n' +
            '.ldsRoller div:nth-child(6) {\n' +
            '  animation-delay: -0.216s;\n' +
            '}\n' +
            '\n' +
            '.ldsRoller div:nth-child(6)::after {\n' +
            '  top: 68px;\n' +
            '  left: 24px;\n' +
            '}\n' +
            '\n' +
            '.ldsRoller div:nth-child(7) {\n' +
            '  animation-delay: -0.252s;\n' +
            '}\n' +
            '\n' +
            '.ldsRoller div:nth-child(7)::after {\n' +
            '  top: 63px;\n' +
            '  left: 17px;\n' +
            '}\n' +
            '\n' +
            '.ldsRoller div:nth-child(8) {\n' +
            '  animation-delay: -0.288s;\n' +
            '}\n' +
            '\n' +
            '.ldsRoller div:nth-child(8)::after {\n' +
            '  top: 56px;\n' +
            '  left: 12px;\n' +
            '}\n' +
            '\n' +
            '@keyframes ldsRoller {\n' +
            '  0% {\n' +
            '    transform: rotate(0deg);\n' +
            '  }\n' +
            '\n' +
            '  100% {\n' +
            '    transform: rotate(360deg);\n' +
            '  }\n' +
            '}\n'
    },
    guide: {
        title: 'Guide Loader',
        htmlParagraph: <span>The HTML structure consists of a basic
            webpage with a title "Loader." Inside the <b>body</b>, there is
            a div element with the class "wrap" which contains a loader
            section. The loader section has a
            <b> set of rotating dots </b> represented by the <b>empty divs</b>. Additionally,
            there is an <b>h3</b> element with the class "title" displaying the text "Loading"
            within the loader section </span>,
        cssParagraph: <span> The CSS structure defines a <b>loader animation</b> called "ldsRoller"
            using the @keyframes rule, which <b>rotates</b> the dots 360 degrees from 0% to 100% of
            the animation duration. Each dot element is styled with the "ldsRoller" class, and
            the animation property applies the animation to each dot with a  <b>specified delay</b>, creating
            a staggered effect. This approach simplifies the process for programmers as they <b>don't</b> need
            to <b>manually position</b> each dot, but instead focus on understanding how animations work and can
            <b> easily</b> modify and customize the animation as needed. However, it's important to mention that many
            developers use <b>ready-made</b> or basic animations to save time and effort. You need to have <b>enough</b> knowledge to
            create such animations from scratch, but remember that there is no point in <b>reinventing the wheel</b></span>,
        lastComment: <span><i>It is simple as that</i> <span style={{
            color: accentColor,
            fontSize: text4,
            marginLeft: '25px',
            transform: 'rotate(90deg) translateX(4px)',
            display: 'inline-block',
            letterSpacing: '0.2rem'
        }}>:)</span></span>
    }
}

export const AccordionMenuData = {
    name: 'Accordion menu',
    demoData: {
        title: 'Accordion menu',
        comment: 'Try out the project here',
        leftHeight:
            '11vh',
        appDemo: <AccordionMenu/>,
        descriptionArray:
            [
                'The Accordion menu has a simple and tidy look, showing neat collapsible sections. It\'s easy to see and use, making it great for users',
                'The project functions effectively, enabling quick opening/closing of sections to display abundant information without clutter',
                'You can use the Accordion menu in many ways, like for website menus or FAQs. It makes things organized and users like it']
    },
    html: {
        code: '<!doctype html>\n' +
            '<html lang="en">\n' +
            '\n' +
            '  <head>\n' +
            '    <meta name="viewport" content="width=device-width, initial-scale=1.0">\n' +
            '    <link rel="stylesheet" href="style.css">\n' +
            '    <title>Accordion Menu</title>\n' +
            '  </head>\n' +
            '\n' +
            '  <body>\n' +
            '    <article>\n' +
            '      <button class=\'questionWrap\'>\n' +
            '        <h2 class=\'question\'>\n' +
            '          How to make accordion menu?\n' +
            '        </h2>\n' +
            '        <div class=\'iconWrap\'>\n' +
            '          <span class=\'icon\'>+</span>\n' +
            '        </div>\n' +
            '      </button>\n' +
            '      <p class=\'answer\'>\n' +
            '        Follow the information in the Guide tab to complete According Menu project\n' +
            '      </p>\n' +
            '    </article>\n' +
            '    <article>\n' +
            '      <button class=\'questionWrap\'>\n' +
            '        <h2 class=\'question\'>\n' +
            '          Is it used in real projects?\n' +
            '        </h2>\n' +
            '        <div class=\'iconWrap\'>\n' +
            '          <span class=\'icon\'>+</span>\n' +
            '        </div>\n' +
            '      </button>\n' +
            '      <p class=\'answer\'>\n' +
            '        Yes, it is commonly used in real-world projects, particularly in the field of UI design\n' +
            '      </p>\n' +
            '    </article>\n' +
            '    <article>\n' +
            '      <button class=\'questionWrap\'>\n' +
            '        <h2 class=\'question\'>\n' +
            '          Should I add it to my portfolio?\n' +
            '        </h2>\n' +
            '        <div class=\'iconWrap\'>\n' +
            '          <span class=\'icon\'>+</span>\n' +
            '        </div>\n' +
            '      </button>\n' +
            '      <p class=\'answer\'>\n' +
            '        Absolutely! It will surely catch the recruiter\'s eye\n' +
            '      </p>\n' +
            '    </article>\n' +
            '    <script src="script.js"></script>\n' +
            '  </body>\n' +
            '\n' +
            '</html>\n'
    },
    css: {
        code: '* {\n' +
            '  box-sizing: border-box;\n' +
            '  font-family: arial, sans-serif;\n' +
            '  margin: 0;\n' +
            '  padding: 0;\n' +
            '  color: #1d1d1d;\n' +
            '}\n' +
            '\n' +
            'body {\n' +
            '  width: 500px;\n' +
            '  margin: 20px auto;\n' +
            '}\n' +
            '\n' +
            'article {\n' +
            '  border: 2px solid #1d1d1d;\n' +
            '  border-radius: 6px;\n' +
            '  padding: 10px 25px;\n' +
            '  margin: 0 0 25px;\n' +
            '}\n' +
            '\n' +
            '.questionWrap {\n' +
            '  display: flex;\n' +
            '  text-align: left;\n' +
            '  align-items: center;\n' +
            '  background: white;\n' +
            '  padding: 5px 0;\n' +
            '  border: none;\n' +
            '  width: 100%;\n' +
            '}\n' +
            '\n' +
            '.question {\n' +
            '  width: 75%;\n' +
            '  font-weight: 900;\n' +
            '  font-size: 20px;\n' +
            '}\n' +
            '\n' +
            '.iconWrap {\n' +
            '  margin-left: auto;\n' +
            '  background: mediumslateblue;\n' +
            '  width: 30px;\n' +
            '  height: 30px;\n' +
            '  display: flex;\n' +
            '  justify-content: center;\n' +
            '  align-items: center;\n' +
            '  border-radius: 10px;\n' +
            '}\n' +
            '\n' +
            '.icon {\n' +
            '  font-size: 16px;\n' +
            '  font-weight: 900;\n' +
            '  color: white;\n' +
            '}\n' +
            '\n' +
            '.answer {\n' +
            '  font-weight: 300;\n' +
            '  font-size: 20px;\n' +
            '  max-height: 0;\n' +
            '  overflow: hidden;\n' +
            '  transition: max-height 0.5s ease;\n' +
            '  width: 80%\n' +
            '}\n' +
            '\n' +
            '.active {\n' +
            '  max-height: 200px;\n' +
            '}\n'
    },
    js: {
        code: 'const articles = document.querySelectorAll(\'article\');\n' +
            '\n' +
            'articles.forEach((article) => {\n' +
            '  const answer = article.querySelector(\'.answer\');\n' +
            '  const icon = article.querySelector(\'.icon\');\n' +
            '  const questionWrap = article.querySelector(\'.questionWrap\');\n' +
            '  questionWrap.addEventListener(\'click\', () => {\n' +
            '    answer.classList.toggle(\'active\');\n' +
            '    icon.innerHTML = icon.innerHTML === \'+\' ? \'-\' : \'+\';\n' +
            '  });\n' +
            '});\n '
    },
    guide: {
        title: 'Guide Accordion menu',
        htmlParagraph: <span>To create the HTML part of your accordion menu,
            you'll start with the <b>basic structure</b> of an HTML document. To structure
            your accordion menu, use HTML's <b>"article"</b> tag to group each item.
            Within each "article", employ a "button" for the question and a <b>hidden</b> "div"
            for the answer content. This layout forms the foundation of your accordion menu </span>,
        cssParagraph: <span>For styling, set basic properties with a <b>universal selector</b>, center
            the content in the body, and give each article a border. Utilize flex for question alignment,
            style the icon, and control <b>answer visibility</b> with transitions. This defines the visual aspects
            of your accordion menu </span>,
        jsParagraph: <span> For JavaScript interactivity, you can use a neat <b>trick</b>.
            Run a loop, like "forEach()", on the chosen "article" elements retrieved  using
            "document.querySelectorAll('article')". Inside this loop, pick specific elements
            within each "article" using <b>"querySelector()"</b>, like ".answer", ".icon", and ".questionWrap".
            This keeps your code tidy and avoids repeating steps.<br/> <br/> When users <b>click</b> on the "questionWrap"
            button, the code switches the <b>"active"</b> class for ".answer", showing
            or hiding the answer. It also changes the ".icon" content from <b>'+' to '-'</b>, showing whether the
            accordion item is open or closed </span>,
        lastComment: <span><i>It is simple as that</i> <span style={{
            color: accentColor,
            fontSize: text4,
            marginLeft: '25px',
            transform: 'rotate(90deg) translateX(4px)',
            display: 'inline-block',
            letterSpacing: '0.2rem'
        }}>:)</span></span>
    }
}

export const ClickerData = {
    name: 'Clicker',
    demoData: {
        title: 'Clicker',
        comment: 'Try out the project here',
        leftHeight:
            '11vh',
        appDemo: <Clicker/>,
        descriptionArray:
            [
                'Clicker is found on almost every website, making it a must-have element that really helps users get involved and use the site better',
                'This project is all about simple functionality. Users click, and it counts. It\'s a fun and engaging way to track interactions',
                'The Clicker project finds use in various scenarios, like counting people, tracking items, or even as a basic game mechanic. It\'s versatile and handy']
    },
    html: {
        code: '<!doctype html>\n' +
            '<html lang="en">\n\n' +
            '  <head>\n' +
            '    <meta name="viewport" content="width=device-width,  initial-scale=1.0">\n' +
            '    <link rel="stylesheet" href="style.css">\n' +
            '    <title>Clicker</title>\n' +
            '  </head>\n\n' +
            '  <body>\n' +
            '    <div>\n' +
            '      <h2 class=\'score\'>\n' +
            '        Score : <spam>0</spam>\n' +
            '      </h2>\n' +
            '      <button class=\'button\'>Click me</button>\n' +
            '    </div>\n' +
            '    <script src="script.js"></script>\n' +
            '  </body>\n\n' +
            '</html> '
    },
    css: {
        code: '* {\n' +
            '  box-sizing: border-box;\n' +
            '  font-family: Arial, sans-serif;\n' +
            '  margin: 0;\n' +
            '  padding: 0;\n' +
            '  color: #1d1d1d;\n' +
            '}\n' +
            '\n' +
            'body {\n' +
            '  height: 100vh;\n' +
            '  display: flex;\n' +
            '  justify-content: center;\n' +
            '  flex-direction: column;\n' +
            '  text-align: center;\n' +
            '}\n' +
            '\n' +
            '.score {\n' +
            '  margin-bottom: 20px;\n' +
            '  font-size: 30px;\n' +
            '}\n' +
            '\n' +
            '.button {\n' +
            '  padding: 15px 15px;\n' +
            '  background: mediumslateblue;\n' +
            '  border: 0;\n' +
            '  display: inline-block;\n' +
            '  border-radius: 5px;\n' +
            '  font-size: 20px;\n' +
            '  color: white;\n' +
            '}\n'
    },
    js: {
        code: 'const score = document.querySelector(\'.score spam\');\n' +
            'const button = document.querySelector(\'.button\');\n' +
            '\n' +
            'button.addEventListener(\'click\', () => {\n' +
            '  score.innerHTML = parseInt(score.innerHTML) + 1;\n' +
            '});'
    },
    guide: {
        title: 'Guide Clicker',
        htmlParagraph: <span>Set up the <b>basic structure</b> with a "body" element,
            containing a "div" for score display using "h2" and "spam" elements,
            and a "button" element. Include the JavaScript file using the "script"
            tag </span>,
        cssParagraph: <span>Reset styles,
            align text center, set full viewport height for the "body",
            style "score" class with margin and font size, and customize
            the "button" class with padding, color, and border </span>,
        jsParagraph: <span> To make the clicker functionality work, first, select the "score"
            element using the querySelector method with the class "score spam". Similarly, select
            the "button" element using the class "button". Add an event listener to the button using
            the "addEventListener" method with the 'click' event. Inside the event listener, increment
            the innerHTML of the "score" element by converting it to an integer and adding 1. This will
            increase the displayed score value by one each time the button is clicked</span>,
        lastComment: <span><i>It is simple as that</i> <span style={{
            color: accentColor,
            fontSize: text4,
            marginLeft: '25px',
            transform: 'rotate(90deg) translateX(4px)',
            display: 'inline-block',
            letterSpacing: '0.2rem'
        }}>:)</span></span>
    }
}

export const CounterData = {
    name: 'Counter',
    demoData: {
        title: 'Counter',
        comment: 'Try out the project here',
        leftHeight:
            '11vh',
        appDemo: <Counter/>,
        descriptionArray:
            [
                'Counters are a common feature on websites, serving as a valuable tool for tracking and presenting various types of information',
                'This project does one thing: it counts up or down. Users can adjust the numbers and watch them change, providing a simple and effective way to track things',
                'The Counter project finds practical uses like tracking website visits, inventory, or even personal goals. Its versatility makes it handy in various scenarios for keeping count accurately']
    },
    html: {
        code: '<!doctype html>\n' +
            '<html lang="en">\n' +
            '\n' +
            '  <head>\n' +
            '    <meta name="viewport" content="width=device-width, initial-scale=1.0">\n' +
            '    <link rel="stylesheet" href="style.css">\n' +
            '    <title>Counter</title>\n' +
            '  </head>\n' +
            '\n' +
            '  <body>\n' +
            '    <div>\n' +
            '      <h2 class=\'title\'>Counter</h2>\n' +
            '      <div class=\'counterWrap\'>\n' +
            '        <button class=\'decButton\'>-</button>\n' +
            '        <span class=\'score\'>0</span>\n' +
            '        <button class=\'incButton\'>+</button>\n' +
            '      </div>\n' +
            '    </div>\n' +
            '    <script src="script.js"></script>\n' +
            '  </body>\n' +
            '\n' +
            '</html>\n '
    },
    css: {
        code: ' * {\n' +
            '  box-sizing: border-box;\n' +
            '  font-family: Arial, sans-serif;\n' +
            '  margin: 0;\n' +
            '  padding: 0;\n' +
            '  color: #1d1d1d;\n' +
            '}\n' +
            '\n' +
            'body {\n' +
            '  height: 100vh;\n' +
            '  display: flex;\n' +
            '  justify-content: center;\n' +
            '  flex-direction: column;\n' +
            '  text-align: center;\n' +
            '}\n' +
            '\n' +
            '.title {\n' +
            '  margin-bottom: 30px;\n' +
            '  font-size: 30px;\n' +
            '}\n' +
            '\n' +
            '.counterWrap {\n' +
            '  display: flex;\n' +
            '  justify-content: center;\n' +
            '}\n' +
            '\n' +
            'button {\n' +
            '  display: flex;\n' +
            '  justify-content: center;\n' +
            '  align-items: center;\n' +
            '  background: mediumslateblue;\n' +
            '  border: 0;\n' +
            '  border-radius: 5px;\n' +
            '  font-size: 20px;\n' +
            '  color: white;\n' +
            '  margin: 0 35px;\n' +
            '  width: 36px;\n' +
            '  height: 36px;\n' +
            '}\n' +
            '\n' +
            '.score {\n' +
            '  font-size: 30px;\n' +
            '  min-width: 42px;\n' +
            '  text-align: center;\n' +
            '}'
    },
    js: {
        code: ' const score = document.querySelector(\'.score\');\n' +
            'const decButton = document.querySelector(\'.decButton\');\n' +
            'const incButton = document.querySelector(\'.incButton\');\n' +
            '\n' +
            'decButton.addEventListener(\'click\', () => {\n' +
            '  score.innerHTML = parseInt(score.innerHTML) - 1;\n' +
            '});\n' +
            'incButton.addEventListener(\'click\', () => {\n' +
            '  score.innerHTML = parseInt(score.innerHTML) + 1;\n' +
            '});'
    },
    guide: {
        title: 'Guide Counter',
        htmlParagraph: <span>Create a <b>basic structure</b> with a "body", a "div" containing
            a title, and a "counterWrap" with decrease and increase buttons and a score
            display. Include the JavaScript file using the "script" tag </span>,
        cssParagraph: <span>Reset styles, center content in the viewport,
            and style the title and buttons for the counter display </span>,
        jsParagraph: <span>Use the "querySelector" method to <b>target</b> the "score", "decButton",
            and "incButton" elements. Set up <b>"click" event</b> listeners for the decrease and increase
            buttons. Inside the decrease button's event listener, reduce the score by 1 using "parseInt"
            and update the "score" element's <b>innerHTML</b>. Similarly, in the increase button's event listener,
            raise the score by 1 and update the display using the same method </span>,
        lastComment: <span><i>It is simple as that</i> <span style={{
            color: accentColor,
            fontSize: text4,
            marginLeft: '25px',
            transform: 'rotate(90deg) translateX(4px)',
            display: 'inline-block',
            letterSpacing: '0.2rem'
        }}>:)</span></span>
    }
}

export const DragAndDropData = {
    name: 'Drag & Drop',
    demoData: {
        title: 'Drag & Drop',
        comment: 'Try out the project here',
        leftHeight:
            '11vh',
        appDemo: <DragAndDrop/>,
        descriptionArray:
            [
                'The Drag and Drop project features a clear layout where three elements are movable within a designated area',
                'This project offers a straightforward function where users can click and drag the three elements within the specified area. The interaction is intuitive, allowing users to rearrange the elements as they see fit',
                'The Drag and Drop project has versatile applications, from educational games to interactive design tools. It engages users by letting them manipulate on-screen elements, promoting creativity and learning']
    },
    html: {
        code: '<!doctype html>\n' +
            '<html lang="en">\n' +
            '\n' +
            '  <head>\n' +
            '    <meta name="viewport" content="width=device-width, initial-scale=1.0">\n' +
            '    <link rel="stylesheet" href="style.css">\n' +
            '    <title>Drag&Drop</title>\n' +
            '  </head>\n' +
            '\n' +
            '  <body>\n' +
            '    <div id="square" ondrop="handleDrop(event)" ondragover="handleDragOver(event)">\n' +
            '      <div class="draggable" draggable="true" ondragstart="handleDragStart(event)"></div>\n' +
            '      <div class="draggable secondSquare" draggable="true" ondragstart="handleDragStart(event)"></div>\n' +
            '      <div class="draggable thirdSquare" draggable="true" ondragstart="handleDragStart(event)"></div>\n' +
            '    </div>\n' +
            '    <script src="script.js"></script>\n' +
            '  </body>\n' +
            '\n' +
            '</html>\n'
    },
    css: {
        code: '   * {\n' +
            '    box-sizing: border-box;\n' +
            '    font-family: Arial, sans-serif;\n' +
            '    margin: 0;\n' +
            '    padding: 0;\n' +
            '    color: #1d1d1d;\n' +
            '  }\n' +
            '\n' +
            '  body {\n' +
            '    display: flex;\n' +
            '    align-items: center;\n' +
            '    justify-content: center;\n' +
            '    min-height: 100vh;\n' +
            '  }\n' +
            '\n' +
            '  #square {\n' +
            '    width: 400px;\n' +
            '    height: 400px;\n' +
            '    border: 2px solid #1d1d1d;\n' +
            '    position: relative;\n' +
            '    overflow: hidden;\n' +
            '  }\n' +
            '\n' +
            '  .draggable {\n' +
            '    width: 50px;\n' +
            '    height: 50px;\n' +
            '    background-color: mediumslateblue;\n' +
            '    position: absolute;\n' +
            '    cursor: grab;\n' +
            '    top: 10%;\n' +
            '    left: 10%;\n' +
            '  }\n' +
            '\n' +
            '  .secondSquare {\n' +
            '    background-color: darkred;\n' +
            '    top: 30%;\n' +
            '    left: 60%;\n' +
            '  }\n' +
            '\n' +
            '  .thirdSquare {\n' +
            '    background-color: #1d1d1d;\n' +
            '    top: 70%;\n' +
            '    left: 20%;\n' +
            '  }\n'
    },
    js: {
        code: 'let draggableItem = null;\n' +
            '\n' +
            'function handleDragOver(event) {\n' +
            '  event.preventDefault();\n' +
            '}\n' +
            '\n' +
            'function handleDragStart(event) {\n' +
            '  draggableItem = event.target;\n' +
            '  event.dataTransfer.setData("text/plain", \'\');\n' +
            '}\n' +
            '\n' +
            'function handleDrop(event) {\n' +
            '  event.preventDefault();\n' +
            '  const square = document.getElementById("square");\n' +
            '  const rect = square.getBoundingClientRect();\n' +
            '  const x = event.clientX - rect.left;\n' +
            '  const y = event.clientY - rect.top;\n' +
            '  draggableItem.style.left = x - draggableItem.offsetWidth / 2 + "px";\n' +
            '  draggableItem.style.top = y - draggableItem.offsetHeight / 2 + "px";\n' +
            '  draggableItem = null;\n' +
            '}\n'
    },
    guide: {
        title: 'Guide Drag and Drop ',
        htmlParagraph: <span>Begin by setting up the basic HTML structure. Inside the "body", create a "div" with the ID
            "square" that will be the <b>container</b> for the draggable elements. Within the "square" div, add multiple "div"
            elements with the class <b>"draggable"</b>. Each draggable element should have the "draggable" attribute set to "true",
            an "ondragstart" event handler to <b>initiate dragging</b> and "ondragover" to <b>handle end</b> of the movement</span>,
        cssParagraph: <span> Reset styles, center the body's content, style the "square" container and draggable
            elements with sizes, positions, colors, and set a <b>"grab" cursor</b></span>,
        jsParagraph: <span>For the JavaScript functionality, declare a variable named <b>"draggableItem"</b>.
            Define functions for handling drag-over, drag-start, and drop events. In the "handleDragOver" function,
            prevent the default behavior to <b>allow dropping</b>. In the "handleDragStart" function, set the "draggableItem"
            variable to the dragged element and use "event.dataTransfer.setData" to <b>prepare</b> for data transfer<br/> <br/>

            During a drag operation, the <b>event.dataTransfer</b> object is used to <b>hold the data</b> being dragged.
            The setData method allows you to set the data that will be transferred when the drop event occurs.
            The first argument specifies the data format, and the second argument is the actual data to be transferred.
            In this line, an empty string is being set as the data with the format <b>"text/plain"</b>. While the empty string might seem strange,
            it's a <b>common practice</b> in drag-and-drop operations <br/><br/>

            In the "handleDrop" function, prevent the <b>default behavior</b> and calculate the <b>new position </b>based on mouse coordinates.
            <b>Update</b> the dragged item's position and reset the "draggableItem" variable</span>,
        lastComment: <span><i>It is simple as that</i> <span style={{
            color: accentColor,
            fontSize: text4,
            marginLeft: '25px',
            transform: 'rotate(90deg) translateX(4px)',
            display: 'inline-block',
            letterSpacing: '0.2rem'
        }}>:)</span></span>
    }
}

export const EventCountdownData = {
    name: 'Event countdown',
    demoData: {
        title: 'Event countdown',
        comment: 'Try out the project here',
        leftHeight:
            '11vh',
        appDemo: <EventCountdown/>,
        descriptionArray:
            [
                'The project features a simple yet elegant appearance with a title "New Year Countdown". The countdown timer is displayed using clear and concise blocks for time units',
                'In terms of functionality, the project enables you to accurately calculate and display the time remaining until any desired event. The real-time countdown generates anticipation as it approaches the chosen date',
                'This project is versatile, suitable for holidays, birthdays, or product launches. It offers an engaging way to track time for events, making it a valuable addition to websites or personal projects']
    },
    html: {
        code: '<!doctype html>\n' +
            '<html lang="en">\n' +
            '\n' +
            '  <head>\n' +
            '    <meta name="viewport" content="width=device-width, initial-scale=1.0">\n' +
            '    <link rel="stylesheet" href="style.css">\n' +
            '    <title>Event countdown</title>\n' +
            '  </head>\n' +
            '\n' +
            '  <body>\n' +
            '    <h3 class=\'title\'>New Year <br />\n' +
            '      <span style=\'color:mediumslateblue\'>Countdown</span>\n' +
            '    </h3>\n' +
            '    <div class=\'timeLeft\'>\n' +
            '      <div class=\'timeWrap\'>\n' +
            '        <span class=\'days\'></span>\n' +
            '        <span class=\'hours\'></span>\n' +
            '        <span class=\'minutes\'></span>\n' +
            '        <span class=\'seconds\'></span>\n' +
            '      </div>\n' +
            '      <div class=\'unitsWrap\'>\n' +
            '        <span>Days</span>\n' +
            '        <span>Hours</span>\n' +
            '        <span>Minutes</span>\n' +
            '        <span>Seconds</span>\n' +
            '      </div>\n' +
            '    </div>\n' +
            '    <script src="script.js"></script>\n' +
            '  </body>\n' +
            '\n' +
            '</html>\n'
    },
    css: {
        code: ' * {\n' +
            '  box-sizing: border-box;\n' +
            '  font-family: Arial, sans-serif;\n' +
            '  margin: 0;\n' +
            '  padding: 0;\n' +
            '  color: #1d1d1d;\n' +
            '}\n' +
            '\n' +
            'body {\n' +
            '  height: 100vh;\n' +
            '  display: flex;\n' +
            '  justify-content: center;\n' +
            '  flex-direction: column;\n' +
            '  text-align: center;\n' +
            '}\n' +
            '\n' +
            '.title {\n' +
            '  line-height: 3rem;\n' +
            '  font-weight: 700;\n' +
            '  font-size: 35px;\n' +
            '}\n' +
            '\n' +
            '.timeLeft {\n' +
            '  margin: 35px 0 0 20px;\n' +
            '  display: flex;\n' +
            '  justify-content: center;\n' +
            '}\n' +
            '\n' +
            '.timeLeft>div {\n' +
            '  display: flex;\n' +
            '  flex-direction: column;\n' +
            '  margin: 10px;\n' +
            '  font-weight: 700;\n' +
            '}\n' +
            '\n' +
            '.timeWrap {\n' +
            '  text-align: right;\n' +
            '  color: mediumslateblue;\n' +
            '}\n' +
            '\n' +
            '.unitsWrap {\n' +
            '  text-align: left;\n' +
            '  position: relative;\n' +
            '}\n' +
            '\n' +
            '.unitsWrap::after {\n' +
            '  content: \'\';\n' +
            '  position: absolute;\n' +
            '  left: -10px;\n' +
            '  top: 0;\n' +
            '  width: 3px;\n' +
            '  background: mediumslateblue;\n' +
            '  height: 100%;\n' +
            '}\n'
    },
    js: {
        code: 'const endDate = \'2023-12-31T23:59:59\';\n' +
            '\n' +
            'const days = document.querySelector(\'.days\');\n' +
            'const hours = document.querySelector(\'.hours\');\n' +
            'const minutes = document.querySelector(\'.minutes\');\n' +
            'const seconds = document.querySelector(\'.seconds\');\n' +
            'refreshTimeLeft(endDate)\n' +
            '\n' +
            'setInterval(() => {\n' +
            '  refreshTimeLeft(endDate)\n' +
            '}, 1000)\n' +
            '\n' +
            'function refreshTimeLeft(endDate) {\n' +
            '  let timeLeft = getTimeLeft(endDate);\n' +
            '  days.innerHTML = timeLeft.days;\n' +
            '  hours.innerHTML = timeLeft.hours;\n' +
            '  minutes.innerHTML = timeLeft.minutes;\n' +
            '  seconds.innerHTML = timeLeft.seconds;\n' +
            '}\n' +
            '\n' +
            'function getTimeLeft(endDate) {\n' +
            '  const targetDate = endDate instanceof Date ? endDate : new Date(endDate);\n' +
            '  const now = new Date();\n' +
            '  const timeLeft = targetDate.getTime() - now.getTime();\n' +
            '\n' +
            '  if (timeLeft < 0) {\n' +
            '    return [0, 0, 0, 0];\n' +
            '  }\n' +
            '\n' +
            '  const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));\n' +
            '  const hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);\n' +
            '  const minutes = Math.floor((timeLeft / (1000 * 60)) % 60);\n' +
            '  const seconds = Math.floor((timeLeft / 1000) % 60);\n' +
            '\n' +
            '  return {\n' +
            '    days,\n' +
            '    hours,\n' +
            '    minutes,\n' +
            '    seconds\n' +
            '  };\n' +
            '}\n'
    },
    guide: {
        title: 'Guide Event Countdown',
        htmlParagraph: <span> <b>Basic</b> HTML structure is used here. Create the countdown using "h3" for the title, and "div"
            elements for countdown values and units. Include a "script" tag for JavaScript</span>,
        cssParagraph: <span> Center content using <b>flex</b> in "body". Style ".title", ".timeLeft", ".timeWrap", ".unitsWrap" for layout and appearance</span>,
        jsParagraph: <span> To make your event countdown functional, start by defining a constant
            "endDate" with the date and time of <b>your event</b>. Then, use document.querySelector to select
            the elements with classes "days", "hours", "minutes", and "seconds". Invoke the "refreshTimeLeft"
            function with the initial "endDate". Use "setInterval" to <b>repeatedly call</b> "refreshTimeLeft" every
            second (1000 milliseconds). In the "refreshTimeLeft" function, get the time left by calling "getTimeLeft"
            with the "endDate". <b>Update</b> the content of the selected elements with the calculated days, hours, minutes,
            and seconds. The "getTimeLeft" function calculates the time left in days, hours, minutes, and seconds by
            comparing the <b>target date</b> with the <b>current date</b>. It ensures that if the time left is negative, it displays
            zeros for all countdown values</span>,
        lastComment: <span><i>It is simple as that</i> <span style={{
            color: accentColor,
            fontSize: text4,
            marginLeft: '25px',
            transform: 'rotate(90deg) translateX(4px)',
            display: 'inline-block',
            letterSpacing: '0.2rem'
        }}>:)</span></span>
    }
}

export const FallingObjectsData = {
    name: 'Falling objects',
    demoData: {
        title: 'Falling objects',
        comment: 'Try out the project here',
        leftHeight:
            '11vh',
        appDemo: <FallingObjects/>,
        descriptionArray:
            [
                'The project has a basic button. When you click it, things fall from the top and disappear after a bit',
                'In other words, clicking the button generates falling objects with random X positions. These objects animate a fall and vanish after a few seconds',
                'This project enhances websites with interactive animations. For instance, it can create falling snowflakes on winter-themed sites or add confetti to make celebration pages more exciting, giving a dynamic touch to various web experiences']
    },
    html: {
        code: '<!doctype html>\n' +
            '<html lang="en">\n' +
            '\n' +
            '  <head>\n' +

            '    <meta name="viewport" content="width=device-width, initial-scale=1.0">\n' +
            '    <link rel="stylesheet" href="style.css">\n' +
            '    <title>Falling objects</title>\n' +
            '  </head>\n' +
            '\n' +
            '  <body>\n' +
            '    <button class="button" id="button">\n' +
            '      Rain\n' +
            '    </button>\n' +
            '    <script src="script.js"></script>\n' +
            '  </body>\n' +
            '\n' +
            '</html>\n'
    },
    css: {
        code: ' * {\n' +
            '  box-sizing: border-box;\n' +
            '  font-family: Arial, sans-serif;\n' +
            '  margin: 0;\n' +
            '  padding: 0;\n' +
            '  color: #1d1d1d;\n' +
            '}\n' +
            '\n' +
            'body {\n' +
            '  display: flex;\n' +
            '  align-items: center;\n' +
            '  justify-content: center;\n' +
            '  min-height: 100vh;\n' +
            '  background-color: #151515;\n' +
            '}\n' +
            '\n' +
            '.button {\n' +
            '  width: 130px;\n' +
            '  height: 70px;\n' +
            '  background-color: mediumslateblue;\n' +
            '  color: #fff;\n' +
            '  border-radius: 5px;\n' +
            '  border: none;\n' +
            '  font-size: 1.3em;\n' +
            '  transition: 0.3s ease;\n' +
            '}\n' +
            '\n' +
            '.active {\n' +
            '  box-shadow: 0 0 30px 7px rgba(102, 51, 153, 0.76);\n' +
            '}\n' +
            '\n' +
            '.object {\n' +
            '  position: fixed;\n' +
            '  top: -3vh;\n' +
            '  transform: translateY(0);\n' +
            '  animation: fall 3s linear forwards;\n' +
            '  color: mediumslateblue;\n' +
            '}\n' +
            '\n' +
            '@keyframes fall {\n' +
            '  to {\n' +
            '    transform: translateY(105vh);\n' +
            '  }\n' +
            '}'
    },
    js: {
        code: 'const button = document.getElementById(\'button\');\n' +
            '\n' +
            'let isActive = false;\n' +
            'let intervalId;\n' +
            '\n' +
            'button.addEventListener(\'click\', () => {\n' +
            '  isActive = !isActive;\n' +
            '  if (isActive) {\n' +
            '    button.classList.add(\'active\');\n' +
            '    intervalId = setInterval(createObject, 10);\n' +
            '  } else {\n' +
            '    button.classList.remove(\'active\');\n' +
            '    clearInterval(intervalId);\n' +
            '  }\n' +
            '});\n' +
            '\n' +
            'function createObject() {\n' +
            '  const object = document.createElement(\'div\');\n' +
            '  object.classList.add(\'object\');\n' +
            '  object.style.left = Math.random() * 100 + \'vw\';\n' +
            '  object.style.animationDuration = Math.random() * 2 + 3 + \'s\';\n' +
            '  object.innerText = \'|\';\n' +
            '  document.body.appendChild(object);\n' +
            '  setTimeout(() => {\n' +
            '    object.remove();\n' +
            '  }, 5000);\n' +
            '}\n'
    },
    guide: {
        title: 'Guide Falling objects',
        htmlParagraph: <span> The HTML structure is really <b>simple</b> here. It consist only of one button </span>,
        cssParagraph: <span> Set up the appearance and <b>animation</b>
            of the falling objects and the button. Ensure that the button and
            objects are styled properly and respond to user interactions</span>,
        jsParagraph: <span>Use document.getElementById() to access the
            "Rain" button, set up variables to <b>track</b> button activity and object creation intervals.
            Attach a click <b>event listener</b> to the button that toggles button activity, adds/removes
            classes, and controls the interval to create falling objects. Define the createObject
            function that <b>generates "div"</b> elements with classes and styles for falling objects, <b>adding </b>
                them to the webpage and <b>removing</b> them after a delay </span>,
        lastComment: <span><i>It is simple as that</i> <span style={{
            color: accentColor,
            fontSize: text4,
            marginLeft: '25px',
            transform: 'rotate(90deg) translateX(4px)',
            display: 'inline-block',
            letterSpacing: '0.2rem'
        }}>:)</span></span>
    }
}

export const HoverData = {
    name: 'Hover',
    demoData: {
        title: 'Hover',
        comment: 'Try out the project here',
        leftHeight:
            '11vh',
        appDemo: <Hover/>,
        descriptionArray:
            [
                'The project features an eye that follows the movement of your cursor. The eye\'s design is simple yet captivating, adding a touch of curiosity to the screen',
                'Functionally, the eye\'s gaze is always aligned with your cursor\'s position, creating a dynamic and engaging effect that draws attention and feels interactive',
                'The project finds its place on websites, animating characters to mirror cursor movements, or enlivening user experiences by integrating interactive guides']
    },
    html: {
        code: '<!doctype html>\n' +
            '<html lang="en">\n' +
            '\n' +
            '  <head>\n' +

            '    <meta name="viewport" content="width=device-width, initial-scale=1.0">\n' +
            '    <link rel="stylesheet" href="style.css">\n' +
            '    <title>Hover</title>\n' +
            '  </head>\n' +
            '\n' +
            '  <body>\n' +
            '    <div class=\'eye\'>\n' +
            '      <div class=\'pupil\'></div>\n' +
            '    </div>\n' +
            '    <script src="script.js"></script>\n' +
            '  </body>\n' +
            '\n' +
            '</html>\n'
    },
    css: {
        code: '* {\n' +
            '  box-sizing: border-box;\n' +
            '  font-family: Arial, sans-serif;\n' +
            '  margin: 0;\n' +
            '  padding: 0;\n' +
            '  color: #1d1d1d;\n' +
            '}\n' +
            '\n' +
            'body {\n' +
            '  display: flex;\n' +
            '  justify-content: center;\n' +
            '  align-items: center;\n' +
            '  height: 100vh;\n' +
            '}\n' +
            '\n' +
            '.eye {\n' +
            '  overflow: hidden;\n' +
            '  width: 150px;\n' +
            '  height: 150px;\n' +
            '  background: transparent;\n' +
            '  border: 2px solid mediumslateblue;\n' +
            '  border-radius: 50%;\n' +
            '  position: relative;\n' +
            '}\n' +
            '\n' +
            '.pupil {\n' +
            '  position: absolute;\n' +
            '  top: 50%;\n' +
            '  left: 50%;\n' +
            '  transform: translateX(-50%) translateY(-50%);\n' +
            '  width: 50px;\n' +
            '  height: 50px;\n' +
            '  background: mediumslateblue;\n' +
            '  border-radius: 50%;\n' +
            '  transition: 0.2s ease;\n' +
            '}\n' +
            '\n' +
            '.pupil::after {\n' +
            '  position: absolute;\n' +
            '  content: \' \';\n' +
            '  background: white;\n' +
            '  top: 50%;\n' +
            '  left: 50%;\n' +
            '  transform: translateX(-50%) translateY(-50%);\n' +
            '  width: 10px;\n' +
            '  height: 10px;\n' +
            '  border-radius: 50%;\n' +
            '}\n'
    },
    js: {
        code: 'const eye = document.querySelector(\'.eye\');\n' +
            'const pupil = document.querySelector(\'.pupil\');\n' +
            'const pupilSize = pupil.getBoundingClientRect().width;\n' +
            '\n' +
            'document.body.addEventListener(\'mousemove\', (e) => {\n' +
            '  const eyeRect = eye.getBoundingClientRect();\n' +
            '\n' +
            '  let pupilX = e.clientX - eyeRect.left;\n' +
            '  let pupilY = e.clientY - eyeRect.top;\n' +
            '\n' +
            '  pupilX = Math.max(pupilSize / 2, Math.min(pupilX, eyeRect.width - pupilSize / 2));\n' +
            '  pupilY = Math.max(pupilSize / 2, Math.min(pupilY, eyeRect.height - pupilSize / 2));\n' +
            '\n' +
            '  pupil.style.left = `${pupilX}px`;\n' +
            '  pupil.style.top = `${pupilY}px`;\n' +
            '});'
    },
    guide: {
        title: 'Guide Hover',
        htmlParagraph: <span>Create <b>basic</b> HTML structure and put your content within the "body" element</span>,
        cssParagraph:
            <span> Style the eye and pupil elements, creating the appearance of an eye that responds to hover</span>,
        jsParagraph: <span>Selects the "eye" and "pupil" elements using their respective classes.
            <b>Calculate</b> the position of the mouse cursor relative to the eye element and update the position of the
            pupil element accordingly (change the style of the element). This creates the illusion that the pet's eye is following the cursor as it moves
            across the screen<br/> <br/>
            Let's look into the details of X positioning. When the cursor's <b>X</b> position is <b>to the left</b> of the eye element,
            the first argument in the function,
            which is <b>pupilSize / 2</b>, is received (Using pupilSize / 2 as the minimum position allows the pupil to <b>remain visible</b> within the
            confines of the eye, even when the cursor is near the edge of the eye).<br/><br/> However, when the cursor's X position is <b>within</b> the boundaries of
            the eyeRect (the eye element), the actual cursor position relative to the eyeRect is obtained and assigned
            to <b>pupilX</b>. <br/><br/>If the cursor's X position is <b>to the right</b> of the eyeRect, outside its boundaries, the value needs to be set to the furthest
            allowable position, which is <b>eyeRect.width - pupilSize / 2</b>. This logic ensures that the pupil's movement
            is appropriately constrained within the limits of the eye element, producing the desired hover effect
        </span>,
        lastComment: <span><i>It is simple as that</i> <span style={{
            color: accentColor,
            fontSize: text4,
            marginLeft: '25px',
            transform: 'rotate(90deg) translateX(4px)',
            display: 'inline-block',
            letterSpacing: '0.2rem'
        }}>:)</span></span>
    }
}

export const MapData = {
    name: 'Map',
    demoData: {
        title: 'Map',
        comment: 'Try out the project here',
        leftHeight:
            '11vh',
        appDemo: <Map/>,
        descriptionArray:
            [
                'The project presents an attractive map interface designed for easy navigation, featuring a clean layout that\'s visually engaging',
                'Users can smoothly explore the map through intuitive drag-and-move camera controls, and the project also offers a zoom function for adjusting the map\'s scale with mouse scrolling',
                'This project finds utility in various web applications, such as creating interactive store layouts for e-commerce, enhancing geography learning in education, offering virtual property tours in real estate, and showcasing destinations with detailed zoom on travel websites']
    },
    html: {
        code: '<!doctype html>\n' +
            '<html lang="en">\n' +
            '\n' +
            '  <head>\n' +
            '    <meta name="viewport" content="width=device-width, initial-scale=1.0">\n' +
            '    <link rel="stylesheet" href="css/main.css">\n' +
            '    <title>Map</title>\n' +
            '  </head>\n' +
            '\n' +
            '  <body>\n' +
            '    <div class="container">\n' +
            '      <div class="elemsWrap">\n' +
            '        <div class="introduction">\n' +
            '          <h1>\n' +
            '            Map pet-project\n' +
            '          </h1>\n' +
            '          <ul>\n' +
            '            <li>\n' +
            '              <p>\n' +
            '                Hold and move your mouse to move the camera\n' +
            '              </p>\n' +
            '            </li>\n' +
            '            <li>\n' +
            '              <p>\n' +
            '                Scroll to change scale\n' +
            '              </p>\n' +
            '            </li>\n' +
            '          </ul>\n' +
            '        </div>\n' +
            '        <div class="listWrap">\n' +
            '          <h2>\n' +
            '            Street\n' +
            '          </h2>\n' +
            '          <ul>\n' +
            '            <li style="top:-100px;left:-100px;">\n' +
            '              <p>\n' +
            '                1\n' +
            '              </p>\n' +
            '            </li>\n' +
            '            <li style="top:-100px; right:-100px;">\n' +
            '              <p>\n' +
            '                2\n' +
            '              </p>\n' +
            '            </li>\n' +
            '            <li style="top:40px; left:50%;">\n' +
            '              <p>\n' +
            '                3\n' +
            '              </p>\n' +
            '            </li>\n' +
            '          </ul>\n' +
            '        </div>\n' +
            '        <div class="square">School</div>\n' +
            '      </div>\n' +
            '    </div>\n' +
            '    <script src="js/main.js"></script>\n' +
            '  </body>\n' +
            '\n' +
            '</html>\n'
    },
    css: {
        code: '* {\n' +
            '  box-sizing: border-box;\n' +
            '  font-family: arial, sans-serif;\n' +
            '  margin: 0;\n' +
            '  padding: 0;\n' +
            '  color: mediumslateblue;\n' +
            '}\n' +
            '\n' +
            'ul {\n' +
            '  list-style: none;\n' +
            '}\n' +
            '\n' +
            '.container {\n' +
            '  background-color: #151515;\n' +
            '  position: relative;\n' +
            '  width: 100vw;\n' +
            '  height: 100vh;\n' +
            '  overflow: auto;\n' +
            '  white-space: nowrap;\n' +
            '\n' +
            '}\n' +
            '\n' +
            '.container::-webkit-scrollbar {\n' +
            '  display: none;\n' +
            '}\n' +
            '\n' +
            '.elemsWrap {\n' +
            '  position: absolute;\n' +
            '  min-width: 200vw;\n' +
            '  min-height: 200vh;\n' +
            '  display: flex;\n' +
            '  justify-content: center;\n' +
            '  align-items: center;\n' +
            '  user-select: none;\n' +
            '}\n' +
            '\n' +
            '.elemsWrap .introduction {\n' +
            '  position: absolute;\n' +
            '  top: 0;\n' +
            '  left: 5%;\n' +
            '  height: 80vh;\n' +
            '  display: flex;\n' +
            '  flex-direction: column;\n' +
            '  justify-content: space-around;\n' +
            '}\n' +
            '\n' +
            '.elemsWrap .introduction ul li::marker {\n' +
            '  font-size: 2rem;\n' +
            '}\n' +
            '\n' +
            '.elemsWrap .introduction h1 {\n' +
            '  font-size: 5rem;\n' +
            '}\n' +
            '\n' +
            '.elemsWrap .introduction p {\n' +
            '  font-size: 3rem;\n' +
            '  margin-bottom: 50px;\n' +
            '}\n' +
            '\n' +
            '.elemsWrap .listWrap {\n' +
            '  border: 1px solid #151515;\n' +
            '  padding: 10px 25px;\n' +
            '}\n' +
            '\n' +
            '.elemsWrap .listWrap h2 {\n' +
            '  font-size: 3rem;\n' +
            '}\n' +
            '\n' +
            '.elemsWrap .listWrap ul {\n' +
            '  position: relative;\n' +
            '}\n' +
            '\n' +
            '.elemsWrap .listWrap ul li {\n' +
            '  position: absolute;\n' +
            '}\n' +
            '\n' +
            '.elemsWrap .listWrap ul li p {\n' +
            '  color: mediumslateblue;\n' +
            '  font-size: 2rem;\n' +
            '}\n' +
            '\n' +
            '.elemsWrap .square {\n' +
            '  display: flex;\n' +
            '  justify-content: center;\n' +
            '  align-items: center;\n' +
            '  position: absolute;\n' +
            '  margin: 20px;\n' +
            '  height: 100px;\n' +
            '  width: 100px;\n' +
            '  bottom: 30%;\n' +
            '  left: 60%;\n' +
            '  border: 1px solid mediumslateblue;\n' +
            '  font-size: 1.5rem;\n' +
            '}\n'
    },
    js: {
        code: 'function handleDragAndMoveCamera() {\n' +
            '  const container = document.querySelector(\'.container\');\n' +
            '  let left = 0;\n' +
            '  let top = 0;\n' +
            '  let drag = false;\n' +
            '  let move = false;\n' +
            '  let coorX = 0;\n' +
            '  let coorY = 0;\n' +
            '  const speed = 1;\n' +
            '\n' +
            '  container.addEventListener(\'mousedown\', (e) => {\n' +
            '    drag = true;\n' +
            '    coorX = e.pageX - container.offsetLeft;\n' +
            '    coorY = e.pageY - container.offsetTop;\n' +
            '  });\n' +
            '\n' +
            '  container.addEventListener(\'mouseup\', () => {\n' +
            '    drag = false;\n' +
            '    move = false;\n' +
            '    left = container.scrollLeft;\n' +
            '    top = container.scrollTop;\n' +
            '  });\n' +
            '\n' +
            '  container.addEventListener(\'mousemove\', (e) => {\n' +
            '    if (drag) {\n' +
            '      move = true;\n' +
            '      container.scrollLeft = left - (e.pageX - container.offsetLeft - coorX) * speed;\n' +
            '      container.scrollTop = top - (e.pageY - container.offsetTop - coorY) * speed;\n' +
            '    }\n' +
            '  });\n' +
            '\n' +
            '  container.onmouseleave = () => {\n' +
            '    move = false;\n' +
            '    drag = false;\n' +
            '    left = container.scrollLeft;\n' +
            '    top = container.scrollTop;\n' +
            '  };\n' +
            '}\n' +
            '\n' +
            'function handleZoom() {\n' +
            '  let scaleValue = 10;\n' +
            '  const elemsWrap = document.querySelector(\'.elemsWrap\');\n' +
            '\n' +
            '  function scrollUp() {\n' +
            '    if (scaleValue < 10) {\n' +
            '      scaleValue++;\n' +
            '      elemsWrap.style.transform = `scale(${scaleValue / 10})`;\n' +
            '    }\n' +
            '  }\n' +
            '\n' +
            '  function scrollDown() {\n' +
            '    if (scaleValue > 6) {\n' +
            '      scaleValue--;\n' +
            '      elemsWrap.style.transform = `scale(${scaleValue / 10})`;\n' +
            '    }\n' +
            '  }\n' +
            '\n' +
            '  // Scroll navigation\n' +
            '  if (\'onwheel\' in document) {\n' +
            '    window.addEventListener("wheel", onWheel, {\n' +
            '      passive: false\n' +
            '    });\n' +
            '  } \n' +
            '\n' +
            '  function onWheel(e) {\n' +
            '    e = e || window.event;\n' +
            '    const delta = e.deltaY || e.detail || e.wheelDelta;\n' +
            '    if (delta > 0) {\n' +
            '      scrollDown();\n' +
            '    } else {\n' +
            '      scrollUp();\n' +
            '    }\n' +
            '    e.preventDefault ? e.preventDefault() : (e.returnValue = false);\n' +
            '  }\n' +
            '}\n' +
            '\n' +
            '// Function to initialize the app\n' +
            'function initApp() {\n' +
            '  handleDragAndMoveCamera();\n' +
            '  handleZoom();\n' +
            '}\n' +
            '\n' +
            'initApp();\n'
    },
    guide: {
        title: 'Guide Map',
        htmlParagraph: <span>In the body, you have a main <b>container</b> with class "container"
            that holds <b>sections</b>: "introduction" with an "h1" heading and a list, "listWrap"
            with an "h2" heading and a list, and a "div" with class "square" representing a school</span>,
        cssParagraph: <span> Style the "container" with dimensions, and <b>hide the scrollbar</b> . Customize "elemsWrap",
            "introduction", and "listWrap" styles, along with the "square" class. Remember to set <b>"user-select"</b> of elements to none </span>,
        jsParagraph: <span>To code the JavaScript part, create <b>two main functions</b>: one for handling drag and move camera
            functionality and another for handling zoom.<br/><br/> Within the drag and move camera function, select the ".container"
            element and add <b>4 event listeners</b> for "mousedown", "mouseup","mousemove" and "mouseleave" events. Use variables to track mouse
            coordinates, scrolling values, and dragging status. <br/><br/> In the zoom function, set an initial scale value and select the
            ".elemsWrap" element. <b>Define functions</b> to handle scroll-up and scroll-down actions, adjusting the <b>scale value</b> and applying
            the transformation to ".elemsWrap". Implement scroll navigation using event listeners for <b>wheel events</b>. Finally, create an
            <b> initialization function</b> that calls both the drag and move camera and zoom functions. Invoke the initialization function to
            start the app. <br/> <br/>
            The <b>"passive: false"</b> part in the code tells the computer that we're doing something <b>special</b> with this <b>scroll</b> action.
            Normally, when you scroll, the webpage just moves up and down smoothly. But in this case, we want to do something
            extra while scrolling, like zooming in or out. So, by saying passive: false, we're letting the computer know that
            we might <b>change how scrolling works</b>, and it needs to be ready for that<br/><br/>
            There are many details in the code so feel free to change certain part for <b>practical understanding</b>
            </span>,
        lastComment: <span><i>It is simple as that</i> <span style={{
            color: accentColor,
            fontSize: text4,
            marginLeft: '25px',
            transform: 'rotate(90deg) translateX(4px)',
            display: 'inline-block',
            letterSpacing: '0.2rem'
        }}>:)</span></span>
    }
}

// export const PaintData = {
//     name: 'Map',
//     demoData: {
//         title: 'Map',
//         comment: 'Try out the project here',
//         leftHeight:
//             '11vh',
//         appDemo: <Paint/>,
//         descriptionArray:
//             [
//                 '',
//                 '',
//                 '']
//     },
//     html: {
//         code: '<!doctype html>\n' +
//             '<html lang="en">\n' +
//             '\n' +
//             '  <head>\n' +
//             
//             '    <meta name="viewport" content="width=device-width,initial-scale=1.0">\n' +
//             '    <link rel="stylesheet" href="styles/main.css">\n' +
//             '    <title>Paint</title>\n' +
//             '  </head>\n' +
//             '\n' +
//             '  <body>\n' +
//             '    <canvas id="canvas">\n' +
//             '      Your browser is not supported...\n' +
//             '    </canvas>\n' +
//             '    <button class="btn" id="btnClear">\n' +
//             '      Clear\n' +
//             '    </button>\n' +
//             '    <script src="js/main.js"></script>\n' +
//             '  </body>\n' +
//             '\n' +
//             '</html>\n'
//     },
//     css: {
//         code: '*{\n' +
//             '    box-sizing: border-box;\n' +
//             '    background-color: #151515;\n' +
//             '}\n' +
//             '\n' +
//             'body{\n' +
//             '    margin: 0;\n' +
//             '}\n' +
//             '\n' +
//             'canvas{\n' +
//             '    display: block;\n' +
//             '}\n' +
//             '\n' +
//             '.btn{\n' +
//             '    width: 100px;\n' +
//             '    height: 50px;\n' +
//             '    color:#fff;\n' +
//             '    position: absolute;\n' +
//             '    background-color:mediumslateblue;\n' +
//             '    border:0;\n' +
//             '    top: 10px;\n' +
//             '    left: 10px;\n' +
//             '}\n' +
//             '\n' +
//             '\n' +
//             '.btn:focus{\n' +
//             '    border: 0;\n' +
//             '    outline: 0;\n' +
//             '}\n' +
//             '\n' +
//             '.btn:active{\n' +
//             '    transform: translate(1px,1px);\n' +
//             '}'
//     },
//     js:{
//         code:'let isMouseDown = false;\n' +
//             'let canvas = document.getElementById(\'canvas\');\n' +
//             'let context = canvas.getContext(\'2d\'); //two-dimensional graphics\n' +
//             'canvas.width = window.innerWidth;\n' +
//             'canvas.height = window.innerHeight;\n' +
//             '\n' +
//             'function clear() {\n' +
//             '  context.fillStyle = \'#151515\';\n' +
//             '  context.fillRect(0, 0, canvas.width, canvas.height);\n' +
//             '  context.fillStyle = \'white\';\n' +
//             '\n' +
//             '}\n' +
//             '\n' +
//             'function onMouseDown(e) {\n' +
//             '  isMouseDown = true;\n' +
//             '\n' +
//             '  context.arc(e.clientX, e.clientY, 10, 0, Math.PI * 2);\n' +
//             '  context.fill();\n' +
//             '  context.beginPath();\n' +
//             '\n' +
//             '}\n' +
//             '\n' +
//             'function onMouseMove(e) {\n' +
//             '  if (isMouseDown) {\n' +
//             '    context.lineTo(e.clientX, e.clientY);\n' +
//             '  \n' +
//             '    context.stroke();\n' +
//             '\n' +
//             '    context.beginPath();\n' +
//             '    context.arc(e.clientX, e.clientY, 10, 0, Math.PI * 2);\n' +
//             '    context.fill();\n' +
//             '\n' +
//             '    context.beginPath();\n' +
//             '    context.moveTo(e.clientX, e.clientY);\n' +
//             '  }\n' +
//             '}\n' +
//             '\n' +
//             'function onUp(e) {\n' +
//             '  isMouseDown = false;\n' +
//             '  context.beginPath();\n' +
//             '\n' +
//             '}\n' +
//             '\n' +
//             'context.fillStyle = \'white\';\n' +
//             'context.strokeStyle = \'white\';\n' +
//             'context.lineWidth = 20;\n' +
//             '\n' +
//             'let btnClear = document.getElementById(\'btnClear\');\n' +
//             'btnClear.addEventListener(\'click\', () => {\n' +
//             '  \n' +
//             '    clear();\n' +
//             '  \n' +
//             '});\n' +
//             '\n' +
//             'canvas.onmousedown = (e) => {\n' +
//             '  onMouseDown(e);\n' +
//             '};\n' +
//             'canvas.onmousemove = (e) => {\n' +
//             '  onMouseMove(e);\n' +
//             '};\n' +
//             'canvas.onmouseup = (e) => {\n' +
//             '  onUp(e);\n' +
//             '};\n' +
//             'canvas.onmouseout = () => {\n' +
//             '  isMouseDown = false;\n' +
//             '};'
//     },
//     guide: {
//         title: 'Guide Hover',
//         htmlParagraph: <span>The HTML structure consists of a basic
//             webpage with a title "Loader." Inside the <b>body</b>, there is
//             a div element with the class "wrap" which contains a loader
//             section. The loader section has a
//             <b> set of rotating dots </b> represented by the <b>empty divs</b>. Additionally,
//             there is an <b>h3</b> element with the class "title" displaying the text "Loading"
//             within the loader section </span>,
//         cssParagraph: <span> The CSS structure defines a <b>loader animation</b> called "ldsRoller"
//             using the @keyframes rule, which <b>rotates</b> the dots 360 degrees from 0% to 100% of
//             the animation duration. Each dot element is styled with the "ldsRoller" class, and
//             the animation property applies the animation to each dot with a  <b>specified delay</b>, creating
//             a staggered effect. This approach simplifies the process for programmers as they <b>don't</b> need
//             to <b>manually position</b> each dot, but instead focus on understanding how animations work and can
//             <b> easily</b> modify and customize the animation as needed</span>,
//         lastComment: <span><i>It is simple as that</i> <span style={{
//             color: accentColor,
//             fontSize: text4,
//             marginLeft: '25px',
//             transform: 'rotate(90deg) translateX(4px)',
//             display: 'inline-block',
//             letterSpacing: '0.2rem'
//         }}>:)</span></span>
//     }
// }

export const PopupData = {
    name: 'Popup',
    demoData: {
        title: 'Popup',
        comment: 'Try out the project here',
        leftHeight:
            '11vh',
        appDemo: <Popup/>,
        descriptionArray:
            [
                'The project boasts elegantly designed popups that seamlessly fit with the website\'s visual identity, drawing immediate attention',
                'These user-friendly popups show up every time the button is clicked, giving clear messages and options',
                'In different websites, these popups are useful—displaying discounts in online shops, announcing important news, or collecting opinions on news sites. They work well for many online needs']
    },
    html: {
        code: '<!doctype html>\n' +
            '<html lang="en">\n' +
            '\n' +
            '  <head>\n' +
            '    <meta name="viewport" content="width=device-width, initial-scale=1.0">\n' +
            '    <link rel="stylesheet" href="style.css">\n' +
            '    <title>Popup</title>\n' +
            '  </head>\n' +
            '\n' +
            '  <body>\n' +
            '    <button class="open-button">\n' +
            '      Open\n' +
            '    </button>\n' +
            '    <div class="popup-container">\n' +
            '      <div class="popup">\n' +
            '        <button class="close-button">\n' +
            '          x\n' +
            '        </button>\n' +
            '        <h2>\n' +
            '          This is popup!\n' +
            '        </h2>\n' +
            '        <p>\n' +
            '          It was pretty easy...\n' +
            '        </p>\n' +
            '      </div>\n' +
            '    </div>\n' +
            '    <script src="script.js"></script>\n' +
            '  </body>\n' +
            '\n' +
            '</html>\n'
    },
    css: {
        code: '* {\n' +
            '  box-sizing: border-box;\n' +
            '  font-family: Arial, sans-serif;\n' +
            '  margin: 0;\n' +
            '  padding: 0;\n' +
            '}\n' +
            '\n' +
            'body {\n' +
            '  display: flex;\n' +
            '  align-items: center;\n' +
            '  justify-content: center;\n' +
            '  min-height: 100vh;\n' +
            '  background-color: #1d1d1d;\n' +
            '}\n' +
            '\n' +
            'button {\n' +
            '  cursor: pointer;\n' +
            '  transition: 0.3s ease;\n' +
            '}\n' +
            '\n' +
            '.open-button {\n' +
            '  padding: 1.6rem 3.1rem;\n' +
            '  background-color: mediumslateblue;\n' +
            '  color: #fff;\n' +
            '  border-radius: 8px;\n' +
            '  border: none;\n' +
            '  font-size: 1.4rem;\n' +
            '}\n' +
            '\n' +
            '.popup-container {\n' +
            '  position: fixed;\n' +
            '  top: 0;\n' +
            '  left: 0;\n' +
            '  right: 0;\n' +
            '  bottom: 0;\n' +
            '  background-color: mediumslateblue;\n' +
            '  display: none;\n' +
            '}\n' +
            '\n' +
            '.popup-container.active {\n' +
            '  display: flex;\n' +
            '  align-items: center;\n' +
            '  justify-content: center;\n' +
            '}\n' +
            '\n' +
            '.popup {\n' +
            '  position: relative;\n' +
            '  background-color: #1d1d1d;\n' +
            '  color: #fff;\n' +
            '  width: 500px;\n' +
            '  border-radius: 5px;\n' +
            '  box-shadow: 0 0 10px 3px rgba(0, 0, 0, .5);\n' +
            '  padding: 40px;\n' +
            '}\n' +
            '\n' +
            '.close-button {\n' +
            '  padding: .3rem .7rem;\n' +
            '  position: absolute;\n' +
            '  top: 8px;\n' +
            '  right: 8px;\n' +
            '  background-color: transparent;\n' +
            '  border: none;\n' +
            '  font-size: 1.3rem;\n' +
            '  color: #fff;\n' +
            '}\n'
    },
    js: {
        code: 'const open = document.querySelector(\'.open-button\');\n' +
            'const container = document.querySelector(\'.popup-container\');\n' +
            'const close = document.querySelector(\'.close-button\');\n' +
            'const popup = document.querySelector(\'.popup\');\n' +
            '\n' +
            'open.addEventListener(\'click\', () => {\n' +
            '  container.classList.add(\'active\');\n' +
            '});\n' +
            '\n' +
            'close.addEventListener(\'click\', () => {\n' +
            '  container.classList.remove(\'active\');\n' +
            '});\n' +
            '\n' +
            'container.addEventListener(\'click\', e => {\n' +
            '  e.stopPropagation();\n' +
            '  if (e.target === container && e.target !== popup) {\n' +
            '    container.classList.remove(\'active\');\n' +
            '  }\n' +
            '});\n'
    },
    guide: {
        title: 'Guide Popup',
        htmlParagraph: <span>Create <b>basic</b> HTML structure.Inside the body of the document, there is a popup. The popup itself is contained within a
            div with the class <b>"popup-container"</b> and its content is within a nested div with the class "popup" </span>,
        cssParagraph: <span>CSS part is simple here. Style the button, popup container, and content. Ensure that the "popup-container"
            element completely <b>covers</b> the user's browser area </span>,
        jsParagraph: <span> Use <b>event listeners</b> to detect when the open button is clicked and add
            the class <b>"active"</b> to the popup container, making the popup visible.Listen
            for clicks on the close button and the popup container itself. When the close button is clicked,
            the "active" class is removed, hiding the popup. Clicking <b>outside the popup</b>  container area also
            closes the popup. This JavaScript code makes your popup functional and user-friendly</span>,
        lastComment: <span><i>It is simple as that</i> <span style={{
            color: accentColor,
            fontSize: text4,
            marginLeft: '25px',
            transform: 'rotate(90deg) translateX(4px)',
            display: 'inline-block',
            letterSpacing: '0.2rem'
        }}>:)</span></span>
    }
}

export const ScrollingProgressData = {
    name: 'Scrolling progress',
    demoData: {
        title: 'Scrolling progress',
        comment: 'Try out the project here',
        leftHeight:
            '11vh',
        appDemo: <ScrollingProgress/>,
        descriptionArray:
            [
                'Including a scrolling progress indicator into your website sets it apart from others. This unique feature adds an engaging element that captivates users and enhances their browsing experience',
                'Functionally, the project employs JavaScript to dynamically fill the progress bar as users scroll through the content, offering real-time feedback and enhancing the user experience',
                'This project finds practical application in web scenarios such as e-learning modules where learners can track their progress, long articles to estimate reading progress, and e-commerce checkouts to indicate the completion status.']
    },
    html: {
        code: '<!doctype html>\n' +
            '<html lang="en">\n' +
            '\n' +
            '  <head>\n' +

            '    <meta name="viewport" content="width=device-width, initial-scale=1.0">\n' +
            '    <link rel="stylesheet" href="style.css">\n' +
            '    <title>Scrolling progress</title>\n' +
            '  </head>\n' +
            '\n' +
            '  <body>\n' +
            '    <div class="container">\n' +
            '      <div class="progressBar">\n' +
            '      </div>\n' +
            '      <section>1/4</section>\n' +
            '      <section>2/4</section>\n' +
            '      <section>3/4</section>\n' +
            '      <section>4/4</section>\n' +
            '    </div>\n' +
            '  </body>\n' +
            '\n' +
            '</html>\n'
    },
    css: {
        code: ' * {\n' +
            '  box-sizing: border-box;\n' +
            '  font-family: Arial, sans-serif;\n' +
            '  margin: 0;\n' +
            '  padding: 0;\n' +
            '  color: #1d1d1d;\n' +
            '}\n' +
            '\n' +
            'body {\n' +
            '  height: 100vh;\n' +
            '}\n' +
            '\n' +
            '.container {\n' +
            '  width: 100%;\n' +
            '  overflow: auto;\n' +
            '  height: 100%;\n' +
            '}\n' +
            '\n' +
            '.progressBar {\n' +
            '  position: absolute;\n' +
            '  top: 0;\n' +
            '  right: 0;\n' +
            '  background: mediumslateblue;\n' +
            '  width: 10px;\n' +
            '}\n' +
            '\n' +
            'section {\n' +
            '  display: flex;\n' +
            '  justify-content: center;\n' +
            '  align-items: center;\n' +
            '  font-size: 30px;\n' +
            '  width: 100%;\n' +
            '  height: 75vh\n' +
            '}\n' +
            '\n' +
            'section:nth-child(odd) {\n' +
            '  background: #1d1d1d;\n' +
            '  color: white;\n' +
            '}\n' +
            '\n' +
            '::-webkit-scrollbar {\n' +
            '  display: none;\n' +
            '}'
    },
    js: {
        code: 'const container = document.querySelector(\'.container\');\n' +
            'const progressBar = document.querySelector(\'.progressBar\');\n' +
            '\n' +
            'container.addEventListener("scroll", onScroll);\n' +
            '\n' +
            'function onScroll() {\n' +
            '  const currentScrolled = container.scrollTop;\n' +
            '  const restHeight = container.scrollHeight - container.clientHeight;\n' +
            '  const scrolled = (currentScrolled / restHeight) * 100;\n' +
            '  progressBar.style.height = `${scrolled}%`;\n' +
            '}'
    },
    guide: {
        title: 'Guide Scrolling progress',
        htmlParagraph: <span>Set up <b>basic</b> structure, including sections for different parts of the content and a div
            with the class "progressBar" for the scrolling progress indicator</span>,
        cssParagraph: <span> Style the layout and appearance of elements. Include settings for the container, progress bar,
            and sections, ensuring that they are visually appealing</span>,
        jsParagraph: <span><b>Select</b> the container and progress bar
            elements using document.querySelector '.container' and document.querySelector('.progressBar'). Attach a <b>"scroll"
            event listener</b> to the container element. Inside this listener, calculate container.scrollTop to determine how much
            the user <b>has scrolled</b>. Calculate the <b>remaining scrollable area</b> by subtracting container.clientHeight from container.scrollHeight.
            Compute the scrolling progress by dividing the scrolled distance by the remaining scrollable height and multiplying by 100. Adjust
            the progress bar's height using <b>progressBar.style.height </b>to visually indicate the user's scrolling progress </span>,
        lastComment: <span><i>It is simple as that</i> <span style={{
            color: accentColor,
            fontSize: text4,
            marginLeft: '25px',
            transform: 'rotate(90deg) translateX(4px)',
            display: 'inline-block',
            letterSpacing: '0.2rem'
        }}>:)</span></span>
    }
}

export const SliderData = {
    name: 'Slider',
    demoData: {
        title: 'Slider',
        comment: 'Try out the project here',
        leftHeight:
            '11vh',
        appDemo: <Slider/>,
        descriptionArray:
            [
                'The slider pet-project has a sleek, modern look that seamlessly fits various website styles, ensuring a user-friendly experience',
                'The slider enables smooth navigation through images or content sections, offering interactive and engaging switching between slides',
                'This project suits various web scenarios, from e-commerce product highlights to creative portfolio showcases and breaking news presentations. Its versatility captivates users with dynamic content']
    },
    html: {
        code: '<!doctype html>\n' +
            '<html lang="en">\n' +
            '\n' +
            '  <head>\n' +
            '    <meta name="viewport" content="width=device-width, initial-scale=1.0">\n' +
            '    <link rel="stylesheet" href="style.css">\n' +
            '    <title>Slider</title>\n' +
            '  </head>\n' +
            '\n' +
            '  <body>\n' +
            '    <button id="prevBtn">&lt;</button>\n' +
            '    <div class="slider-container">\n' +
            '      <div class="slide">\n' +
            '        <img src="slide2.jpg" alt="Slide 2">\n' +
            '        <h3 class="name">Miley</h3>\n' +
            '        <p class="position">Engineer</p>\n' +
            '      </div>\n' +
            '      <div class="slide">\n' +
            '        <img src="slide3.jpg" alt="Slide 3">\n' +
            '        <h3 class="name">Tom</h3>\n' +
            '        <p class="position">SEO</p>\n' +
            '      </div>\n' +
            '      <div class="slide active-slide">\n' +
            '        <img src="slide1.jpg" alt="Slide 1">\n' +
            '        <h3 class="name">Adam</h3>\n' +
            '        <p class="position">Engineer</p>\n' +
            '      </div>\n' +
            '    </div>\n' +
            '    <button id="nextBtn">&gt;</button>\n' +
            '  </body>\n' +
            '\n' +
            '</html>\n'
    },
    css: {
        code: '  * {\n' +
            '    box-sizing: border-box;\n' +
            '    font-family: Arial, sans-serif;\n' +
            '    margin: 0;\n' +
            '    padding: 0;\n' +
            '    color: #1d1d1d;\n' +
            '  }\n' +
            '\n' +
            '  body {\n' +
            '    display: flex;\n' +
            '    justify-content: center;\n' +
            '    align-items: center;\n' +
            '    height: 100vh;\n' +
            '  }\n' +
            '\n' +
            '  button {\n' +
            '    background: #1d1d1d;\n' +
            '    color: white;\n' +
            '    font-size: 30px;\n' +
            '    border: none;\n' +
            '    width: 40px;\n' +
            '    height: 40px;\n' +
            '    text-align: center;\n' +
            '    border-radius: 8px\n' +
            '  }\n' +
            '\n' +
            '  .slider-container {\n' +
            '    width: 400px;\n' +
            '    overflow: hidden;\n' +
            '  }\n' +
            '\n' +
            '\n' +
            '  .slide {\n' +
            '    width: 400px;\n' +
            '    height: 200px;\n' +
            '    display: none;\n' +
            '  }\n' +
            '\n' +
            '  img {\n' +
            '    width: 120px;\n' +
            '    height: 120px;\n' +
            '    background: transparent;\n' +
            '    border: 2px solid #1d1d1d;\n' +
            '    border-radius: 50%;\n' +
            '    margin-bottom: 20px;\n' +
            '  }\n' +
            '\n' +
            '  .name {\n' +
            '    margin-bottom: 5px;\n' +
            '    font-size: 30px;\n' +
            '  }\n' +
            '\n' +
            '  .active-slide {\n' +
            '    display: flex;\n' +
            '    justify-content: center;\n' +
            '    flex-direction: column;\n' +
            '    align-items: center;\n' +
            '  }\n'
    },
    js: {
        code: 'const slides = document.querySelectorAll(".slide");\n' +
            'const prevBtn = document.getElementById("prevBtn");\n' +
            'const nextBtn = document.getElementById("nextBtn");\n' +
            'let currentSlideIndex = 0;\n' +
            '\n' +
            'function showSlide(index) {\n' +
            '  slides.forEach((slide, i) => {\n' +
            '    slide.classList.toggle("active-slide", i === index);\n' +
            '  });\n' +
            '}\n' +
            '\n' +
            'function goToNextSlide() {\n' +
            '  currentSlideIndex++;\n' +
            '  if (currentSlideIndex >= slides.length) {\n' +
            '    currentSlideIndex = 0;\n' +
            '  }\n' +
            '  showSlide(currentSlideIndex);\n' +
            '}\n' +
            '\n' +
            'function goToPrevSlide() {\n' +
            '  currentSlideIndex--;\n' +
            '  if (currentSlideIndex < 0) {\n' +
            '    currentSlideIndex = slides.length - 1;\n' +
            '  }\n' +
            '  showSlide(currentSlideIndex);\n' +
            '}\n' +
            '\n' +
            'showSlide(currentSlideIndex);\n' +
            '\n' +
            'prevBtn.addEventListener("click", goToPrevSlide);\n' +
            'nextBtn.addEventListener("click", goToNextSlide);\n'
    },
    guide: {
        title: 'Guide Slider',
        htmlParagraph: <span> This project contain <b>basic</b> HTML structure. Create the foundation,
            featuring navigation buttons and slide content within a container.
            Each slide includes an image, name, and position details</span>,
        cssParagraph:
            <span>Style slider's appearance, button, slide arrangement, and content display. Remember to set <b>overflow:hidden</b> to "slider-container" </span>,
        jsParagraph:
            <span>To implement the slider's functionality using JavaScript, you should start by <b>selecting</b> all
            slide elements with document.querySelectorAll(".slide") and assigning them to the slides variable. Retrieve
            the "previous" and "next" <b>button elements</b> using document.getElementById() with IDs prevBtn and nextBtn.
            Initialize <b>currentSlideIndex</b> at 0 to track the displayed slide.<br/><br/> Define showSlide(index) to <b>toggle the
            "active-slide"</b> class on the chosen slide using classList.toggle(). Create goToNextSlide() and goToPrevSlide()
            to <b>update currentSlideIndex</b>, preventing index errors. Display the <b>initial slide</b> with showSlide(currentSlideIndex)
            on page load.<br/><br/> Attach <b>"click" event</b> listeners to the previous and next buttons using prevBtn.addEventListener("click",
            goToPrevSlide) and nextBtn.addEventListener("click", goToNextSlide). This JavaScript setup enables users to navigate
            slides with buttons while maintaining slide visibility and preventing navigation errors </span>,
        lastComment: <span><i>It is simple as that</i> <span style={{
            color: accentColor,
            fontSize: text4,
            marginLeft: '25px',
            transform: 'rotate(90deg) translateX(4px)',
            display: 'inline-block',
            letterSpacing: '0.2rem'
        }}>:)</span></span>
    }
}

export const SmoothScrollData = {
    name: 'Smooth Scroll',
    demoData: {
        title: 'Smooth Scroll',
        comment: 'Try out the project here',
        leftHeight:
            '11vh',
        appDemo: <SmoothScroll/>,
        descriptionArray:
            [
                'The Smooth Scroll project exudes seamless elegance, enhancing website visuals with fluid transitions and animations for a pleasing scrolling experience',
                'This project works to make moving through a website feel smoother and more comfortable. It stops sudden jumps and helps content flow better as you scroll',
                'You can use this project in long articles so they\'re easy to read, on websites with lots of sections for smooth transitions, and on storytelling websites to guide users through stories. It fits well in different types of websites ']
    },
    html: {
        code: '<!doctype html>\n' +
            '<html lang="en">\n' +
            '\n' +
            '  <head>\n' +
            '    <meta name="viewport" content="width=device-width, initial-scale=1.0">\n' +
            '    <link rel="stylesheet" href="style.css">\n' +
            '    <title>Smooth Scroll</title>\n' +
            '  </head>\n' +
            '\n' +
            '  <body>\n' +
            '    <header>\n' +
            '      <nav>\n' +
            '        <ul class="navList">\n' +
            '          <li>\n' +
            '            <a href="#smoothScrollHome" class="scrollLink">\n' +
            '              Home\n' +
            '            </a>\n' +
            '          </li>\n' +
            '          <li>\n' +
            '            <a href="#smoothScrollAbout" class="scrollLink">\n' +
            '              About\n' +
            '            </a>\n' +
            '          </li>\n' +
            '          <li>\n' +
            '            <a href="#smoothScrollContacts" class="scrollLink">\n' +
            '              Contacts\n' +
            '            </a>\n' +
            '          </li>\n' +
            '        </ul>\n' +
            '      </nav>\n' +
            '    </header>\n' +
            '    <section id="smoothScrollHome">\n' +
            '      Home\n' +
            '    </section>\n' +
            '    <section id="smoothScrollAbout">\n' +
            '      About\n' +
            '    </section>\n' +
            '    <section id="smoothScrollContacts">\n' +
            '      Contacts\n' +
            '    </section>\n' +
            '    <script src="script.js"></script>\n' +
            '  </body>\n' +
            '\n' +
            '</html>\n'
    },
    css: {
        code: '* {\n' +
            '  box-sizing: border-box;\n' +
            '  font-family: Arial, sans-serif;\n' +
            '  margin: 0;\n' +
            '  padding: 0;\n' +
            '  color: #1d1d1d;\n' +
            '}\n' +
            '\n' +
            'ul {\n' +
            '  list-style: none;\n' +
            '}\n' +
            '\n' +
            '.navList {\n' +
            '  display: flex;\n' +
            '  width: 100%;\n' +
            '  padding: 0 10px;\n' +
            '  align-items: center;\n' +
            '  height: 48px;\n' +
            '  background: #1d1d1d;\n' +
            '}\n' +
            '\n' +
            '.scrollLink {\n' +
            '  margin: 0 6px;\n' +
            '  font-weight: 700;\n' +
            '  color: white;\n' +
            '  text-decoration: none;\n' +
            '}\n' +
            '\n' +
            'section {\n' +
            '  height: 100vh;\n' +
            '  width: 100vw;\n' +
            '  display: flex;\n' +
            '  justify-content: center;\n' +
            '  align-items: center;\n' +
            '  font-size: 30px;\n' +
            '}\n' +
            '\n' +
            'section:nth-child(odd) {\n' +
            '  background: mediumslateblue;\n' +
            '  color: white;\n' +
            '}'
    },
    js: {
        code: 'const scrollLinks = document.querySelectorAll(".scrollLink");\n' +
            'const navbar = document.querySelector("nav");\n' +
            '\n' +
            'scrollLinks.forEach(function(link) {\n' +
            '  link.addEventListener("click", handleClick);\n' +
            '});\n' +
            '\n' +
            'function handleClick(e) {\n' +
            '  e.preventDefault();\n' +
            '\n' +
            '  const id = e.currentTarget.getAttribute("href").slice(1);\n' +
            '  const element = document.getElementById(id);\n' +
            '  let position = element.offsetTop;\n' +
            '\n' +
            '  window.scrollTo({\n' +
            '    left: 0,\n' +
            '    top: position,\n' +
            '    behavior: \'smooth\'\n' +
            '  });\n' +
            '}\n'
    },
    guide: {
        title: 'Guide Smooth Scroll',
        htmlParagraph: <span>Set up the <b>basic</b> layout, include navigation
            links that are meant to smoothly scroll to different sections within the page.
            Define each section using the <b>"section"</b> element and assign an id corresponding
            to the respective navigation link </span>,
        cssParagraph: <span> <b>Set the appearance</b> of the navigation bar, navigation links, and sections, ensuring they are visually appealing and well-organized </span>,
        jsParagraph: <span> You should <b>target</b> the navigation links, attach event listeners to them, and, upon a click,
            <b> prevent</b> the <b>default</b> link <b>behavior</b>. Determine the target section based on the clicked link's <b>href attribute</b>
            and initiate smooth scrolling to that section using the <b>window.scrollTo</b> method. <br/><br/>This is particularly vital because without this
            preventive measure, clicking on a navigation link with an anchor would <b>instantly jump</b> to the designated section on the page, which disrupts
            the smooth scrolling experience you are aiming for. <br/><br/>This JavaScript implementation enhances your project by delivering a
            seamless and visually <b>satisfying scrolling experience</b> as users navigate through the page</span>,
        lastComment: <span><i>It is simple as that</i> <span style={{
            color: accentColor,
            fontSize: text4,
            marginLeft: '25px',
            transform: 'rotate(90deg) translateX(4px)',
            display: 'inline-block',
            letterSpacing: '0.2rem'
        }}>:)</span></span>
    }
}

export const TabsData = {
    name: 'Tabs',
    demoData: {
        title: 'Tabs',
        comment: 'Try out the project here',
        leftHeight:
            '11vh',
        appDemo: <Tabs/>,
        descriptionArray:
            [
                'The tabs project shows buttons to switch between content sections, each representing a title with comments',
                'Clicking a button updates content to display information about the selected topic, making it easy to organize and present details',
                'Tabs effectively structure content for specific subjects across diverse websites. Tabs prove valuable on travel websites for showcasing different locations, on fitness sites for sharing various workouts, and their applications are vast']
    },
    html: {
        code: '<!doctype html>\n' +
            '<html lang="en">\n' +
            '\n' +
            '  <head>\n' +
            '    <meta name="viewport" content="width=device-width, initial-scale=1.0">\n' +
            '    <link rel="stylesheet" href="Tabs.css">\n' +
            '    <title>Tabs</title>\n' +
            '  </head>\n' +
            '\n' +
            '  <body>\n' +
            '    <div class="wrap">\n' +
            '      <div class="container">\n' +
            '        <div class="content"></div>\n' +
            '        <div class="navbar">\n' +
            '          <ul id="tabButtons">\n' +
            '            <li><button data-tab="Cats">Cats</button></li>\n' +
            '            <li><button data-tab="Dogs">Dogs</button></li>\n' +
            '            <li><button data-tab="Cows">Cows</button></li>\n' +
            '          </ul>\n' +
            '        </div>\n' +
            '      </div>\n' +
            '    </div>\n' +
            '    <script src="Tabs.js"></script>\n' +
            '  </body>\n' +
            '\n' +
            '</html>'
    },
    css: {
        code: '* {\n' +
            '  box-sizing: border-box;\n' +
            '  font-family: Arial, sans-serif;\n' +
            '  margin: 0;\n' +
            '  padding: 0;\n' +
            '  color: #1d1d1d;\n' +
            '}\n' +
            '\n' +
            'body {\n' +
            '  display: flex;\n' +
            '  justify-content: center;\n' +
            '  align-items: center;\n' +
            '}\n' +
            '\n' +
            '.container {\n' +
            '  display: flex;\n' +
            '  width: 300px;\n' +
            '  height: 315px;\n' +
            '  border: 2px solid #1d1d1d;\n' +
            '  border-radius: 10px;\n' +
            '  position: relative;\n' +
            '  padding-right: 30px;\n' +
            '}\n' +
            '\n' +
            '.content {\n' +
            '  text-align: center;\n' +
            '  padding: 20px;\n' +
            '}\n' +
            '\n' +
            '.title {\n' +
            '  margin-bottom: 20px;\n' +
            '  font-weight: 900;\n' +
            '  font-size: 30px;\n' +
            '\n' +
            '}\n' +
            '\n' +
            '.text {\n' +
            '  line-height: 1.5rem;\n' +
            '  width: 90%;\n' +
            '  text-align: left;\n' +
            '  font-weight: 700;\n' +
            '}\n' +
            '\n' +
            '.navbar {\n' +
            '  right: 10px;\n' +
            '  bottom: -2px;\n' +
            '  position: absolute;\n' +
            '  height: 30px;\n' +
            '  background: mediumslateblue;\n' +
            '  width: 315px;\n' +
            '  transform: rotate(90deg);\n' +
            '  transform-origin: 100% 100%;\n' +
            '}\n' +
            '\n' +
            '.navbar ul {\n' +
            '  display: flex;\n' +
            '  height: 100%;\n' +
            '}\n' +
            '\n' +
            '.navbar ul li {\n' +
            '  display: flex;\n' +
            '  justify-content: center;\n' +
            '  background: transparent;\n' +
            '  border: 2px solid #1d1d1d;\n' +
            '  height: 100%;\n' +
            '  width: 100%;\n' +
            '}\n' +
            '\n' +
            '.navbar ul li:nth-child(even) {\n' +
            '  border-left: none;\n' +
            '  border-right: none;\n' +
            '}\n' +
            '\n' +
            '.navbar button {\n' +
            '  background: transparent;\n' +
            '  color: white;\n' +
            '  border: none;\n' +
            '  width: 100%;\n' +
            '  cursor: pointer;\n' +
            '}\n'
    },
    js: {
        code: 'const tabButtons = document.querySelectorAll("#tabButtons button");\n' +
            'let currentTab = \'Cats\';\n' +
            'updateUI();\n' +
            'addClickListenerToButtons(tabButtons);\n' +
            '\n' +
            '\n' +
            'function addClickListenerToButtons(buttons) {\n' +
            '  buttons.forEach(button => {\n' +
            '    const tabTitle = button.getAttribute("data-tab");\n' +
            '    button.addEventListener("click", function() {\n' +
            '      currentTab = tabTitle;\n' +
            '      updateUI();\n' +
            '    });\n' +
            '  });\n' +
            '}\n' +
            '\n' +
            'function updateUI() {\n' +
            '  const contentDiv = document.querySelector(".content");\n' +
            '  const tabData = {\n' +
            '    Cats: {\n' +
            '      title: "Cats",\n' +
            '      text: \'Cats blink slowly at you as a sign of trust and affection, a feline "I love you\'\n' +
            '    },\n' +
            '    Dogs: {\n' +
            '      title: "Dogs",\n' +
            '      text: "Dogs have unique nose prints, much like human fingerprints, making each one special"\n' +
            '    },\n' +
            '    Cows: {\n' +
            '      title: "Cows",\n' +
            '      text: "Cows have best friends and can become stressed when separated from them, showing their strong social bonds"\n' +
            '    }\n' +
            '  };\n' +
            '\n' +
            '  const tab = tabData[currentTab];\n' +
            '\n' +
            '  contentDiv.innerHTML = `\n' +
            '            <h3 class="title">${tab.title}</h3>\n' +
            '            <p class="text">${tab.text}</p>`;\n' +
            '}\n'
    },
    guide: {
        title: 'Guide Tabs',
        htmlParagraph: <span>Establish the <b>core structure</b> with navigation buttons to switch between
            content tabs. The tabs are organized within a container with associated content displayed
            in a separate section which will be <b>inserted</b> from Java Script</span>,
        cssParagraph:
            <span>Adjust the visual presentation of your tabs project, including the layout of the <b>container</b> for content,
            navigation buttons, and tab content which is in Java Script part</span>,
        jsParagraph: <span> Start by <b>selecting tab buttons</b> using document.querySelectorAll("#tabButtons button")
            and assigning them to the tabButtons variable. Initialize currentTab as 'Cats' for the <b>default tab</b>. Ensure
            the initial content matches the default tab by calling updateUI(). <br/> <br/>Implement the addClickListenerToButtons(buttons)
            function. Attach a <b>click listener</b> to each button. On a click, set currentTab and call updateUI(). <br/><br/>Inside updateUI(),
            access the <b>.content</b> element and create <b>tabData</b> object. Inside the tabData object's field place <b>any data</b> for testing.
            Retrieve tab info using <b>tabData[currentTab]</b>. Use template literals
            to create <b>dynamic HTML</b> with tab info. <b>Update</b> content with the generated HTML. <br/><br/>By following these steps, your JavaScript
            code will allow tab switching, enhancing the user experience and interaction in your tabs project</span>,
        lastComment: <span><i>It is simple as that</i> <span style={{
            color: accentColor,
            fontSize: text4,
            marginLeft: '25px',
            transform: 'rotate(90deg) translateX(4px)',
            display: 'inline-block',
            letterSpacing: '0.2rem'
        }}>:)</span></span>
    }
}

export const TextTyperData = {
    name: 'Text Typer',
    demoData: {
        title: 'Text Typer',
        comment: 'Try out the project here',
        leftHeight:
            '11vh',
        appDemo: <TextTyper/>,
        descriptionArray:
            [
                'The text typer project displays text as if being typed in real-time, creating an engaging visual',
                'It types out text character by character, animating the process and then restarting for an attention-grabbing display',
                'This suits personal websites for creative introductions, landing pages for key messages, and banners for special offers. Its interactive nature engages users effectively']
    },
    html: {
        code: '<!doctype html>\n' +
            '<html lang="en">\n' +
            '\n' +
            '  <head>\n' +
            '    <meta name="viewport" content="width=device-width, initial-scale=1.0">\n' +
            '    <link rel="stylesheet" href="style.css">\n' +
            '    <title>Text Typer</title>\n' +
            '  </head>\n' +
            '\n' +
            '  <body>\n' +
            '    <script src="script.js"></script>\n' +
            '  </body>\n' +
            '\n' +
            '</html>'
    },
    css: {
        code: '* {\n' +
            '  box-sizing: border-box;\n' +
            '  font-family: Arial, sans-serif;\n' +
            '  margin: 0;\n' +
            '  padding: 0;\n' +
            '}\n' +
            '\n' +
            'body {\n' +
            '  display: flex;\n' +
            '  align-items: center;\n' +
            '  justify-content: center;\n' +
            '  margin: 0;\n' +
            '  min-height: 100vh;\n' +
            '  background-color: #151515;\n' +
            '  color: #fff;\n' +
            '  font-size: 2rem;\n' +
            '}\n'
    },
    js: {
        code: 'const text = "You are the best!";\n' +
            'let index;\n' +
            'let interval;\n' +
            '\n' +
            'startTyper();\n' +
            '\n' +
            'function startTyper() {\n' +
            '    index = 0;\n' +
            '    interval = setInterval(writeText, 110);\n' +
            '}\n' +
            '\n' +
            'function writeText() {\n' +
            '    document.body.innerText = text.slice(0, index);\n' +
            '    index++;\n' +
            '    if (index > text.length) {\n' +
            '        clearInterval(interval);\n' +
            '        setTimeout(startTyper, 1000);\n' +
            '    }\n' +
            '}'
    },
    guide: {
        title: 'Guide Text Typer',
        htmlParagraph: <span>Create <b>basic</b> HTML structure</span>,
        cssParagraph: <span>Style the 'body' element and the <b>upcoming</b> text content </span>,
        jsParagraph: <span> Begin by defining the <b>text you want </b>to display and create variables to
            keep track of the current index and the interval. The startTyper() function <b>initializes the process</b>,
            and the writeText() function gradually writes the text on the webpage as we initialized an <b>interval </b>using <b>setInterval()</b>.
            Once the text is fully typed,the interval is cleared and the startTypewriter() function is called after a
            <b> brief pause</b> which is set by <b>setTimeout()</b></span>,
        lastComment: <span><i>It is simple as that</i> <span style={{
            color: accentColor,
            fontSize: text4,
            marginLeft: '25px',
            transform: 'rotate(90deg) translateX(4px)',
            display: 'inline-block',
            letterSpacing: '0.2rem'
        }}>:)</span></span>
    }
}

export const ThemeTogglerData = {
    name: 'Theme Toggler',
    demoData: {
        title: 'Theme Toggler',
        comment: 'Try out the project here',
        leftHeight:
            '11vh',
        appDemo: <ThemeToggler/>,
        descriptionArray:
            [
                'The theme toggler pet-project has a clean look, featuring a toggle button that switches the appearance of a specific section on a website',
                'Functionally, when the toggle button is clicked, it activates or deactivates a different visual style for the content section. This allows users to switch between themes instantly',
                'This project is useful for websites providing various visual choices. It can adapt to news sites with light and dark themes, portfolios showcasing design styles, and e-commerce platforms offering user customization']
    },
    html: {
        code: '<!doctype html>\n' +
            '<html lang="en">\n' +
            '\n' +
            '  <head>\n' +
            '    <meta name="viewport" content="width=device-width, initial-scale=1.0">\n' +
            '    <link rel="stylesheet" href="style.css">\n' +
            '    <title>Theme Toggler</title>\n' +
            '  </head>\n' +
            '\n' +
            '  <body>\n' +
            '    <div class="content" id="content">\n' +
            '      <h4 class="text">Theme Toggler</h4>\n' +
            '    </div>\n' +
            '    <button class="button" id="toggleButton"></button>\n' +
            '    <script src="script.js"></script>\n' +
            '  </body>\n' +
            '\n' +
            '</html>'
    },
    css: {
        code: '* {\n' +
            '  box-sizing: border-box;\n' +
            '  font-family: Arial, sans-serif;\n' +
            '  margin: 0;\n' +
            '  padding: 0;\n' +
            '}\n' +
            '\n' +
            'body {\n' +
            '  display: flex;\n' +
            '  justify-content: center;\n' +
            '  align-items: center;\n' +
            '  flex-direction: column;\n' +
            '  height: 100vh;\n' +
            '}\n' +
            '\n' +
            '.content {\n' +
            '  background: mediumslateblue;\n' +
            '  border-radius: 8px;\n' +
            '  padding: 15px;\n' +
            '  margin-bottom: 30px;\n' +
            '  transition: all 0.2s;\n' +
            '}\n' +
            '\n' +
            '.text {\n' +
            '  color: white;\n' +
            '}\n' +
            '\n' +
            '.button {\n' +
            '  border-radius: 20px;\n' +
            '  width: 50px;\n' +
            '  height: 30px;\n' +
            '  border: 2px solid mediumslateblue;\n' +
            '  position: relative;\n' +
            '\n' +
            '}\n' +
            '\n' +
            '.button::after {\n' +
            '  content: \'\';\n' +
            '  width: 24px;\n' +
            '  height: 24px;\n' +
            '  background: mediumslateblue;\n' +
            '  position: absolute;\n' +
            '  top: 1px;\n' +
            '  right: 1px;\n' +
            '  border-radius: 50%;\n' +
            '  transition: all 0.3s;\n' +
            '}\n' +
            '\n' +
            '.btnActive {\n' +
            '  border-color: #1d1d1d;\n' +
            '}\n' +
            '\n' +
            '.btnActive::after {\n' +
            '  transform: translateX(-20px);\n' +
            '  background: #1d1d1d;\n' +
            '}\n' +
            '\n' +
            '.contentActive {\n' +
            '  background: #1d1d1d;\n' +
            '}\n'
    },
    js: {
        code: 'const contentDiv = document.getElementById("content");\n' +
            'const toggleButton = document.getElementById("toggleButton");\n' +
            '\n' +
            'let isActive = false;\n' +
            '\n' +
            'toggleButton.addEventListener("click", function() {\n' +
            '  isActive = !isActive;\n' +
            '  updateClasses();\n' +
            '});\n' +
            '\n' +
            'function updateClasses() {\n' +
            '  if (isActive) {\n' +
            '    contentDiv.classList.add("contentActive");\n' +
            '    toggleButton.classList.add("btnActive");\n' +
            '  } else {\n' +
            '    contentDiv.classList.remove("contentActive");\n' +
            '    toggleButton.classList.remove("btnActive");\n' +
            '  }\n' +
            '}'
    },
    guide: {
        title: 'Guide Theme Toggler',
        htmlParagraph: <span>Create <b>basic</b> HTML structure. In the "body", add a "div"
            with class "content", an "h4" with class "text", and a button with class "button" and ID "toggleButton</span>,
        cssParagraph: <span>Style the layout, content container, toggle button, and active state, ensuring
            a visually appealing and responsive design</span>,
        jsParagraph: <span><b>Select</b> the content div using
            document.getElementById("content") and the toggle button using document.getElementById("toggleButton").
            Create a variable <b>isActive</b> to track the active state. Attach a <b>click listener</b> to the
            toggleButton that toggles the isActive state and calls <b>updateClasses()</b>.
            Define the updateClasses() function to <b>modify classes</b> based on the active state. If active, <b>add</b> classes
            to the content div and toggle button to <b>change their styles</b>. If not active, <b>remove</b> these classes.
            This function ensures the visual change in the theme based on the toggle. </span>,
        lastComment: <span><i>It is simple as that</i> <span style={{
            color: accentColor,
            fontSize: text4,
            marginLeft: '25px',
            transform: 'rotate(90deg) translateX(4px)',
            display: 'inline-block',
            letterSpacing: '0.2rem'
        }}>:)</span></span>
    }
}

export const ToastData = {
    name: 'Toast Notifications',
    demoData: {
        title: 'Toast Notifications',
        comment: 'Try out the project here',
        leftHeight:
            '11vh',
        appDemo: <Toast/>,
        descriptionArray:
            [
                'The toast pet-project features a simple appearance, displaying small notification boxes on the screen for a brief moment',
                'Functionally, when a button is clicked, the project generates a notification box, adding a visual element to provide quick feedback or alerts. The notification disappears after a short duration',
                'This project is applicable in various web contexts. For example, it can be used in messaging apps to show when a message is sent, in e-commerce sites to confirm items added to the cart, and in social media platforms to indicate when a post is liked']
    },
    html: {
        code: ' <!doctype html>\n' +
            '<html lang="en">\n' +
            '\n' +
            '  <head>\n' +
            '    <meta name="viewport" content="width=device-width, initial-scale=1.0">\n' +
            '    <link rel="stylesheet" href="style.css">\n' +
            '    <title>Toast</title>\n' +
            '  </head>\n' +
            '\n' +
            '  <body>\n' +
            '    <div id="container"></div>\n' +
            '    <button id="btn">\n' +
            '      Make toast\n' +
            '    </button>\n' +
            '    <script src="script.js"></script>\n' +
            '  </body>\n' +
            '\n' +
            '</html>'
    },
    css: {
        code: '* {\n' +
            '  box-sizing: border-box;\n' +
            '  font-family: Arial, sans-serif;\n' +
            '  margin: 0;\n' +
            '  padding: 0;\n' +
            '}\n' +
            '\n' +
            'body {\n' +
            '  display: flex;\n' +
            '  align-items: center;\n' +
            '  justify-content: center;\n' +
            '  min-height: 100vh;\n' +
            '  background: #1d1d1d;\n' +
            '}\n' +
            '\n' +
            'button {\n' +
            '  background-color: mediumslateblue;\n' +
            '  color: white;\n' +
            '  padding: 20px;\n' +
            '  border-radius: 6px;\n' +
            '  border: none;\n' +
            '  font-size: 1.2rem;\n' +
            '}\n' +
            '\n' +
            '#container {\n' +
            '  position: fixed;\n' +
            '  bottom: 0;\n' +
            '  right: 0;\n' +
            '}\n' +
            '\n' +
            '.toast {\n' +
            '  background-color: mediumslateblue;\n' +
            '  text-align: center;\n' +
            '  padding: 15px;\n' +
            '  border-radius: 5px;\n' +
            '  color: white;\n' +
            '  margin: 1rem;\n' +
            '  animation: appear .3s linear;\n' +
            '}\n' +
            '\n' +
            '@keyframes appear {\n' +
            '  0% {\n' +
            '    opacity: 0;\n' +
            '  }\n' +
            '\n' +
            '  100% {\n' +
            '    opacity: 1;\n' +
            '  }\n' +
            '}\n'
    },
    js: {
        code: 'const btn = document.getElementById(\'btn\');\n' +
            'const container = document.getElementById(\'container\');\n' +
            '\n' +
            'btn.addEventListener(\'click\', () => {\n' +
            '  createNotification();\n' +
            '});\n' +
            '\n' +
            'function createNotification() {\n' +
            '  const notif = document.createElement(\'div\');\n' +
            '  notif.classList.add(\'toast\');\n' +
            '  notif.innerText = \'+1\';\n' +
            '  container.appendChild(notif);\n' +
            '  setTimeout(() => {\n' +
            '    notif.remove();\n' +
            '  }, 3000)\n' +
            '}\n'
    },
    guide: {
        title: 'Guide Toast Notifications',
        htmlParagraph: <span> Include <b>basic</b> HTML structure and button</span>,
        cssParagraph: <span>Set basic styling. The toast container is positioned at the <b>bottom-right</b> corner of the <b>viewport</b>, housing toast elements.
            The appearance of toasts is animated smoothly using the "appear" <b>keyframes</b> rule, gradually altering opacity for a pleasing transition effect </span>,
        jsParagraph: <span>In the JavaScript section, attach a <b>click event</b> to the button with the ID "btn" to trigger the creation of a notification when clicked.
            Define the <b>createNotification()</b> function as follows: Create a <b>new "div"</b> element with the class "toast", <b>set its content</b> to "+1", <b>append</b> the element
                to the container, and utilize a <b>setTimeout </b>to remove the element after 3 seconds </span>,
        lastComment: <span><i>It is simple as that</i> <span style={{
            color: accentColor,
            fontSize: text4,
            marginLeft: '25px',
            transform: 'rotate(90deg) translateX(4px)',
            display: 'inline-block',
            letterSpacing: '0.2rem'
        }}>:)</span></span>
    }
}

export const TodoListData = {
    name: 'Todo list',
    demoData: {
        title: 'Todo list',
        comment: 'Try out the project here',
        leftHeight:
            '11vh',
        appDemo: <TodoList/>,
        descriptionArray:
            [
                'The todo list project has a simple look with a task input, an "Add" button, and a list display',
                'You add tasks by typing and clicking "Add." Each task gets a "Delete" button for removal',
                'It suits personal task tracking, shopping lists, and team projects. It adapts well to various needs']
    },
    html: {
        code: '<!doctype html>\n' +
            '<html lang="en">\n' +
            '\n' +
            '  <head>\n' +
            '    <meta name="viewport" content="width=device-width, initial-scale=1.0">\n' +
            '    <link rel="stylesheet" href="style.css">\n' +
            '    <title>Todo List</title>\n' +
            '  </head>\n' +
            '\n' +
            '  <body>\n' +
            '    <div class="container">\n' +
            '      <h1 class="title">Todo List</h1>\n' +
            '      <div class="newTaskContainer">\n' +
            '        <input type="text" id="todoInput" placeholder="Enter a new task">\n' +
            '        <button id="addButton">Add</button>\n' +
            '      </div>\n' +
            '      <ul class="list" id="todoList"></ul>\n' +
            '    </div>\n' +
            '    <script src="script.js"></script>\n' +
            '  </body>\n' +
            '\n' +
            '</html>\n'
    },
    css: {
        code: '* {\n' +
            '  box-sizing: border-box;\n' +
            '  font-family: Arial, sans-serif;\n' +
            '  margin: 0;\n' +
            '  padding: 0;\n' +
            '}\n' +
            '\n' +
            '.container {\n' +
            '  width: 500px;\n' +
            '  flex-direction: column;\n' +
            '  margin: 0 auto;\n' +
            '  padding: 40px 20px 20px;\n' +
            '  text-align: center;\n' +
            '  overflow: auto;\n' +
            '  height: 100%;\n' +
            '}\n' +
            '\n' +
            '.title {\n' +
            '  margin-bottom: 40px;\n' +
            '  font-weight: 700;\n' +
            '  color: mediumslateblue;\n' +
            '}\n' +
            '\n' +
            '.newTaskContainer {\n' +
            '  display: flex;\n' +
            '}\n' +
            '\n' +
            'input {\n' +
            '  font-size: 20px;\n' +
            '  display: block;\n' +
            '  width: 60%;\n' +
            '}\n' +
            '\n' +
            '.list {\n' +
            '  list-style-type: none;\n' +
            '  padding: 0;\n' +
            '  width: 100%;\n' +
            '  margin-top: 20px;\n' +
            '}\n' +
            '\n' +
            '.list li {\n' +
            '  display: flex;\n' +
            '  justify-content: space-between;\n' +
            '  align-items: center;\n' +
            '  padding: 10px 20px;\n' +
            '  border: 1px solid #ddd;\n' +
            '  margin-top: 5px;\n' +
            '}\n' +
            '\n' +
            '.task {\n' +
            '  max-width: 170px;\n' +
            '  overflow: auto;\n' +
            '  font-weight: 700;\n' +
            '  font-size: 20px;\n' +
            '}\n' +
            '\n' +
            'button {\n' +
            '  margin-left: auto;\n' +
            '  display: block;\n' +
            '  font-size: 20px;\n' +
            '  background: mediumslateblue;\n' +
            '  border: none;\n' +
            '  color: white;\n' +
            '  border-radius: 10px;\n' +
            '  padding: 10px 15px;\n' +
            '}\n' +
            '\n' +
            'span::-webkit-scrollbar {\n' +
            '  height: 4px;\n' +
            '}\n'
    },
    js: {
        code: 'const todoInput = document.getElementById("todoInput");\n' +
            'const addButton = document.getElementById("addButton");\n' +
            'const todoList = document.getElementById("todoList");\n' +
            '\n' +
            'addButton.addEventListener("click", addTodo);\n' +
            '\n' +
            'function addTodo() {\n' +
            '  const task = todoInput.value;\n' +
            '  if (task.trim() === "") {\n' +
            '    return;\n' +
            '  }\n' +
            '\n' +
            '  const listItem = document.createElement("li");\n' +
            '  listItem.innerHTML = `\n' +
            '            <span class="task">${task}</span>\n' +
            '            <button class="deleteButton">Delete</button>\n' +
            '        `;\n' +
            '\n' +
            '  const deleteButton = listItem.querySelector(".deleteButton");\n' +
            '  deleteButton.addEventListener("click", function() {\n' +
            '    listItem.remove();\n' +
            '  });\n' +
            '\n' +
            '  todoList.appendChild(listItem);\n' +
            '  todoInput.value = "";\n' +
            '}\n'
    },
    guide: {
        title: 'Guide Toast',
        htmlParagraph: <span>Create an HTML structure that includes
            a new task input with an "Add" button, and an unordered list to display tasks </span>,
        cssParagraph: <span>In the CSS section, you'll style the <b>upcoming elements</b> to create a visually appealing 
            and user-friendly interface. Pay attention to the container layout, the appearance of input and buttons, 
            and the list format. Additionally, ensure to customize the appearance of the <b>scrollbar</b> </span>,
        jsParagraph: <span> Access HTML elements like input, "Add" button, and the task list.
            Attach a <b>click event</b> to the "Add" button that triggers the <b>addTodo</b> function. This function <b>creates</b> a new task item with a delete button.
            The delete button's click event <b>removes </b>the task item. <b>Append the task item</b>  to the list and <b>clear </b>the input field after adding a task</span>,
        lastComment: <span><i>It is simple as that</i> <span style={{
            color: accentColor,
            fontSize: text4,
            marginLeft: '25px',
            transform: 'rotate(90deg) translateX(4px)',
            display: 'inline-block',
            letterSpacing: '0.2rem'
        }}>:)</span></span>
    }
}

export const VideoData = {
    name: 'Video',
    demoData: {
        title: 'Video',
        comment: 'Try out the project here',
        leftHeight:
            '11vh',
        appDemo: <Video/>,
        descriptionArray:
            [
                'The video pet-project showcases a video player. The provided video can be played, paused, and adjusted using the control buttons provided',
                'Functionally, the project allows users to play and control video content',
                'This project finds utility in diverse web scenarios. It can be used to present tutorial videos on e-learning platforms, showcase product demonstrations on e-commerce sites, and provide video content on news websites']
    },
    html: {
        code: '<!doctype html>\n' +
            '<html lang="en">\n' +
            '\n' +
            '  <head>\n' +
            '    <meta name="viewport" content="width=device-width, initial-scale=1.0">\n' +
            '    <link rel="stylesheet" href="style.css">\n' +
            '    <title>Video</title>\n' +
            '  </head>\n' +
            '\n' +
            '  <body>\n' +
            '    <div className="video-container">\n' +
            '      <video controls>\n' +
            '        <source src=\'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4\' type="video/mp4" />\n' +
            '        Your browser does not support the video tag.\n' +
            '      </video>\n' +
            '    </div>\n' +
            '  </body>\n' +
            '\n' +
            '</html>\n'
    },
    css: {
        code: '* {\n' +
            '  box-sizing: border-box;\n' +
            '  font-family: Arial, sans-serif;\n' +
            '  margin: 0;\n' +
            '  padding: 0;\n' +
            '}\n' +
            '\n' +
            'body {\n' +
            '  height: 100vh;\n' +
            '  display: flex;\n' +
            '  align-items: center;\n' +
            '}\n' +
            '\n' +
            'video {\n' +
            '  width: 100%;\n' +
            '}\n'
    },
    guide: {
        title: 'Video',
        htmlParagraph: <span> The <b>"video"</b> element includes
            a video source with a link to a sample video file in the "video/mp4" format. If the browser
            does not support the video tag, a fallback message is displayed. This code creates a basic
            setup for embedding and controlling a video player on a webpage</span>,
        cssParagraph: <span> Style the <b>video tag</b></span>,
        lastComment: <span><i>It is simple as that</i> <span style={{
            color: accentColor,
            fontSize: text4,
            marginLeft: '25px',
            transform: 'rotate(90deg) translateX(4px)',
            display: 'inline-block',
            letterSpacing: '0.2rem'
        }}>:)</span></span>
    }
}
