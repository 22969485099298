import React from 'react';
import cl from './Html.module.css'
import { vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import SyntaxHighlighter from 'react-syntax-highlighter';

import TitleWithComment from "../../../entities/TitleWithComment/TitleWithComment";
import Button_lilac from "../../../shared/UI/Button_lilac/Button_lilac";

const Html = ({name, code}) => {
    return (
        <div className={cl.wrap}>
            <div className={cl.titleWithCommentWrap}>
                <TitleWithComment
                    data={{title: 'HTML markup', comment: <div><div style={{marginBottom:'20px'}} >{name} project</div><Button_lilac onClick={() =>  navigator.clipboard.writeText(code)}>Copy the code</Button_lilac></div>}}/>
            </div>

            <SyntaxHighlighter wrapLongLines={true} language="htmlbars" style={vs2015}>
                {code}
            </SyntaxHighlighter>
        </div>
    );
};

export default Html;