import React from 'react';
import Hero from "./Hero/Hero";
import Benefits from "./Benefits/Benefits";
import AboutCodeFeatures from "./AboutCodeFeatures/AboutCodeFeatures";
import OfferContent from "./OfferContent/OfferContent";
import AboutCompany from "./AboutCompany/AboutCompany";

const Home = () => {
    return (
        <div>
            <Hero/>
            <AboutCompany/>
            <Benefits/>
            <AboutCodeFeatures/>
            <OfferContent/>
        </div>
    );
};

export default Home;