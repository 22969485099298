import React, {useState} from 'react';
import cl from './BackgroundChanger.module.css'
import Button_lilac_outline from "../../../shared/UI/Button_lilac_outline/Button_lilac_outline";

const BackgroundChanger = () => {
    const [removeAnimation, setRemoveAnimation] = useState(false)
    const [currentBackgroundColor, setCurrentBackgroundColor] = useState(0)

    function randomBg() {
        let colorCode = Math.floor(Math.random() * 360);
        return `hsl(${colorCode}, 60%, 50%`;
    }

    const handleClick = () => {
        setRemoveAnimation(true);
        setCurrentBackgroundColor((currentBackgroundColor + 1) % 3)
    }
    return (
        <div className={cl.wrap + (removeAnimation ? ' ' + cl.removeAnimation : '')}
             style={removeAnimation ? {backgroundColor: randomBg()} : {}}>
            <header> </header>
            <Button_lilac_outline style={removeAnimation ? {animation: 'none'} : {}}
                                  onClick={() => handleClick()}>Change</Button_lilac_outline>

        </div>
    );
};

export default BackgroundChanger;