
export const typesOfCommand = {
    string: {
        hint: 'Just a string',
        color: 'green',
    },
    newExpression: {
        hint: 'Declaration of a new expression',
        color: 'orange',
    },
    null:{
      hint:'Null value',
      color:'white'
    },
    property:{
      hint:'Property of the object in format key:value',
      color:'white'
    },
    conditionalExpressionTest: {
        hint: 'It is a conditional expression. If it is true the return value will be the value after question mark. In other case the result will be the value after semicolon',
        color: 'white',
    },
    boolean: {
        hint: 'Boolean value',
        color: 'orange',
    },
    array: {
        hint: 'Just an array',
        color: 'white',
    },
    nullValue: {
        hint: 'Represents the intentional absence',
        color: 'white',
    },
    object: {
        hint: 'Just an object',
        color: 'white',
    },
    number: {
        hint: 'Just a number',
        color: 'blue',
    },
    params: {
        hint: 'A parameter of the function',
        color: 'white',
    },
    variable: {
        hint: `Variable name`,
        color: 'white',
    },
    func: {
        hint: `It's just a function name`,
        color: 'yellow',
    },
    templateLiteral: {
        hint: `It's a string with possible variables usage inside`,
        color: 'green',
    },
    err: {
        hint: `Error`,
        color: 'red',
    },
    command:'command'
};