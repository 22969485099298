import React, {useEffect, useRef, useState} from 'react';
import cl from './Popup.module.css'

const Popup = () => {
    const [popupIsOpened, setPopupIsOpened] = useState(false);
    const containerNode = useRef(null);
    const popupNode = useRef(null);


    useEffect(() => {
        containerNode.current.addEventListener('click', e => {
            if (e.target === containerNode.current && e.target !== popupNode.current) {
                setPopupIsOpened(false)
            }
        });
    }, [])

    return (
        <div className={cl.wrap}>
            <button className={cl.openButton} onClick={() => setPopupIsOpened(true)}>
                Open
            </button>
            <div ref={containerNode} className={cl.popupContainer + (popupIsOpened ? ' ' + cl.active : '')}>
                <div ref={popupNode} className={cl.popup}>
                    <button className={cl.closeButton} onClick={() => {setPopupIsOpened(false)}}>
                        x
                    </button>
                    <h4 className={cl.popupTitle}>
                        This is popup!
                    </h4>
                    <p>
                        It was pretty easy...
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Popup;