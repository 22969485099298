import React, {useCallback, useRef, useState} from 'react';
import cl from './DragAndDrop.module.css'

import DraggableElem from "./DraggableElem/DraggableElem";

const DragAndDrop = () => {
    const [draggedBox, setDraggedBox] = useState('');
    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    const square = useRef(null)
    const handleDragStart = (id) => {
        setDraggedBox(id);
    };
    const draggableItems = {
        box1: useRef(null),
        box2: useRef(null),
        box3: useRef(null),
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = useCallback((event) => {
        const rect = square.current.getBoundingClientRect();
        const scaleX = rect.width / square.current.offsetWidth; // Calculate the horizontal scaling factor
        const scaleY = rect.height / square.current.offsetHeight; // Calculate the vertical scaling factor
        let x = isTouchDevice
            ? (event.changedTouches[0].clientX - rect.left) / scaleX
            : (event.clientX - rect.left) / scaleX;
        let y = isTouchDevice
            ? (event.changedTouches[0].clientY - rect.top) / scaleY
            : (event.clientY - rect.top) / scaleY;
        const draggableItem = draggableItems[draggedBox]?.current;
        if (x >= square.current.offsetWidth) x = square.current.offsetWidth
        if (y >= square.current.offsetHeight) y = square.current.offsetHeight
        if (x < 0) x = 0;
        if (y < 0) y = 0;

        if (draggableItem) {
            draggableItem.style.left = x - draggableItem.offsetWidth / 2 + 'px';
            draggableItem.style.top = y - draggableItem.offsetHeight / 2 + 'px';
            setDraggedBox(null);
        }
    }, [draggedBox]);

    return (
        <div
            id="square"
            className={cl.dropArea}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onTouchEnd={handleDrop}
            ref={square}
        >
            <DraggableElem id="box1" reff={draggableItems.box1} onDragStart={handleDragStart}
                           onTouchStart={isTouchDevice ? handleDragStart : undefined}/>
            <DraggableElem id="box2" reff={draggableItems.box2} onDragStart={handleDragStart}
                           onTouchStart={isTouchDevice ? handleDragStart : undefined}/>
            <DraggableElem id="box3" reff={draggableItems.box3} onDragStart={handleDragStart}
                           onTouchStart={isTouchDevice ? handleDragStart : undefined}/>
        </div>
    );
};

export default DragAndDrop;